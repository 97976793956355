import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import React from 'react';
import '@leenguyen/react-flip-clock-countdown/dist/index.css'

const Countdown = ({ targetDate, onCountdownEnd }) => {
    if (!targetDate) {
        return null;
    }

    const date = targetDate.replace('Z', '');
    const now = new Date();
    const target = new Date(date);

    if (now >= target) {
        onCountdownEnd?.();
        return null;
    }

    return (
        <section className="flex flex-col justify-center items-center h-screen md:w-screen md:h-screen">
            <div className="bg-black/70 absolute w-full h-full"></div>
            <div className="absolute flex flex-col items-center text-center">
                <h1 className="text-[3rem] mb-[1rem] text-white font-dmsans">Lancement imminent</h1>

                <FlipClockCountdown
                    to={date}
                    labels={['JOURS', 'HEURES', 'MINUTES', 'SECONDES']}
                    className="text-white font-dmsans"
                    duration={0.5}
                    onComplete={onCountdownEnd}
                />
            </div>
        </section>
    );
};

export default Countdown;