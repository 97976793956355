import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CardImage from "./CardImage";
import CardTextContent from "./CardTextContent";
import { fetchTranslations, generateSlug } from "../../services/specificServices/articles";
import { useTranslation } from "react-i18next";

const HeroCard = ({ article, baseUrl, id }) => {
    const { i18n } = useTranslation();
    const [translatedTitle, setTranslatedTitle] = useState(article.title);

    useEffect(() => {
        const getTranslatedTitle = async () => {
            if (i18n.language === 'fr') {
                setTranslatedTitle(article.title);
                return;
            }

            try {
                const translation = await fetchTranslations(article.id, i18n.language);
                if (translation && translation.title) {
                    setTranslatedTitle(translation.title);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération de la traduction:', error);
                setTranslatedTitle(article.title);
            }
        };

        getTranslatedTitle();
    }, [article.id, article.title, i18n.language]);

    return (
        <Link to={`/actualites/${generateSlug(translatedTitle)}`} key={id}>
            <div className="relative w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[50vh]">
                <CardImage
                    imagePath={`${baseUrl}${article.imagePath}`}
                    altText={translatedTitle}
                    className="w-full h-full rounded-xl"
                />
                <div className="absolute left-4 sm:left-8 bottom-[-40px] sm:bottom-[-60px] bg-white rounded-xl w-11/12 sm:w-4/5 md:w-3/5 lg:w-2/5 p-6 sm:p-8 lg:p-10 border-2 border-[#E8E8EA]">
                    <CardTextContent
                        categoryName={article.category}
                        title={translatedTitle}
                        date={new Date(article.updated).toLocaleDateString()}
                        isHero
                    />
                </div>
            </div>
        </Link>
    );
};

export default HeroCard; 