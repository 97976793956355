import { http } from "./api";

export const logout = () => {
    localStorage.removeItem("authToken");
};

export const getProfile = () => {
    const token = localStorage.getItem("token");
    return http("/user/profile", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };