import React from 'react';
import { API_BASE_URL } from '../../config/apiConfig';
import Heading from '../common/Heading';
import { LiaMedalSolid } from 'react-icons/lia';
import { GoTrophy } from 'react-icons/go';
import { Trans, useTranslation } from 'react-i18next';

const CeoSection = () => {
    const { t } = useTranslation();

    return (
        <section className="pb-8 sm:pb-12 lg:pb-16 xl:pb-20 2xl:pb-24 pt-8 px-4 sm:px-6 lg:px-8 xl:px-12">
            <div className="max-w-[2000px] mx-auto">
                <div className="max-w-7xl xl:max-w-[1400px] 2xl:max-w-[1800px] mx-auto">
                    <Heading
                        subtitle={t('alcyone.subtitle')}
                        title={t('alcyone.title')}
                        classParent="left"
                        classChild="start"
                    />
                </div>

                <div className="max-w-7xl xl:max-w-[1400px] 2xl:max-w-[1800px] mx-auto grid grid-cols-2 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-9 gap-6 sm:gap-8 lg:gap-12 xl:gap-16 mt-8 sm:mt-12 lg:mt-16">
                    <div className="col-span-2 sm:col-span-5 md:col-span-6 lg:col-span-4 xl:col-span-5 space-y-6 xl:space-y-8 justify-between flex flex-col">
                        <div className="space-y-4 xl:space-y-4">
                            <p className="text-base sm:text-lg xl:text-lg text-gray-700 font-worksans leading-relaxed text-justify">
                                <Trans i18nKey="alcyone.description1" components={{ strong: <strong />, em: <em /> }} />
                            </p>
                            <p className="text-base sm:text-lg xl:text-lg text-gray-700 font-worksans leading-relaxed text-justify">
                                <Trans i18nKey="alcyone.description2" components={{ strong: <strong />, em: <em /> }} />
                            </p>
                            <p className="text-base sm:text-lg xl:text-lg text-gray-700 font-worksans leading-relaxed text-justify">
                                <Trans i18nKey="alcyone.description3" components={{ strong: <strong />, em: <em /> }} />
                            </p>
                        </div>
                    </div>

                    <div className="col-span-2 sm:col-span-5 md:col-span-6 lg:col-span-4 xl:col-span-4 flex justify-center lg:justify-center mt-8 lg:mt-0">
                        <div className="relative w-full">
                            <img 
                                src="./bureaux.jpg"
                                alt="Lusis"
                                className="w-full h-full object-contain lg:object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CeoSection; 