import { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigationConfig } from '../../config/navigationConfig';
import { IoIosArrowDown } from "react-icons/io";

const Navigation = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const navigationConfig = useNavigationConfig();
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    useEffect(() => {
        const handleClickOutside = (event) => {

            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsMenuOpen(false);
            }
        };


        document.addEventListener("mousedown", handleClickOutside);


        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <nav className="relative flex items-center justify-between text-gray-700 w-15vw lg:w-auto">
            <div className="lg:hidden">
                <button
                    ref={buttonRef}
                    onClick={toggleMenu}
                    className="text-gray-700 focus:outline-none"
                >
                    {isMenuOpen ? (
                        <FaTimes className="text-2xl" />
                    ) : (
                        <FaBars className="text-2xl" />
                    )}
                </button>
            </div>

            <div
                ref={menuRef}
                className={`${isMenuOpen ? "block" : "hidden"} 
                    absolute top-16 -left-24 w-full bg-white lg:px-2 lg:py-4 xl:p-6 shadow-lg rounded-md z-50 
                    lg:static lg:w-auto lg:flex lg:bg-transparent lg:shadow-none w-281p`}
            >
                <div className="flex flex-col lg:flex-row gap-[16px] xl:gap-6">
                    {navigationConfig.map((item) => (
                        <Link
                            key={item.path}
                            to={item.path}
                            className="text-black flex flex-row items-center text-base lg:text-sm xl:text-base font-dmsans font-bold hover:text-[#0B86C8] transition-colors"
                        >
                            {item.label}
                            {/* <IoIosArrowDown className="mt-[4px] text-sm" /> */}
                        </Link>
                    ))}
                </div>
            </div>
        </nav>
    );
};

export default Navigation;
