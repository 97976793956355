import { useState, useEffect } from 'react';
import { fetchAll, fetchTranslations } from '../services/specificServices/privacy';
import { useTranslation } from 'react-i18next';

export const usePrivacy = () => {
  const [translatedItems, setTranslatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadPrivacy = async () => {
      try {
        setLoading(true);
        const privacy = await fetchAll();

        const translatedPrivacy = await Promise.all(
          privacy.map(async (privacy) => {
            const translation = await fetchTranslations(privacy.id, i18n.language);
            return {
              ...privacy,
              privacy: translation?.content || privacy.content,
            };
          })
        );
        setTranslatedItems(translatedPrivacy);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadPrivacy();
  }, [i18n.language]);

  return { items: translatedItems, loading, error };
}; 