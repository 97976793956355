import React, { useState, useEffect } from 'react';
import { fetchAll } from '../../services/specificServices/articles';
import { fetchAll as fetchCategories } from '../../services/specificServices/categories';
import Pagination from '../../components/common/Pagination';
import { API_BASE_URL } from '../../config/apiConfig';
import ArticleCard from '../../components/articles/ArticleCard';
import HeroCard from '../../components/articles/HeroCard';
import { useTranslation } from 'react-i18next';
import { useArticles } from '../../hooks/useArticles';
import { useCategories } from '../../hooks/useCategories';

const ArticlesPage = ({ number, headerActive, HomePage }) => {
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [nonFeaturedArticles, setNonFeaturedArticles] = useState([]);
  const [heroArticle, setHeroArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = number;
  const [selectedCategory, setSelectedCategory] = useState('all');
  const { t } = useTranslation();
  const { items, loadingArticles, errorArticles } = useArticles();
  const { categories, categoriesInit, loading: loadingCategories } = useCategories();
  const [isDataReady, setIsDataReady] = useState(false);

  const translatedArticlesMap = React.useMemo(() => {
    if (!items.length || !categoriesInit.length) return {};
    
    return items.reduce((acc, item) => {
      const category = categoriesInit.find(cat => cat.id === item.categoryId);
      acc[item.id] = {
        ...item,
        category: category?.name || "Unknown Category"
      };
      return acc;
    }, {});
  }, [items, categoriesInit]);

  useEffect(() => {
    if (items.length > 0 && categoriesInit.length > 0) {
      setIsDataReady(true);
    }
  }, [items, categoriesInit]);

  useEffect(() => {
    const loadArticles = async () => {
      if (!isDataReady) return;

      try {
        setLoading(true);
        const data = await fetchAll();
        
        const featured = data.filter(article => article.featured);
        const nonFeatured = data.filter(article => !article.featured);

        const sortedFeatured = featured.sort(
          (a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)
        );

        const sortedNonFeatured = nonFeatured.sort(
          (a, b) => (a.displayOrder || 0) - (b.displayOrder || 0)
        );

        const transformArticle = (article) => {
          const translatedData = translatedArticlesMap[article.id] || {};
          const category = categories.find(cat => cat.id === article.categoryId);
          
          return {
            ...article,
            ...translatedData,
            category: category?.name || "Unknown Category",
            categoryId: article.categoryId
          };
        };

        const hero = sortedFeatured.length > 0 ? sortedFeatured[0] : null;
        
        if (hero) {
          const transformedHero = transformArticle(hero);
          setHeroArticle(transformedHero);
        }
        
        setFeaturedArticles(sortedFeatured.slice(1).map(transformArticle));
        setNonFeaturedArticles(sortedNonFeatured.map(transformArticle));
      } catch (err) {
        setError('Une erreur est survenue lors du chargement des articles');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, [isDataReady, translatedArticlesMap, categories]);

  useEffect(() => {
    window.scrollTo({ top: -10, behavior: "smooth" });
  }, [currentPage]);

  if (loadingArticles || loadingCategories) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-center">
          <p className="text-xl font-semibold">{error}</p>
        </div>
      </div>
    );
  }

  const combinedArticles = headerActive 
    ? [...featuredArticles, ...nonFeaturedArticles]
    : [heroArticle, ...featuredArticles, ...nonFeaturedArticles];

  const validArticles = combinedArticles.filter(article => article !== null);

  const categoriesWithArticles = [...new Set(validArticles.map(article => article.categoryId))];

  const activeCategories = categories.filter(category =>
    categoriesWithArticles.includes(category.id)
  );

  const filteredArticles = validArticles.filter(article =>
    selectedCategory === 'all' || article.categoryId === selectedCategory
  );

  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 1, behavior: "smooth" });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div id="scroll-top" className="absolute top-0" />

      {heroArticle && headerActive && (
        <>
          <HeroCard 
            article={heroArticle}
            baseUrl={API_BASE_URL}
            id={heroArticle.id}
          />
        </>
      )}

      <div className={`${headerActive ? 'mt-16' : 'mt-0'} flex justify-end mb-4`}>
        {currentArticles.length > 0 && (
          <select
            className="w-full md:w-64 p-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 font-dmsans"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value === 'all' ? 'all' : parseInt(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value="all">{t('buttons.all_categories')}</option>
            {activeCategories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        )}
      </div>

      {currentArticles.length > 0 ? (
        <>
          <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 ${headerActive ? 'lg:mt-8' : 'lg:mt-8'}`}>
            {currentArticles.map((article) => (
              <ArticleCard
                key={article.id}
                article={article}
                baseUrl={API_BASE_URL}
                id={article.id}
              />
            ))}
          </div>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      ) : !HomePage && !heroArticle && headerActive && currentArticles.length === 0 && (
        <div className="min-h-[200px] flex items-center justify-center">
          <p className="text-xl text-gray-600 font-dmsans">
            {t('messages.no_articles_found')}
          </p>
        </div>
      )}
    </div>
  );
};

export default ArticlesPage; 