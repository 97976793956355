import React from 'react';
import { API_BASE_URL } from '../../config/apiConfig';
import Table from '../common/Table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const LabelTable = ({ items, onEdit, onDelete }) => {
  const columns = [
    {
      key: 'imageUrl',
      title: 'Logo',
      render: (label) => (
        label.imageUrl ? (
          <img
            src={label.imageUrl.startsWith('http')
              ? label.imageUrl
              : `${API_BASE_URL}${label.imageUrl}`
            }
            alt={label.title}
            className="w-16 h-16 object-contain"
          />
        ) : (
          'Aucun logo'
        )
      ),
    },
    {
      key: 'title',
      title: 'Nom',
    },
    {
      key: 'description',
      title: 'Description',
      render: (label) => {
        if (!label.description) return '-';
        return label.description.length > 50
          ? `${label.description.substring(0, 50)}...`
          : label.description;
      }
    }
  ];

  const handleDelete = (label) => {
    const confirmMessage = `Êtes-vous sûr de vouloir supprimer le label "${label.title}" ?`;

    if (window.confirm(confirmMessage)) {
      onDelete(label.id);
      toast.success('Label supprimé avec succès', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        style: toastStyle.success.style
      });
    }
  };

  return (
    <div className="relative">
      <Table
        columns={columns}
        data={items}
        actions={(item) => (
          <>
            <button
              className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
              onClick={() => onEdit(item)}
            >
              Modifier
            </button>
            <button
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => handleDelete(item)}
            >
              Supprimer
            </button>
          </>
        )}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={3}
        toastStyle={toastStyle}
      />
    </div>
  );
};

export default LabelTable; 