import { useState, useEffect } from 'react';
import { fetchTags } from '../services/specificServices/jobOffers';

export const useJobTags = () => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadTags = async () => {
      try {
        const data = await fetchTags();
        setTags(data);
        setError(null);
      } catch (err) {
        console.error('Erreur lors du chargement des tags:', err);
        setError('Impossible de charger les tags');
      } finally {
        setLoading(false);
      }
    };

    loadTags();
  }, []);

  return { tags, loading, error, setTags };
}; 