import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation();

  return [
    {
      path: '/',
      label: t('navigation.home'),
      exact: true
    },
    {
      path: '/a-propos',
      label: t('navigation.about'),
    },
    {
      path: '/services',
      label: t('navigation.services'),
    },
    {
      path: '/carrieres',
      label: t('navigation.careers'),
    },
    {
      path: '/actualites',
      label: t('navigation.articles'),
    },
    {
      path: '/contact',
      label: t('navigation.contact'),
    }
  ];
};
