import { useState, useEffect } from 'react';
import { fetchAll, fetchTranslations } from '../services/specificServices/teamMembers';
import { useTranslation } from 'react-i18next';

export const useTeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadTeamMembers = async () => {
      try {
        setLoading(true);
        const data = await fetchAll();

        const translatedMembers = await Promise.all(
          data.map(async (member) => {
            const translation = await fetchTranslations(member.id, i18n.language);
            return {
              ...member,
              role: translation?.role || member.role
            };
          })
        );
        setTeamMembers(translatedMembers);
        setError(null);
      } catch (err) {
        console.error('Erreur lors du chargement des membres:', err);
        setError('Impossible de charger les membres de l\'équipe');
      } finally {
        setLoading(false);
      }
    };

    loadTeamMembers();
  }, [i18n.language]);

  return { teamMembers, loading, error };
}; 