import React, { useEffect, useState } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import { create, update, uploadImage, fetchOne, fetchTranslations, updateTranslation, fetchAll } from '../../services/specificServices/teamMembers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    success: {
        style: {
            background: '#0B86C8',
            color: 'white'
        }
    },
    error: {
        style: {
            background: '#f64915',
            color: 'white'
        }
    }
};

const TeamMemberForm = ({ item = null, onSubmit, onCancel }) => {
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(
        item?.imageUrl ? `${API_BASE_URL}${item.imageUrl}` : ''
    );
    const [formData, setFormData] = useState({
        firstName: item?.firstName || '',
        lastName: item?.lastName || '',
        role: item?.role || '',
        linkedinUrl: item?.linkedinUrl || '',
        bio: item?.bio || '',
        displayOrder: item?.displayOrder || null
    });


    const [selectedLanguage, setSelectedLanguage] = useState('fr');
    const [allTranslations, setAllTranslations] = useState({
        fr: { role: '' },
        en: { role: '' }
    });
    const availableLanguages = [
        { code: 'fr', label: 'Français' },
        { code: 'en', label: 'Anglais' }
    ];

    useEffect(() => {
        if (item?.id) {
            loadTranslations(item.id, selectedLanguage);
        }
    }, [item, selectedLanguage]);

    const fields = [
        // {
        //     name: 'lastName',
        //     label: 'Nom',
        //     type: 'text',
        //     placeholder: 'Nom',
        //     validation: { required: 'Le nom est requis' }
        // },
        // {
        //     name: 'role',
        //     label: 'Poste',
        //     type: 'text',
        //     placeholder: 'Poste',
        //     validation: { required: 'Le poste est requis' }
        // },
        // {
        //     name: 'linkedinUrl',
        //     label: 'Linkedin',
        //     type: 'text',
        //     placeholder: 'Linkedin',
        //     validation: { required: 'Le linkedin est requis' }
        // },
        // {
        //     name: 'bio',
        //     label: 'Description',
        //     type: 'textarea',
        //     placeholder: 'Description',
        //     validation: { required: 'La description est requise' }
        // }
    ];

    const handleSubmit = async (data) => {
        try {
            let savedMember;

            const { id, ...memberData } = data;
            if (!file && item?.imageUrl) {
                memberData.imageUrl = item.imageUrl;
            }

            const saveData = {
                ...memberData,
                firstName: formData.firstName,
                lastName: formData.lastName,
                linkedinUrl: formData.linkedinUrl,
                role: allTranslations[selectedLanguage].role,
                displayOrder: formData.displayOrder
            };

            if (!item?.id) {
                try {
                    const members = await fetchAll();
                    const maxDisplayOrder = members.reduce((max, member) =>
                        Math.max(max, member.displayOrder || 0), 0);
                    saveData.displayOrder = maxDisplayOrder + 1;
                } catch (error) {
                    console.error('Erreur lors de la récupération des membres:', error);
                    saveData.displayOrder = 1;
                }
            }

            if (item?.id) {
                savedMember = await update(item.id, saveData);
                toast.success('Membre mis à jour avec succès', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style,
                    onClose: () => {
                        if (typeof onSubmit === 'function') {
                            onSubmit(savedMember);
                        }
                    }
                });
            } else {
                savedMember = await create(saveData);
                toast.success('Membre créé avec succès', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style,
                    onClose: () => {
                        if (typeof onSubmit === 'function') {
                            onSubmit(savedMember);
                        }
                    }
                });
            }

            if (file) {
                await uploadImage(savedMember.id, file);
                savedMember = await fetchOne(savedMember.id);
            }

            for (const lang of availableLanguages) {
                await updateTranslation(savedMember.id, lang.code, 'role', allTranslations[lang.code].role);
            }
        } catch (error) {
            console.error('Erreur lors de la sauvegarde:', error);
        }
    };

    useEffect(() => {
        if (item?.id) {
            loadTranslations(item.id, selectedLanguage);
        }
    }, [item, selectedLanguage]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        }
    };

    const handleLanguageChange = async (e) => {
        const newLanguage = e.target.value;
        setSelectedLanguage(newLanguage);
        if (item?.id) {
            await loadTranslations(item.id, newLanguage);
        }
    };

    const loadTranslations = async (articleId, language) => {
        try {
            const data = await fetchTranslations(articleId, language);
            setAllTranslations(prev => ({
                ...prev,
                [language]: {
                    role: data?.role || ''
                }
            }));
        } catch (error) {
            console.error('Erreur lors du chargement des traductions:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleTranslationChange = (field, value) => {
        setAllTranslations(prev => ({
            ...prev,
            [selectedLanguage]: {
                ...prev[selectedLanguage],
                [field]: value
            }
        }));
    };

    const handleTranslationBlur = async (field, value) => {
        if (item?.id) {
            try {
                await updateTranslation(item.id, selectedLanguage, field, value);
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la traduction:', error);
            }
        }
    };

    return (
        <div className='relative'>
            <Form
                onSubmit={handleSubmit}
                onCancel={onCancel}
                fields={fields}
                initialValues={item}
                title={item ? "Modifier le membre" : "Nouveau membre"}
                submitLabel={item ? "Mettre à jour" : "Créer"}
            >
                <div className="mb-4">
                    <label className="block font-semibold mb-1">Langue</label>
                    <select
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        {availableLanguages.map(lang => (
                            <option key={lang.code} value={lang.code}>
                                {lang.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-4">
                    <label className="block font-semibold mb-1">Prénom</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block font-semibold mb-1">Nom</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block font-semibold mb-1">Role</label>
                    <input
                        type="text"
                        name="role"
                        value={allTranslations[selectedLanguage].role}
                        onChange={(e) => handleTranslationChange('role', e.target.value)}
                        onBlur={(e) => handleTranslationBlur('role', e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block font-semibold mb-1">Linkedin</label>
                    <input
                        type="text"
                        name="linkedinUrl"
                        value={formData.linkedinUrl}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>

                {previewImage && (
                    <div>
                        <label className="block font-semibold mb-1">Photo actuelle</label>
                        <img
                            src={previewImage}
                            alt="Aperçu"
                            className="w-48 h-48 object-cover rounded-full"
                        />
                    </div>
                )}

                <div>
                    <label className="block font-semibold mb-1">Photo</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="w-full p-2 border border-gray-300 rounded"
                        required={!item}
                    />
                </div>
            </Form>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
};

export default TeamMemberForm; 