import React from 'react';
import Table from '../common/Table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const JobOfferTable = ({ items, onEdit, onDelete }) => {
  const sortedItems = [...items].sort((a, b) => {
    if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') return -1;
    if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') return 1;
    return 0;
  });

  const columns = [
    {
      key: 'title',
      title: 'Titre',
    },
    {
      key: 'location',
      title: 'Lieu',
    },
    {
      key: 'tags',
      title: 'Tags',
      render: (jobOffer) => (
        <div className="flex flex-wrap gap-2">
          {jobOffer.tags?.map(tag => (
            <span
              key={tag.id}
              className="px-2 py-1 bg-[#0B86C8]/10 text-[#0B86C8] text-sm rounded-full"
            >
              {tag.name}
            </span>
          ))}
        </div>
      )
    },
    {
      key: 'status',
      title: 'Statut',
      render: (jobOffer) => (
        <span className={`px-2 py-1 rounded-full text-sm ${jobOffer.status === 'ACTIVE'
          ? 'bg-green-100 text-green-800'
          : 'bg-red-100 text-red-800'
          }`}>
          {jobOffer.status === 'ACTIVE' ? 'Active' : 'Inactive'}
        </span>
      )
    }
  ];

  const handleDelete = async (jobOffer) => {
    const confirmMessage = `Êtes-vous sûr de vouloir supprimer l'offre "${jobOffer.title}" ?`;

    if (window.confirm(confirmMessage)) {
      try {
        await onDelete(jobOffer.id);
        toast.success('Offre d\'emploi supprimée avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style
        });
      } catch (error) {
        toast.error('Erreur lors de la suppression de l\'offre d\'emploi', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.error.style
        });
      }
    };
  };

  return (
    <div className="relative">
      <Table
        columns={columns}
        data={sortedItems}
        actions={(item) => (
          <>
            <button
              className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
              onClick={() => onEdit(item)}
            >
              Modifier
            </button>
            <button
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => handleDelete(item)}
            >
              Supprimer
            </button>
          </>
        )}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={3}
        toastStyle={toastStyle}
      />
    </div>
  );
};

export default JobOfferTable; 