import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(i18n.language || 'fr');

    const languages = [
        { code: 'fr', label: 'Français', shortLabel: 'FR' },
        { code: 'en', label: 'English', shortLabel: 'GB' }
    ];

    useEffect(() => {
        const storedLang = localStorage.getItem('i18nextLng');

        if (!storedLang || !languages.some(lang => lang.code === storedLang)) {
            i18n.changeLanguage('fr');
            localStorage.setItem('i18nextLng', 'fr');
            setCurrentLang('fr');
        } else {
            setCurrentLang(storedLang);
        }
    }, [i18n]);

    const handleLanguageChange = (langCode) => {
        i18n.changeLanguage(langCode);
        localStorage.setItem('i18nextLng', langCode);
        setCurrentLang(langCode);
    };

    return (
        <div className="relative flex-row lg:items-center gap-2 xl:ml-5 flex">
            {languages.map((lang) => (
                <button
                    key={lang.code}
                    onClick={() => handleLanguageChange(lang.code)}
                    className={`w-12 lg:w-full px-3 py-2 lg:px-2 lg:py-1 xl:px-3 xl:py-2 text-left hover:bg-gray-50 flex items-center gap-3 transition-colors rounded-full
                                ${currentLang === lang.code ? 'bg-gray-50' : ''}`}
                >
                    <Flag code={lang.shortLabel} className="w-6 h-6 lg:w-4 lg:h-4" />
                </button>
            ))}
        </div>
    );
};

export default LanguageSelector;
