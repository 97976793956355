import React from 'react';
import { API_BASE_URL } from '../../config/apiConfig';
import { useTranslation } from 'react-i18next';

const OfficeCard = ({ office }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img
        src={office.imageUrl?.startsWith('http') 
          ? office.imageUrl 
          : `${API_BASE_URL}${office.imageUrl}`
        }
        alt={office.city}
        className="h-64 w-full object-cover"
        onError={(e) => {
          e.target.src = '/placeholder.jpg';
        }}
      />
      <div className="px-10 py-8">
        <p className="text-[#B9BBBF] mb-11 font-dmsans text-lg font-medium">
          {office.address}
          <br />
          {office.postalCode}
          <br />
          {office.city}
        </p>
        <a
          href={office.websiteUrl || "#"}
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-xl hover:underline text-[#898D93] font-dmsans inline-flex items-center"
        >
          {t('buttons.more')}
          <span className="ml-2">→</span>
        </a>
      </div>
    </div>
  );
};

export default OfficeCard; 