import React, { useState } from "react";
import { sectionsConfig } from "../config/sectionsConfig";
import Sidebar from "../components/admin/Sidebar";
import { resourcesConfig } from "../config/resourcesConfig";

const AdminDashboard = ({ userProfile }) => {
    const [activeSection, setActiveSection] = useState("dashboard");
    const [editingItem, setEditingItem] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleEdit = (item) => {
        const currentResource = Object.values(resourcesConfig).find(
            resource => resource.routes.list === activeSection || 
                       resource.routes.create === activeSection ||
                       resource.routes.edit === activeSection
        );

        if (!currentResource) return;

        if (item) {
            setActiveSection(currentResource.routes.edit);
            setEditingItem(item);
        } else {
            setActiveSection(currentResource.routes.list);
            setEditingItem(null);
        }
        setIsSidebarOpen(false);
    };

    const handleCreate = () => {
        const currentResource = Object.values(resourcesConfig).find(
            resource => resource.routes.list === activeSection
        );

        if (currentResource) {
            setActiveSection(currentResource.routes.create);
            setEditingItem(null);
        }
        setIsSidebarOpen(false);
    };

    const renderContent = () => {
        const section = sectionsConfig.find((s) => s.key === activeSection);
        if (!section) {
            return <p>Section inconnue: {activeSection}</p>;
        }

        const { Component } = section;
        return (
            <Component
                onEdit={handleEdit}
                onCreate={handleCreate}
                editingItem={editingItem}
            />
        );
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
            <button 
                onClick={toggleSidebar}
                className="md:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-white shadow-lg"
            >
                <svg 
                    className="w-6 h-6 text-gray-600" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                >
                    <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d={isSidebarOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                    />
                </svg>
            </button>

            {isSidebarOpen && (
                <div 
                    className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-30"
                    onClick={() => setIsSidebarOpen(false)}
                />
            )}

            <div className={`
                fixed md:relative
                inset-y-0 left-0
                transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
                md:translate-x-0 transition-transform duration-300 ease-in-out
                z-40 md:z-0
                w-64 min-h-screen
                bg-white shadow-lg
            `}>
                <Sidebar 
                    userProfile={userProfile}
                    setActiveSection={(section) => {
                        setActiveSection(section);
                        setIsSidebarOpen(false);
                    }}
                />
            </div>

            <main className={`
                flex-grow
                w-full md:w-auto
                p-4 sm:p-6 md:p-8
                transition-all duration-300
                ${isSidebarOpen ? 'md:ml-0' : 'md:ml-0'}
                mt-16 md:mt-0
            `}>
                <div className="max-w-7xl mx-auto">
                    {renderContent()}
                </div>
            </main>
        </div>
    );
};

export default AdminDashboard;
