import React from 'react';
import { FiPhoneCall } from 'react-icons/fi';
import { BiMap } from 'react-icons/bi';
import { LuMailPlus } from 'react-icons/lu';
import { useTranslation } from 'react-i18next';
const ContactInfoItem = ({ icon: Icon, label, value, href }) => (
  <div className="flex items-center space-x-2">
    <Icon className="text-3xl text-black" />
    <div>
      <p className="text-black font-normal text-sm sm:text-base font-dmsans">{label}</p>
      {href ? (
        <a href={href} className="text-[#0B86C8] text-sm sm:text-base hover:underline font-dmsans">
          {value}
        </a>
      ) : (
        <p className="text-[#0B86C8] text-sm sm:text-base font-dmsans">{value}</p>
      )}
    </div>
  </div>
);

const ContactInfo = ({ settings }) => {
  const { t } = useTranslation();
  if (!settings) return null;

  return (
    <div className="relative z-10 px-6 lg:px-12 py-8">
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-6 sm:gap-11">
        <ContactInfoItem
          icon={FiPhoneCall}
          label={t('contact.phone')}
          value={settings.phone}
          href={`tel:${settings.phone}`}
        />
        <ContactInfoItem
          icon={BiMap}
          label={t('contact.locality')}
          value={settings.address}
        />
        <ContactInfoItem
          icon={LuMailPlus}
          label={t('contact.email')}
          value={settings.email}
          href={`mailto:${settings.email}`}
        />
      </div>
    </div>
  );
};

export default ContactInfo; 