import React, { useEffect, useState } from "react";
import { menuConfig } from "../../config/menuConfig";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ setActiveSection, userProfile }) => {
    const [activeMenu, setActiveMenu] = useState(null);
    const navigate = useNavigate();

    const userRole = userProfile?.role || "";

    const filteredMenu = menuConfig.filter(menu => {
        if (userRole === "Consultant") {
            return true;
        }
        return menu.section !== "superAdmin";
    });

    const toggleMenu = (menuKey) => {
        setActiveMenu(activeMenu === menuKey ? null : menuKey);
    };

    const handleMenuClick = (section) => {
        setActiveSection(section);
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("codeVerifier");
        navigate("/");
    };

    return (
        <aside className="w-64 bg-[#17508A] text-white flex flex-col h-full">
            <div className="p-4 text-lg font-bold border-b border-[#0B86C8]">
                <button
                    className="w-full text-left hover:bg-[#0B86C8] p-2 rounded"
                    onClick={() => handleMenuClick("dashboard")}
                >
                    Lusis
                </button>
            </div>
            <nav className="flex-grow p-4">
                <ul className="space-y-2">
                    {filteredMenu.map((menu) => (
                        <li key={menu.key}>
                            {menu.subMenus ? (
                                <>
                                    <button
                                        className="flex justify-between items-center w-full p-2 rounded hover:bg-[#0B86C8] font-dmsans"
                                        onClick={() => toggleMenu(menu.key)}
                                    >
                                        <span>{menu.name}</span>
                                        <span>{activeMenu === menu.key ? "-" : "+"}</span>
                                    </button>
                                    {activeMenu === menu.key && (
                                        <ul className="ml-4 mt-2 space-y-2">
                                            {menu.subMenus.map((subMenu, index) => (
                                                <li key={index}>
                                                    <button
                                                        className="block p-2 rounded w-full text-left hover:bg-[#0B86C8] font-dmsans"
                                                        onClick={() => handleMenuClick(subMenu.section)}
                                                    >
                                                        {subMenu.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            ) : (
                                <button
                                    className="w-full p-2 text-left rounded hover:bg-[#0B86C8]"
                                    onClick={() => handleMenuClick(menu.section)}
                                >
                                    {menu.name}
                                </button>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="p-4 text-base font-dmsans border-t border-[#0B86C8]">
                <button
                    className="w-full text-left hover:bg-[#0B86C8] p-2 rounded"
                    onClick={handleLogout}
                >
                    Déconnexion
                </button>
            </div>
        </aside>
    );
};

export default Sidebar;