import React from 'react';
import Table from '../common/Table';
import { API_BASE_URL } from '../../config/apiConfig';

const LogoPeriodTable = ({ items, onEdit, onDelete }) => {
    const columns = [
        {
            key: 'logo',
            title: 'Logo',
            render: (item) => (
                item.logoUrl ? (
                    <img
                        src={`${API_BASE_URL}${item.logoUrl}`}
                        alt={item.name}
                        className="w-20 h-20 object-contain"
                        onError={(e) => {
                            e.target.src = '/placeholder-logo.png';
                        }}
                    />
                ) : (
                    <div className="w-20 h-20 bg-gray-200 flex items-center justify-center text-gray-400">
                        No logo
                    </div>
                )
            )
        },
        {
            key: 'name',
            title: 'Nom'
        },
        {
            key: 'startDate',
            title: 'Date de début',
            render: (item) => `${item.startDay < 10 ? '0' + item.startDay : item.startDay}/${item.startMonth < 10 ? '0' + item.startMonth : item.startMonth}`
        },
        {
            key: 'endDate',
            title: 'Date de fin',
            render: (item) => `${item.endDay < 10 ? '0' + item.endDay : item.endDay}/${item.endMonth < 10 ? '0' + item.endMonth : item.endMonth}`
        }
    ];

    const handleDelete = async (item) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette période ?')) {
            onDelete(item.id);
        }
    };

    return (
        <Table
            columns={columns}
            data={items}
            onEdit={onEdit}
            onDelete={handleDelete}
            actions={(item) => (
                <>
                    <button
                        className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
                        onClick={() => onEdit(item)}
                    >
                        Modifier
                    </button>
                    <button
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                        onClick={() => handleDelete(item)}
                    >
                        Supprimer
                    </button>
                </>
            )}
        />
    );
};

export default LogoPeriodTable; 