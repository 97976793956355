import React, { useState } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import { create, uploadImage, fetchOne, update } from '../../services/specificServices/clients';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const ClientForm = ({ onSubmit, onCancel, initialData }) => {
  const [files, setFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState(
    initialData?.imageUrl ? [`${API_BASE_URL}${initialData.imageUrl}`] : []
  );

  const fields = [
  ];

  const handleSubmit = async (data) => {
    try {
      if (initialData?.id) {
        if (files.length > 0) {
          await uploadImage(initialData.id, files[0]);

          const updatedClient = await fetchOne(initialData.id);

          if (updatedClient && typeof onSubmit === 'function') {
            onSubmit(updatedClient);
          }
          return true;
        }
        return false;
      }

      const createdClients = [];
      for (const file of files) {
        const clientData = {
          title: data.title || '',
          websiteUrl: data.websiteUrl || '',
        };

        let savedClient = await create(clientData);
        if (savedClient) {
          await uploadImage(savedClient.id, file);
          const updatedClient = await fetchOne(savedClient.id);
          if (updatedClient) {
            createdClients.push(updatedClient);
          }
        }

      }

      if (createdClients.length > 0 && createdClients.length < 2 && typeof onSubmit === 'function') {
        toast.success('Client créé avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(createdClients[0]);
            }
          }
        });
        return true;
      } else if (createdClients.length > 1) {
        toast.success('Clients créés avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(createdClients[0]);
            }
          }
        });
      } else {
        toast.error('Erreur lors de la création des clients', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.error.style,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      alert('Une erreur est survenue. Veuillez réessayer.');
      return false;
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setPreviewImages(selectedFiles.map((file) => URL.createObjectURL(file)));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setPreviewImages((prevImages) => [
      ...prevImages,
      ...droppedFiles.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="border-dashed border-2 border-gray-400 p-4 rounded-md"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <Form
        onSubmit={handleSubmit}
        onCancel={onCancel}
        fields={fields}
        initialValues={initialData || {}}
        title={initialData ? "Modifier le client" : "Créer plusieurs clients"}
        submitLabel={initialData ? "Modifier" : "Créer"}
      >
        {previewImages.length > 0 && (
          <div className="grid grid-cols-3 gap-4 mb-4">
            {previewImages.map((preview, index) => (
              <div key={index} className="relative">
                <img
                  src={preview}
                  alt={`Aperçu ${index + 1}`}
                  className="w-full h-32 object-contain rounded border"
                />
              </div>
            ))}
          </div>
        )}

        <div>
          <label className="block font-semibold mb-1">Logos</label>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
          <p className="text-sm text-gray-500 mt-1">Glissez-déposez ou sélectionnez plusieurs fichiers.</p>
        </div>
      </Form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ClientForm;
