import React from "react";
import { Trans } from 'react-i18next';
const Heading = ({ subtitle, title, classParent = "center", classChild = "center", className = "" }) => {
    return (
        <div className={`text-${classParent} my-8`}>
            <div className={`flex items-center justify-${classChild} mb-2 ${className}`}>
                <span className="h-[2px] w-10 bg-[#0B86C8] mr-4"></span>
                <span className="text-[#0B86C8] text-2xl font-bold font-dmsans">
                    <Trans i18nKey={subtitle} components={{ strong: <strong />, em: <em /> }} />
                </span>
                <span className="h-[2px] w-10 bg-[#0B86C8] ml-4"></span>
            </div>
            <h2 className={`text-2xl sm:text-3xl lg:text-5xl font-bold text-[#898D93] font-dmsans uppercase ${className}`}>
                {title}
            </h2>
        </div>
    );
};

export default Heading; 