import React, { useState } from 'react';
import Heading from '../../components/common/Heading';
import TeamMemberCard from '../../components/team/TeamMemberCard';
import Pagination from '../../components/common/Pagination';
import { useTeamMembers } from '../../hooks/useTeamMembers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Team = () => {
  const { teamMembers, loading, error } = useTeamMembers();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 99;
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-center">
          <p className="text-xl font-semibold">{error}</p>
        </div>
      </div>
    );
  }

  const sortedMembers = [...teamMembers].sort((a, b) => {
    if (a.displayOrder === null) return 1;
    if (b.displayOrder === null) return -1;
    return a.displayOrder - b.displayOrder;
  });

  const totalPages = Math.ceil(sortedMembers.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = sortedMembers.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {teamMembers.length > 0 ? (
        <section className="py-16 px-8">
          <div className="max-w-7xl mx-auto">
            <div className="mb-16">
              <Heading
                subtitle={t('team.subtitle')}
                title={t('team.title')}
                classParent="left"
                classChild="left"
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-16">
              {currentItems.map((member) => (
                <TeamMemberCard
                  key={member.id}
                  firstname={member.firstName}
                  name={member.lastName}
                  role={member.role}
                  image={member.imageUrl}
                  linkedin={member.linkedinUrl}
                  bio={member.bio}
                />
              ))}
            </div>

            <div className="mt-12">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </section>
      ) : (
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center">
            <p className="text-xl text-gray-600 mb-4 font-dmsans">{t('messages.no_members_found')}</p>
            <Link to="/" className="text-[#0B86C8] hover:text-[#289EDC] font-dmsans">
              {t('navigation.home')}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Team; 