import React from 'react';
import { useForm } from 'react-hook-form';

const Form = ({
  onSubmit,
  onCancel,
  fields,
  initialValues = {},
  submitLabel = 'Enregistrer',
  cancelLabel = 'Annuler',
  title,
  children
}) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: initialValues
  });

  const handleCancel = (e) => {
    e.preventDefault();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-6 bg-white rounded shadow-md space-y-4">
      {title && <h2 className="text-2xl font-bold">{title}</h2>}

      {fields.map((field) => (
        <div key={field.name}>
          <label className="block font-semibold mb-1">{field.label}</label>
          {field.type === 'select' ? (
            <select
              {...register(field.name, field.validation)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">{field.placeholder}</option>
              {field.options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : field.type === 'textarea' ? (
            <textarea
              {...register(field.name, field.validation)}
              placeholder={field.placeholder}
              className="w-full p-2 border border-gray-300 rounded h-32"
            />
          ) : (
            <input
              type={field.type}
              {...register(field.name, field.validation)}
              placeholder={field.placeholder}
              className="w-full p-2 border border-gray-300 rounded"
            />
          )}
          {errors[field.name] && (
            <span className="text-red-500 text-sm">{errors[field.name].message}</span>
          )}
        </div>
      ))}

      {children}

      <div className="flex justify-end space-x-4">
        {onCancel && (
          <button
            type="button"
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          >
            {cancelLabel}
          </button>
        )}
        <button
          type="submit"
          className="px-4 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#0B86C8]"
        >
          {submitLabel}
        </button>
      </div>
    </form>
  );
};

export default Form; 