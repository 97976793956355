import React from 'react';
import { API_BASE_URL } from '../../config/apiConfig';
import { Link, useNavigate } from 'react-router-dom';
import { generateSlug } from '../../services/specificServices/services';
import { Trans, useTranslation } from 'react-i18next';

const ServiceCard = ({ service, index }) => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const handleServiceClick = (e) => {
        e.preventDefault();
        const slug = generateSlug(service.name);
        navigate(`/services/${slug}`, { state: { language: i18n.language } });
    };

    return (
        <div className="bg-[#EFF0F1] hover:bg-[#E5E6E7] transition-all duration-300 rounded-lg overflow-hidden p-4 sm:p-6 relative mt-4 sm:mt-6 md:mt-8">
            <div className="absolute top-0 right-0 bg-[#0B86C8] text-white rounded-es-lg px-2 sm:px-3 py-1 text-sm sm:text-base font-bold font-dmsans">
                {String(index + 1).padStart(2, "0")}
            </div>

            {service.iconUrl ? (
                <img
                    src={`${API_BASE_URL}${service.iconUrl}`}
                    alt={service.name}
                    className="w-12 h-12 sm:w-16 sm:h-16 mb-3 sm:mb-4 transition-transform duration-300 hover:scale-110"
                    onError={(e) => {
                        e.target.src = '/placeholder-icon.png';
                    }}
                />
            ) : (
                <div className="w-12 h-12 sm:w-16 sm:h-16 mb-3 sm:mb-4 bg-gray-200 rounded-full flex items-center justify-center">
                    <span className="text-gray-400 text-xs">No icon</span>
                </div>
            )}

            <h2 className="text-xl sm:text-2xl font-semibold text-[#898D93] text-left mb-2 sm:mb-3 font-dmsans">
                {service.name}
            </h2>

            <p className="text-[#B9BBBF] font-dmsans mt-1 sm:mt-2 text-base sm:text-lg text-justify">
                <Trans i18nKey={service.description} components={{ strong: <strong />, em: <em /> }} />
            </p>

            <div className="flex justify-between items-center mt-4 sm:mt-6">
                <button
                    onClick={handleServiceClick}
                    className="text-[#0B86C8] font-bold hover:text-[#17508A] transition-colors duration-300 group flex items-center"
                >
                    <span className="mr-2">En savoir plus</span>
                    <span className="transform group-hover:translate-x-1 transition-transform duration-300">→</span>
                </button>
            </div>
        </div>
    );
};

const ServiceCards = ({ services }) => {
    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 md:py-8">
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-6 sm:gap-8 md:gap-10 lg:gap-12">
                {services.map((service, index) => (
                    <ServiceCard 
                        key={service.id} 
                        service={service} 
                        index={index}
                    />
                ))}
            </div>
        </div>
    );
};

export default ServiceCards; 