import { http } from "../api";

export const fetchAll = () => http("/articles");

export const findIdBySlug = async (slug, language = 'fr') => {
    try {
        const response = await http("/articles");
        const articles = await response;
        
        for (const article of articles) {
            if (language === 'fr') {
                if (generateSlug(article.title) === slug) {
                    return article.id;
                }
            } else {
                try {
                    const translation = await fetchTranslations(article.id, language);
                    if (translation && translation.title) {
                        if (generateSlug(translation.title) === slug) {
                            return article.id;
                        }
                    }
                } catch (error) {
                    console.error(`Erreur lors de la récupération de la traduction pour l'article ${article.id}:`, error);
                    continue;
                }
            }
        }

        const articleByOriginalTitle = articles.find(article => generateSlug(article.title) === slug);
        if (articleByOriginalTitle) {
            return articleByOriginalTitle.id;
        }

        throw new Error('Article non trouvé');
    } catch (error) {
        console.error('Erreur lors de la recherche de l\'article:', error);
        throw error;
    }
};

export const generateSlug = (title) => {
    return title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '')
        .trim();
};

export const fetchOne = async (slug, language = 'fr') => {
    try {
        const id = await findIdBySlug(slug, language);
        if (!id) {
            throw new Error('Article non trouvé');
        }

        const article = await http(`/articles/${id}`);
        if (!article) {
            throw new Error('Article non trouvé');
        }

        if (language !== 'fr') {
            try {
                const translations = await fetchTranslations(id, language);
                if (translations && translations.title) {
                    return {
                        ...article,
                        title: translations.title,
                        content: translations.content || article.content
                    };
                }
            } catch (error) {
                console.error(`Erreur lors de la récupération des traductions pour l'article ${id}:`, error);
                return article;
            }
        }
        
        return article;
    } catch (error) {
        console.error('Erreur lors de la récupération de l\'article:', error);
        throw error;
    }
};

export const fetchTranslations = (articleId, language) => {
    return http(`/articles/${articleId}/translations/${language}`);
};

export const updateTranslation = async (articleId, language, field, translation) => {
  const data = {
    language,
    field,
    translation
  };
  return await http(`/articles/${articleId}/translations`, {
    method: "PUT",
    body: JSON.stringify(data)
  });
};

export const create = (data) => http("/articles", { 
  method: "POST", 
  body: JSON.stringify(data) 
});
export const update = (id, data) => http(`/articles/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});
export const remove = (id) => http(`/articles/${id}`, { 
  method: "DELETE" 
});

export const toggleFeaturedArticle = (id, featured) => {
  return http(`/articles/${id}/feature`, { 
    method: "PUT",
    body: JSON.stringify({
      id: id,
      isFeatured: featured
    })
  });
};

export const updateArticleOrder = (orderedItems) => {
  const orderedIds = orderedItems.map(item => Number(item.id));
  return http("/articles/reorder", {
    method: "PUT",
    body: JSON.stringify(orderedIds)
  });
};

export const uploadImage = async (articleId, formData) => {
  try {
    const response = await http(`/articles/${articleId}/uploadImage`, {
      method: "POST",
      body: formData
    });
    return response;
  } catch (error) {
    console.error('Erreur lors de l\'upload de l\'image:', error);
    throw error;
  }
};

export const updateOrder = async (items) => {
  const orderedIds = items.map(item => item.id);
  return await http('/articles/reorder', {
    method: 'PUT',
    body: JSON.stringify(orderedIds)
  });
};

export const fetchById = async (id, language = 'fr') => {
    try {
        const article = await http(`/articles/${id}`);
        
        if (language !== 'fr') {
            try {
                const translations = await fetchTranslations(id, language);
                if (translations && translations.title) {
                    return {
                        ...article,
                        title: translations.title,
                        content: translations.content || article.content
                    };
                }
            } catch (error) {
                console.error(`Erreur lors de la récupération des traductions pour l'article ${id}:`, error);
            }
        }
        
        return article;
    } catch (error) {
        console.error(`Erreur lors de la récupération de l'article ${id}:`, error);
        throw error;
    }
};
