import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config/apiConfig";
import { fetchOne, fetchById, generateSlug } from "../../services/specificServices/articles";
import { fetchTranslations as fetchTranslationsCategories } from "../../services/specificServices/categories";
import { FaArrowLeft } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const ArticleDetails = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [articleId, setArticleId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const loadInitialArticle = async () => {
            try {
                const data = await fetchOne(slug, i18n.language);
                if (!data) {
                    setError(t('errors.article_not_found'));
                    return;
                }
                setArticleId(data.id);
                setArticle(data);
            } catch (err) {
                try {
                    const otherLang = i18n.language === 'fr' ? 'en' : 'fr';
                    const data = await fetchOne(slug, otherLang);
                    if (data) {
                        await i18n.changeLanguage(otherLang);
                        setArticleId(data.id);
                        setArticle(data);
                        return;
                    }
                } catch (error) {
                    console.error("Erreur lors de la tentative avec l'autre langue:", error);
                }
                
                console.error("Erreur lors du chargement initial:", err);
                setError(t('errors.article_load_error'));
            } finally {
                setLoading(false);
            }
        };

        loadInitialArticle();
    }, [slug]);

    useEffect(() => {
        const updateArticleTranslation = async () => {
            if (!articleId) return;

            try {
                setLoading(true);
                const article = await fetchById(articleId, i18n.language);
                
                let categoryName = "";
                try {
                    const categories = await fetchTranslationsCategories(article.categoryId, i18n.language);
                    categoryName = categories.name;
                } catch (err) {
                    console.error("Erreur catégorie:", err);
                }

                const updatedArticle = {
                    ...article,
                    categoryName: categoryName
                };
                setArticle(updatedArticle);
                setCategoryName(categoryName);

                const newSlug = generateSlug(updatedArticle.title);
                if (newSlug !== slug) {
                    navigate(`/actualites/${newSlug}`, { replace: true });
                }
            } catch (err) {
                console.error("Erreur lors de la mise à jour de la traduction:", err);
                setError(t('errors.article_load_error'));
            } finally {
                setLoading(false);
            }
        };

        updateArticleTranslation();
    }, [i18n.language, articleId]);

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-red-500 text-center">
                    <p className="text-xl font-semibold">{error}</p>
                </div>
            </div>
        );
    }

    if (!article) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p className="text-xl text-gray-600">Article introuvable.</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="relative">
                <img
                    src={
                        article.imagePath?.startsWith('http')
                            ? article.imagePath
                            : `${API_BASE_URL}${article.imagePath}`
                    }
                    alt={article.title}
                    className="h-96 w-full object-cover rounded-lg shadow-lg"
                />
                <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center rounded-lg">
                    <h1 className="text-4xl font-bold text-white text-center px-4 font-dmsans">
                        {article.title}
                    </h1>
                </div>
            </div>

            <div className="mt-8 max-w-4xl mx-auto">
                <div className="flex justify-start items-center mt-8">
                    <Link
                        to="/actualites"
                        className="inline-flex items-center text-[#0B86C8] hover:text-[#289EDC] mb-6 sm:mb-8 font-dmsans"
                    >
                        <FaArrowLeft className="mr-2" /> {t('buttons.return_articles')}
                    </Link>
                </div>
                <div className="flex justify-between items-center mb-6">
                    <span className="inline-block bg-[#0B86C8] text-white px-4 py-2 rounded-full text-sm font-semibold font-dmsans">
                        {article.categoryName || categoryName}
                    </span>
                    <p className="text-gray-500 text-sm font-dmsans">
                        {t('buttons.update')} {new Date(article.updated).toLocaleDateString()}
                    </p>
                </div>

                <div className="prose prose-lg max-w-none font-dmsans">
                    <div
                        dangerouslySetInnerHTML={{ __html: article.content }}
                        className="text-gray-800 leading-relaxed text-justify"
                    />
                </div>
            </div>
        </div>
    );
};

export default ArticleDetails;
