import React from "react";

const CardImage = ({ imagePath, altText, className }) => {
  return (
    <img
      src={imagePath}
      alt={altText}
      className={`object-cover ${className}`}
    />
  );
};

export default CardImage; 