import React, { useEffect, useState } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import { create, fetchOne, update, uploadIcon, uploadDetailedImage, fetchAll, fetchTranslations, updateTranslation } from '../../services/specificServices/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    success: {
        style: {
            background: '#0B86C8',
            color: 'white'
        }
    },
    error: {
        style: {
            background: '#f64915',
            color: 'white'
        }
    }
};

const TabButton = ({ id, label, active, onClick }) => (
    <button
        type="button"
        onClick={() => onClick(id)}
        className={`px-4 py-2 font-semibold rounded-t-lg transition-colors ${active
                ? 'bg-[#0B86C8] text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
    >
        {label}
    </button>
);

const BasicInfoTab = ({
    selectedLanguage,
    availableLanguages,
    handleLanguageChange,
    translations,
    handleTranslationChange,
    handleTranslationBlur,
    previewImage,
    handleFileChange,
}) => (
    <>
        <div className="mb-4">
            <label className="block font-semibold mb-1">Langue</label>
            <select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                className="w-full p-2 border border-gray-300 rounded"
            >
                {availableLanguages.map(lang => (
                    <option key={lang.code} value={lang.code}>
                        {lang.label}
                    </option>
                ))}
            </select>
        </div>
        <div className="mb-4">
            <label className="block font-semibold mb-1">Nom</label>
            <input
                type="text"
                value={translations[selectedLanguage].name}
                onChange={(e) => handleTranslationChange('name', e.target.value)}
                onBlur={(e) => handleTranslationBlur('name', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Nom"
            />
        </div>

        <div className="mb-4">
            <label className="block font-semibold mb-1">Description</label>
            <textarea
                value={translations[selectedLanguage].description}
                onChange={(e) => handleTranslationChange('description', e.target.value)}
                onBlur={(e) => handleTranslationBlur('description', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder="Description"
            />
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                Icône
            </label>
            {previewImage && (
                <img
                    src={previewImage}
                    alt="Preview"
                    className="w-32 h-32 object-contain mb-2 border rounded"
                />
            )}
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="w-full p-2 border border-gray-300 rounded"
            />
        </div>
    </>
);

const DetailedInfoTab = ({
    selectedLanguage,
    translations,
    handleTranslationChange,
    handleTranslationBlur,
    details,
    handleDetailChange,
    handleRemoveDetail,
    handleAddDetail,
    previewDetailedImage,
    handleDetailedImageChange,
}) => (
    <>
        <div className="mb-4">
            <label className="block font-semibold mb-1">Description détaillée</label>
            <textarea
                value={translations[selectedLanguage].detailedDescription}
                onChange={(e) => handleTranslationChange('detailedDescription', e.target.value)}
                onBlur={(e) => handleTranslationBlur('detailedDescription', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder={`Description détaillée`}
            />
        </div>

        <div className="mb-4">
            <label className="block font-semibold mb-1">Titre</label>
            <input
                type="text"
                value={translations[selectedLanguage].detailedTitle}
                onChange={(e) => handleTranslationChange('detailedTitle', e.target.value)}
                onBlur={(e) => handleTranslationBlur('detailedTitle', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder={`Titre détaillé`}
            />
        </div>

        <div className="mb-4">
            <label className="block font-semibold mb-1">Pourquoi Lusis</label>
            <textarea
                value={translations[selectedLanguage].whyAlcyone}
                onChange={(e) => handleTranslationChange('whyAlcyone', e.target.value)}
                onBlur={(e) => handleTranslationBlur('whyAlcyone', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder={`Pourquoi Alcyone`}
            />
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                Détails
            </label>
            {details.map((detail, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                    <textarea
                        value={detail.name}
                        onChange={(e) => handleDetailChange(index, 'name', e.target.value)}
                        placeholder="Nom du détail"
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                    <textarea
                        value={detail.description}
                        onChange={(e) => handleDetailChange(index, 'description', e.target.value)}
                        placeholder="Description du détail"
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                    <button
                        type="button"
                        onClick={() => handleRemoveDetail(index)}
                        className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Supprimer
                    </button>
                </div>
            ))}
            <button
                type="button"
                onClick={handleAddDetail}
                className="px-4 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#289EDC] mt-2"
            >
                Ajouter un détail
            </button>
        </div>

        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                Image détaillée
            </label>
            {previewDetailedImage && (
                <img
                    src={previewDetailedImage}
                    alt="Detailed Preview"
                    className="w-64 max-h-64 object-cover mb-2 border rounded"
                />
            )}
            <input
                type="file"
                accept="image/*"
                onChange={handleDetailedImageChange}
                className="w-full p-2 border border-gray-300 rounded"
            />
        </div>
    </>
);

const ServiceForm = ({ item = null, onSubmit, onCancel }) => {
    const [file, setFile] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('fr');
    const availableLanguages = [
        { code: 'fr', label: 'Français' },
        { code: 'en', label: 'Anglais' },
    ];
    const [allTranslations, setAllTranslations] = useState({
        fr: {
            name: item?.name || '',
            description: item?.description || '',
            detailedTitle: item?.detailedTitle || '',
            detailedDescription: item?.detailedDescription || '',
            whyAlcyone: item?.whyAlcyone || ''
        },
        en: {
            name: '',
            description: '',
            detailedTitle: '',
            detailedDescription: '',
            whyAlcyone: ''
        }
    });
    const [detailedImageFile, setDetailedImageFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(
        item?.iconUrl ? `${API_BASE_URL}${item.iconUrl}` : ''
    );
    const [previewDetailedImage, setPreviewDetailedImage] = useState(
        item?.detailedImageUrl ? `${API_BASE_URL}${item.detailedImageUrl}` : ''
    );
    const [details, setDetails] = useState(item?.details || []);
    const [activeTab, setActiveTab] = useState('basic');

    const fields = [
    ];

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        }
    };

    const handleDetailedImageChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setDetailedImageFile(selectedFile);
            setPreviewDetailedImage(URL.createObjectURL(selectedFile));
        }
    };

    const handleLanguageChange = async (e) => {
        const newLanguage = e.target.value;
        setSelectedLanguage(newLanguage);
        if (item?.id && newLanguage !== 'fr') {
            await loadTranslations(item.id, newLanguage);
        }
    };

    useEffect(() => {
        if (item) {
            setPreviewImage(item?.iconUrl ? `${API_BASE_URL}${item.iconUrl}` : '');
            setAllTranslations(prev => ({
                ...prev,
                fr: {
                    name: item.name || '',
                    description: item.description || '',
                    detailedTitle: item.detailedTitle || '',
                    detailedDescription: item.detailedDescription || '',
                    whyAlcyone: item.whyAlcyone || ''
                }
            }));
            if (item.id) {
                loadTranslations(item.id, selectedLanguage);
            }
        }
    }, [item]);

    const loadTranslations = async (serviceId, language) => {
        if (language === 'fr') return;
        try {
            const data = await fetchTranslations(serviceId, language);
            if (data) {
                setAllTranslations(prev => ({
                    ...prev,
                    [language]: {
                        name: data.name || '',
                        description: data.description || '',
                        detailedTitle: data.detailedTitle || '',
                        detailedDescription: data.detailedDescription || '',
                        whyAlcyone: data.whyAlcyone || '',
                    }
                }));
            }
        } catch (error) {
            console.error('Erreur lors du chargement des traductions:', error);
        }
    };

    const handleAddDetail = () => {
        setDetails([...details, { name: '', description: '' }]);
    };

    const handleRemoveDetail = (index) => {
        const newDetails = details.filter((_, i) => i !== index);
        setDetails(newDetails);
    };

    const handleDetailChange = (index, field, value) => {
        const newDetails = [...details];
        newDetails[index] = { ...newDetails[index], [field]: value };
        setDetails(newDetails);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submitData = {
            name: allTranslations.fr.name,
            description: allTranslations.fr.description,
            detailedTitle: allTranslations.fr.detailedTitle,
            detailedDescription: allTranslations.fr.detailedDescription,
            whyAlcyone: allTranslations.fr.whyAlcyone,
            iconUrl: !file && item?.iconUrl ? item.iconUrl : undefined,
            detailedImageUrl: !detailedImageFile && item?.detailedImageUrl ? item.detailedImageUrl : undefined,
            details: details.filter(detail => detail.name && detail.description)
                .map(detail => ({
                    id: detail.id,
                    name: detail.name,
                    description: detail.description
                }))
        };

        if (item) {
            try {
                await update(item.id, submitData);
                let finalService = await fetchOne(item.id);

                if (file) {
                    await uploadIcon(item.id, file);
                    finalService = await fetchOne(item.id);
                }

                if (detailedImageFile) {
                    await uploadDetailedImage(item.id, detailedImageFile);
                    finalService = await fetchOne(item.id);
                }

                return new Promise((resolve) => {
                    toast.success('Service mis à jour avec succès', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                        style: toastStyle.success.style,
                        onClose: () => {
                            if (typeof onSubmit === 'function') {
                                onSubmit(finalService);
                            }
                            resolve();
                        }
                    });
                });
            } catch (error) {
                console.error('Erreur lors de la mise à jour:', error);
                toast.error('Erreur lors de la sauvegarde du service', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.error.style
                });
            }
        } else {
            try {
                const newService = await create(submitData);
                let finalService = newService;

                if (newService?.id) {
                    if (file) {
                        await uploadIcon(newService.id, file);
                        finalService = await fetchOne(newService.id);
                    }

                    if (detailedImageFile) {
                        await uploadDetailedImage(newService.id, detailedImageFile);
                        finalService = await fetchOne(newService.id);
                    }

                    for (const lang of Object.keys(allTranslations)) {
                        const translation = allTranslations[lang];
                        if (translation.name || translation.description || translation.detailedTitle || translation.detailedDescription || translation.whyAlcyone) {
                            await updateTranslation(newService.id, lang, 'name', translation.name);
                            await updateTranslation(newService.id, lang, 'description', translation.description);
                            await updateTranslation(newService.id, lang, 'detailedTitle', translation.detailedTitle);
                            await updateTranslation(newService.id, lang, 'detailedDescription', translation.detailedDescription);
                            await updateTranslation(newService.id, lang, 'whyAlcyone', translation.whyAlcyone);
                        }
                    }
                }

                return new Promise((resolve) => {
                    toast.success('Service créé avec succès', {
                        position: "bottom-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: "colored",
                        style: toastStyle.success.style,
                        onClose: () => {
                            if (typeof onSubmit === 'function') {
                                onSubmit(finalService);
                            }
                            resolve();
                        }
                    });
                });
            } catch (error) {
                console.error('Erreur lors de la création:', error);
                toast.error('Erreur lors de la sauvegarde du service', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.error.style
                });
            }
        }
    };

    const handleTranslationChange = (field, value) => {
        setAllTranslations(prev => ({
            ...prev,
            [selectedLanguage]: {
                ...prev[selectedLanguage],
                [field]: value
            }
        }));
    };

    const handleTranslationBlur = async (field, value) => {
        if (item?.id) {
            try {
                await updateTranslation(item.id, selectedLanguage, field, value);
                toast.success('Traduction mise à jour avec succès', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style
                });
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la traduction:', error);
                toast.error('Erreur lors de la mise à jour de la traduction', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.error.style
                });
            }
        }
    };

    return (
        <div className="relative bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <h2 className="text-2xl font-bold mb-6">
                {item ? "Modifier le service" : "Nouveau service"}
            </h2>

            <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <div className="flex space-x-2 mb-4 border-b">
                        <TabButton
                            id="basic"
                            label="Informations de base"
                            active={activeTab === 'basic'}
                            onClick={setActiveTab}
                        />
                        <TabButton
                            id="detailed"
                            label="Informations détaillées"
                            active={activeTab === 'detailed'}
                            onClick={setActiveTab}
                        />
                    </div>

                    {activeTab === 'basic' ? (
                        <BasicInfoTab
                            selectedLanguage={selectedLanguage}
                            availableLanguages={availableLanguages}
                            handleLanguageChange={handleLanguageChange}
                            translations={allTranslations}
                            handleTranslationChange={handleTranslationChange}
                            handleTranslationBlur={handleTranslationBlur}
                            previewImage={previewImage}
                            handleFileChange={handleFileChange}
                        />
                    ) : (
                        <DetailedInfoTab
                            selectedLanguage={selectedLanguage}
                            translations={allTranslations}
                            handleTranslationChange={handleTranslationChange}
                            handleTranslationBlur={handleTranslationBlur}
                            details={details}
                            handleDetailChange={handleDetailChange}
                            handleRemoveDetail={handleRemoveDetail}
                            handleAddDetail={handleAddDetail}
                            previewDetailedImage={previewDetailedImage}
                            handleDetailedImageChange={handleDetailedImageChange}
                        />
                    )}
                </div>

                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#289EDC]"
                    >
                        {item ? "Mettre à jour" : "Créer"}
                    </button>
                </div>
            </form>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                limit={3}
                toastStyle={toastStyle}
            />
        </div>
    );
};

export default ServiceForm; 