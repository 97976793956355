import React from 'react';
import Table from '../common/Table';
import { API_BASE_URL } from '../../config/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    success: {
        style: {
            background: '#0B86C8',
            color: 'white'
        }
    },
    error: {
        style: {
            background: '#f64915',
            color: 'white'
        }
    }
};

const ServiceTable = ({ items, onEdit, onDelete }) => {
    const columns = [
        {
            key: 'icon',
            title: 'Icône',
            render: (item) => (
                item.iconUrl ? (
                    <img
                        src={`${API_BASE_URL}${item.iconUrl}`}
                        alt={item.name}
                        className="w-12 h-12 object-contain"
                        onError={(e) => {
                            e.target.src = '/placeholder-icon.png';
                        }}
                    />
                ) : (
                    <div className="w-12 h-12 bg-gray-200 flex items-center justify-center text-gray-400">
                        <span className="text-xs">No icon</span>
                    </div>
                )
            )
        },
        {
            key: 'name',
            title: 'Nom'
        },
        {
            key: 'description',
            title: 'Description',
            render: (item) => (
                <div className="max-w-md truncate">
                    {item.description}
                </div>
            )
        },
        {
            key: 'projects',
            title: 'Projets',
            render: (item) => (
                <div className="flex items-start justify-start">
                    <span className="px-3 py-1 bg-gray-100 rounded-full text-gray-700">
                        {item.projects?.length || 0} projet{item.projects?.length <= 1 ? '' : 's'}
                    </span>
                </div>
            )
        }
    ];

    const handleDelete = async (item) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce service ?')) {
            try {
                await onDelete(item.id);
                toast.success('Service supprimé avec succès', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style
                });
            } catch (error) {
                toast.error('Erreur lors de la suppression du service', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.error.style
                });
            }
        }
    };

    return (
        <div className="relative">
            <Table
                columns={columns}
                data={items}
                onEdit={onEdit}
                onDelete={handleDelete}
                actions={(item) => (
                    <>
                        <button
                            className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
                            onClick={() => onEdit(item)}
                        >
                            Modifier
                        </button>
                        <button
                            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                            onClick={() => handleDelete(item)}
                        >
                            Supprimer
                        </button>
                    </>
                )}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                limit={3}
                toastStyle={toastStyle}
            />
        </div>
    );
};

export default ServiceTable; 