import React from 'react';
import StatCard from '../common/StatCard';
import { FaUsers, FaAward, FaTasks, FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';


const StatsSection = ({ colorBack, homePage }) => {
    const { t } = useTranslation();

    const stats = [
        {
            number: "20",
            title: t('stats.years'),
            Icon: FaUsers,
        },
        {
            number: "19",
            title: t('stats.consultants'),
            Icon: FaAward,
        },
        {
            number: "30",
            title: t('stats.projets'),
            Icon: FaTasks,
        }
    ];

    return (
        <section className={`${homePage ? 'w-full flex flex-row mx-auto max-w-6xl gap-4' : 'py-8 sm:py-12 lg:py-16 xl:py-20 2xl:py-24 px-4 sm:px-6 lg:px-8 xl:px-12 bg-gray-50'}`}>
            <div className={`${homePage ? 'sm:w-3/5 max-w-7xl xl:max-w-[1650px] mx-auto lg:w-full flex justify-start' : 'max-w-7xl xl:max-w-[1650px] mx-auto'}`}>
                <div className={`grid grid-cols-1 sm:grid-cols-1 ${homePage ? 'gap-2 sm:gap-3 lg:gap-5 lg:grid-cols-4 w-full' : 'gap-6 sm:gap-8 lg:gap-10 lg:grid-cols-3'} justify-center`}>
                    {stats.map((stat, index) => (
                        <StatCard
                            key={index}
                            number={stat.number}
                            title={stat.title}
                            Icon={stat.Icon}
                            colorBackground={colorBack}
                            homePage={homePage}
                        />
                    ))}
                </div>
            </div>
            <div className={`${homePage ? 'w-1/2 sm:w-2/5 flex lg:hidden justify-center xs:items-center' : 'hidden'}`}>
                <img
                    src="./bureaux.jpg"
                    alt="Lusis"
                    className="w-full h-full object-cover lg:object-cover"
                />
            </div>
        </section>
    );
};

export default StatsSection; 