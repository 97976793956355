import React from 'react';
import { Link } from 'react-router-dom';
import StatsSection from './StatsSection';
import { Trans, useTranslation } from 'react-i18next';

const HeroSection = () => {
    const { t } = useTranslation();

    return (
        <div className="bg-[#17508A] overflow-hidden">
            <div className="w-full">
                <div className="flex flex-col lg:flex-row items-center">
                    <div className="w-full lg:w-[95%] space-y-6 background-hero px-4 md:px-6 pb-10 sm:pb-16 lg:pb-20 z-0 relative">
                        <div className="relative font-dmsans antialiased">
                            <div className="w-full max-w-6xl mx-auto pt-4 md:pt-8 lg:pt-24">
                                <div className="text-left font-inter text-white">
                                    <div className="font-extrabold text-3xl sm:text-4xl md:text-5xl lg:text-7xl leading-tight">
                                        <span className="text-white">
                                            Human <span className="text-white font-bold"> Capital </span><br></br>
                                        </span>
                                    </div>
                                    <div className="font-extrabold text-3xl sm:text-4xl md:text-5xl lg:text-7xl leading-tight mt-0 lg:mt-2 flex flex-nowrap">
                                        <span className="text-transparent stroke-white"
                                            style={{ WebkitTextStroke: "1px white" }}>Savoir</span>
                                        <div className="h-[1em] overflow-hidden w-full sm:w-auto">
                                            <ul className="text-transparent stroke-white animate-text-slide"
                                                style={{ WebkitTextStroke: "1px white" }}>
                                                <li className="block">-être</li>
                                                <li className="block">-faire</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-white text-left font-dmsans w-full mx-auto max-w-6xl mb-8">
                                <p className="text-base sm:text-lg mt-2 lg:mt-8 w-full sm:w-3/4 md:w-2/3 lg:w-1/2 text-justify">
                                    <Trans i18nKey="hero.description" components={{ strong: <strong />, em: <em /> }} />
                                </p>
                            </div>

                            <StatsSection colorBack={'#17508A'} homePage={true} />
                        </div>
                    </div>

                    <div className="hidden lg:block w-full lg:w-[22%] h-[390px] sm:h-[400px] md:h-[500px] lg:h-[590px] relative lg:absolute lg:right-0 background-hero-image">
                        <img 
                            src="./bureaux.jpg"
                            alt="Lusis"
                            className="w-full h-full object-contain lg:object-cover"
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default HeroSection; 