import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const JobFilters = ({ filters, setFilters, availableTags, availableLocations, activeJobs }) => {
  const { t } = useTranslation();

  const activeTags = useMemo(() => {
    if (!activeJobs?.length) return [];
    
    const activeTagIds = new Set(
      activeJobs.flatMap(job => job.tags?.map(tag => tag.id) || [])
    );

    return availableTags.filter(tag => activeTagIds.has(tag.id));
  }, [activeJobs, availableTags]);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-lg font-semibold mb-4 font-dmsans">{t('emploi.filtres')}</h3>
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2 font-dmsans">
          {t('emploi.recherche')}
        </label>
        <input
          type="text"
          value={filters.search}
          onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
          placeholder={t('emploi.recherche_placeholder')}
          className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#0B86C8] focus:border-transparent font-worksans"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2 font-dmsans">
          {t('emploi.location')}
        </label>
        <select
          value={filters.location}
          onChange={(e) => setFilters(prev => ({ ...prev, location: e.target.value }))}
          className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#0B86C8] focus:border-transparent font-worksans"
        >
          <option value="">{t('emploi.location_placeholder')}</option>
          {availableLocations.map(location => (
            <option key={location.id} value={location.name}>
              {location.name}
            </option>
          ))}
        </select>
      </div>

      {activeTags.length > 0 && (
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2 font-dmsans">
            {t('emploi.competence')}
          </label>
          <div className="flex flex-wrap gap-2">
            {activeTags.map(tag => (
              <button
                key={tag.id}
                onClick={() => {
                  setFilters(prev => ({
                    ...prev,
                    tags: prev.tags.includes(tag.id)
                      ? prev.tags.filter(id => id !== tag.id)
                      : [...prev.tags, tag.id]
                  }))
                }}
                className={`px-3 py-1 rounded-full text-sm transition-colors ${
                  filters.tags.includes(tag.id)
                    ? 'bg-[#0B86C8] text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                {tag.name}
              </button>
            ))}
          </div>
        </div>
      )}

      <button
        onClick={() => setFilters({
          search: '',
          location: '',
          tags: []
        })}
        className="w-full px-4 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#289EDC] transition-colors font-dmsans"
      >
        {t('buttons.reset_filters')}
      </button>
    </div>
  );
};

export default JobFilters; 