import { http } from "../api";

export const fetchAll = () => http("/job-offers");

export const fetchById = async (id, language = 'fr') => {
  try {
    const response = await http(`/job-offers/${id}`);
    if (!response) {
      throw new Error('Offre non trouvée');
    }

    if (language !== 'fr') {
      try {
        const translations = await fetchTranslations(id, language);
        if (translations) {
          return {
            ...response,
            title: translations.title || response.title,
            description: translations.description || response.description,
            location: translations.location || response.location,
            contractType: translations.contractType || response.contractType,
            status: translations.status || response.status
          };
        }
      } catch (error) {
        console.error(`Erreur lors de la récupération des traductions pour l'offre ${id}:`, error);
      }
    }
    
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'offre:', error);
    throw error;
  }
};

export const fetchBySlug = async (slug, language = 'fr') => {
  try {
    const id = await findIdBySlug(slug, language);
    if (!id) {
      throw new Error('Offre non trouvée');
    }

    const jobOffer = await fetchById(id);
    
    if (language !== 'fr') {
      try {
        const translations = await fetchTranslations(id, language);
        if (translations) {
          return {
            ...jobOffer,
            title: translations.title || jobOffer.title,
            description: translations.description || jobOffer.description,
            location: translations.location || jobOffer.location,
            contractType: translations.contractType || jobOffer.contractType,
            status: translations.status || jobOffer.status
          };
        }
      } catch (error) {
        console.error(`Erreur lors de la récupération des traductions pour l'offre ${id}:`, error);
        return jobOffer;
      }
    }
    
    return jobOffer;
  } catch (error) {
    console.error('Erreur lors de la récupération de l\'offre:', error);
    throw error;
  }
};

export const findIdBySlug = async (slug, language = 'fr') => {
  try {
    const response = await http("/job-offers");
    const jobOffers = await response;
    
    for (const jobOffer of jobOffers) {
      if (language === 'fr') {
        if (generateSlug(jobOffer.title) === slug) {
          return jobOffer.id;
        }
      } else {
        try {
          const translation = await fetchTranslations(jobOffer.id, language);
          if (translation && translation.title) {
            if (generateSlug(translation.title) === slug) {
              return jobOffer.id;
            }
          }
        } catch (error) {
          console.error(`Erreur lors de la récupération de la traduction pour l'offre ${jobOffer.id}:`, error);
          continue;
        }
      }
    }

    throw new Error('Offre non trouvée');
  } catch (error) {
    console.error('Erreur lors de la recherche de l\'offre:', error);
    throw error;
  }
};

export const generateSlug = (title) => {
  return title
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '')
      .trim();
};

export const create = async (data) => {
  try {
    if (data.contractType === '') {
      delete data.contractType;
    }
    const response = await http("/job-offers", { 
      method: "POST", 
      body: JSON.stringify(data) 
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const update = async (id, data) => {
  try {
    if (data.contractType === '') {
      delete data.contractType;
    }
    const response = await http(`/job-offers/${id}`, { 
      method: "PUT", 
      body: JSON.stringify(data) 
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const remove = (id) => http(`/job-offers/${id}`, { 
  method: "DELETE" 
});

export const addTagToJobOffer = (jobOfferId, tagId) => 
  http(`/job-offers/${jobOfferId}/tags/${tagId}`, {
    method: "POST"
  });

export const removeTagFromJobOffer = (jobOfferId, tagId) => 
  http(`/job-offers/${jobOfferId}/tags/${tagId}`, {
    method: "DELETE"
  });

export const fetchTags = () => http("/job-offers/tags");
export const createTag = (data) => http("/job-offers/tags", {
  method: "POST",
  body: JSON.stringify(data)
});

export const deleteTag = (tagId) => http(`/job-offers/tags/${tagId}`, {
  method: "DELETE"
}); 

export const fetchTranslations = (jobOfferId, language) => {
  return http(`/job-offers/${jobOfferId}/translations/${language}`);
};

export const updateTranslation = async (jobOfferId, language, field, translation) => {
  const data = {
    language,
    field,
    translation
  };
  return await http(`/job-offers/${jobOfferId}/translations`, {
    method: "PUT",
    body: JSON.stringify(data)
  });
};