import React, { useEffect, useState } from 'react';
import Form from '../common/Form';
import { create, update, fetchTranslations, updateTranslation } from '../../services/specificServices/categories';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const CategoryForm = ({ item = null, onSubmit, onCancel }) => {
  const fields = [
  ];

  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const [translations, setTranslations] = useState({
    fr: { name: '' },
    en: { name: '' }
  });

  const availableLanguages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' }
  ];

  useEffect(() => {
    if (item?.id) {
      loadTranslations(item.id, selectedLanguage);
    }
  }, [item?.id, selectedLanguage]);

  const loadTranslations = async (categoryId, language) => {
    try {
      const data = await fetchTranslations(categoryId, language);
      setTranslations(prev => ({
        ...prev,
        [language]: { name: data?.name || '' }
      }));
    } catch (error) {
      console.error('Erreur lors du chargement des traductions:', error);
    }
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
  };

  const handleTranslationChange = (field, value) => {
    setTranslations(prev => ({
      ...prev,
      [selectedLanguage]: {
        ...prev[selectedLanguage],
        [field]: value
      }
    }));
  };

  const handleTranslationBlur = async (field, value) => {
    if (item?.id) {
      try {
        await updateTranslation(item.id, selectedLanguage, field, value);
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la traduction:', error);
      }
    }
  };

  const handleSubmit = async (data) => {
    try {
      let savedCategory;
      const submitData = {
        ...data,
        translations: translations
      };

      const categoryData = {
        name: translations.fr.name
      };

      if (item?.id) {
        savedCategory = await update(item.id, submitData);
        toast.success('Catégorie modifiée avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedCategory);
            }
          }
        });
      } else {
        savedCategory = await create(categoryData);
        toast.success('Catégorie créée avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedCategory);
            }
          }
        });
      }

      for (const lang of Object.keys(translations)) {
        const translation = translations[lang];
        if (translation.name) {
          await updateTranslation(savedCategory.id, lang, 'name', translation.name);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde :', error);
    }
  };

  return (
    <div className='relative'>
      <Form
        onSubmit={handleSubmit}
        onCancel={onCancel}
        fields={fields}
        initialValues={item}
        title={item ? "Modifier la catégorie" : "Nouvelle catégorie"}
        submitLabel={item ? "Mettre à jour" : "Créer"}
      >
        <div className="mb-4">
          <label className="block font-semibold mb-1">Langue</label>
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            {availableLanguages.map(lang => (
              <option key={lang.code} value={lang.code}>
                {lang.label}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block font-semibold mb-1">Nom</label>
            <input
              type="text"
              value={translations[selectedLanguage]?.name || ''}
              onChange={(e) => handleTranslationChange('name', e.target.value)}
              onBlur={(e) => handleTranslationBlur('name', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder={`Nom de la catégorie`}
            />
          </div>
        </div>
      </Form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default CategoryForm; 