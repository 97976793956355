import React from 'react';
import Table from '../common/Table';

const PlaylistTable = ({ items, onEdit, onDelete }) => {
  const columns = [
    {
      key: 'name',
      title: 'Nom',
    },
    {
      key: 'url',
      title: 'URL',
      render: (playlist) => (
        playlist.url ? (
          <a href={playlist.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{playlist.url}</a>
        ) : '-'
      )
    }
  ];

  const handleDelete = (playlist) => {
    const confirmMessage = `Êtes-vous sûr de vouloir supprimer la playlist "${playlist.name}" ?\n\nAttention : Cette action est irréversible et supprimera également tous les articles associés à cette playlist.`;

    if (window.confirm(confirmMessage)) {
      onDelete(playlist.id);
    }
  };

  return (
    <Table
      columns={columns}
      data={items}
      actions={(item) => (
        <>
          <button
            className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
            onClick={() => onEdit(item)}
          >
            Modifier
          </button>
          <button
            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={() => handleDelete(item)}
          >
            Supprimer
          </button>
        </>
      )}
    />
  );
};

export default PlaylistTable; 