export const menuConfig = [
    {
        name: "Articles",
        key: "articles",
        subMenus: [
            { name: "Tous les articles", section: "articles" },
            { name: "Ajouter un article", section: "createArticle" },
            { name: "Toutes les catégories", section: "categories" },
            { name: "Ajouter une catégorie", section: "createCategory" },
        ],
    },
    {
        name: "Bureaux",
        key: "offices",
        subMenus: [
            { name: "Tous les bureaux", section: "offices" },
            { name: "Ajouter un bureau", section: "createOffice" },
        ],
    },
    {
        name: "Équipe",
        key: "team",
        subMenus: [
            { name: "Tous les membres", section: "team" },
            { name: "Ajouter un membre", section: "createTeamMember" },
        ],
    },
    {
        name: "Clients",
        key: "clients",
        subMenus: [
            { name: "Tous les clients", section: "clients" },
            { name: "Ajouter un client", section: "createClient" },
        ],
    },
    {
        name: "Labels",
        key: "labels",
        subMenus: [
            { name: "Tous les labels", section: "labels" },
            { name: "Ajouter un label", section: "createLabel" },
        ],
    },
    {
        name: "Offres d'Emploi",
        key: "jobs",
        subMenus: [
            { name: "Toutes les offres d'emploi", section: "jobs" },
            { name: "Ajouter une offre d'emploi", section: "createJob" },
        ],
    },
    {
        name: "Services",
        key: "services",
        subMenus: [
            { name: "Tous les services", section: "services" },
            { name: "Ajouter un service", section: "createService" },
            { name: "Toutes les références de projets", section: "projects" },
            { name: "Ajouter une référence de projet", section: "createProject" }
        ],
    },
    {
        name: "Mentions légales",
        section: "mentions"
    },
    {
        name: "Politique de confidentialité",
        section: "privacy"
    },
    {
        name: "Informations générales",
        section: "settingsForm"
    },
    {
        name: "Administrateurs",
        section: "superAdmin"
    },
];