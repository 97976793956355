import React from 'react';
import Table from '../common/Table';
import { API_BASE_URL } from '../../config/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const OfficeTable = ({ items, onEdit, onDelete }) => {
  const columns = [
    {
      key: 'imageUrl',
      title: 'Image',
      render: (office) => (
        office.imageUrl ? (
          <img
            src={office.imageUrl.startsWith('http')
              ? office.imageUrl
              : `${API_BASE_URL}${office.imageUrl}`
            }
            alt={office.name}
            className="w-16 h-16 object-cover"
          />
        ) : (
          'Aucune image'
        )
      ),
    },
    {
      key: 'country',
      title: 'Pays',
    },
    {
      key: 'city',
      title: 'Ville',
    }
  ];

  const handleDelete = async (id) => {
    window.confirm('Voulez-vous vraiment supprimer ce bureau ?') && await onDelete(id);
    toast.success('Client supprimé avec succès', {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      style: toastStyle.success.style
    });
  };

  return (
    <div>
      <Table
        columns={columns}
        data={items}
        actions={(item) => (
          <>
            <button
              className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
              onClick={() => onEdit(item)}
            >
              Modifier
            </button>
            <button
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => handleDelete(item.id)}
            >
              Supprimer
            </button>
          </>
        )}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default OfficeTable; 