import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API_BASE_URL } from '../../config/apiConfig';
import { updateOrder } from '../../services/specificServices/articles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ArticleTable = ({ items, onEdit, onDelete, onToggleFeatured, onDragEnd }) => {
  const sortedItems = [...items]
    .map((item, index) => ({
      ...item,
      stringId: `article-${item.id}`,
      displayOrder: item.displayOrder || index
    }))
    .sort((a, b) => {
      if (a.featured && !b.featured) return -1;
      if (!a.featured && b.featured) return 1;
      return (a.displayOrder || 0) - (b.displayOrder || 0);
    });

  const handleToggleFeatured = async (article) => {
    try {
      if (typeof onToggleFeatured !== 'function') {
        console.error('onToggleFeatured n\'est pas une fonction');
        return;
      }
      const newFeaturedState = !article.featured;
      await onToggleFeatured(article.id, newFeaturedState);
    } catch (error) {
      console.error('Erreur lors de la mise en avant:', error);
    }
  };

  const handleDragEnd = async (result) => {
    const { destination, source } = result;

    if (!destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)) {
      return;
    }

    const reorderedItems = Array.from(sortedItems);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    const updatedOrder = reorderedItems.map((item, index) => ({
      id: item.id,
      displayOrder: index
    }));

    try {
      await updateOrder(updatedOrder);

      if (typeof onDragEnd === 'function') {
        onDragEnd(updatedOrder);
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'ordre:', error);
    }
  };

  const handleDelete = async (article) => {
    try {
      const confirmMessage = `Êtes-vous sûr de vouloir supprimer l'article "${article.title}" ?\n\nAttention : Cette action est irréversible et supprimera également tous les commentaires associés à cet article.`;

      if (window.confirm(confirmMessage)) {
        await onDelete(article.id);
        
        toast.success('Article supprimé avec succès', {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: {
            background: '#0B86C8',
            color: 'white'
          }
        });
      }
    } catch (error) {
      toast.error('Erreur lors de la suppression de l\'article', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        style: {
          background: '#f64915',
          color: 'white'
        }
      });
      console.error('Erreur lors de la suppression:', error);
    }
  };

  return (
    <div className='relative'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="overflow-x-auto">
          <Droppable droppableId="droppable-articles">
            {(provided) => (
              <table
                className="min-w-full bg-white rounded"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <tr>
                    <th className="py-3 px-6 text-center"></th>
                    <th className="py-3 px-6 text-left">Image</th>
                    <th className="py-3 px-6 text-left">Titre</th>
                    <th className="py-3 px-6 text-left">Catégorie</th>
                    <th className="py-3 px-6 text-left">Date</th>
                    <th className="py-3 px-6 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700 text-sm">
                  {sortedItems.map((article, index) => (
                    <Draggable
                      key={article.stringId}
                      draggableId={article.stringId}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`border-b border-gray-200 ${snapshot.isDragging ? 'bg-gray-100' : 'hover:bg-gray-50'
                            }`}
                          {...provided.dragHandleProps}
                        >
                          <td className="py-3 px-6 text-center">
                            <button
                              className={`text-2xl focus:outline-none ${article.featured ? "text-yellow-500" : "text-gray-400"
                                }`}
                              onClick={() => handleToggleFeatured(article)}
                              title={article.featured ? "Retirer des favoris" : "Ajouter aux favoris"}
                            >
                              ★
                            </button>
                          </td>
                          <td className="py-3 px-6">
                            {article.imagePath ? (
                              <img
                                src={article.imagePath.startsWith('http')
                                  ? article.imagePath
                                  : `${API_BASE_URL}${article.imagePath}`
                                }
                                alt={article.title}
                                className="w-16 h-16 object-cover"
                              />
                            ) : (
                              "Aucune image"
                            )}
                          </td>
                          <td className="py-3 px-6">{article.title.length > 50 ? `${article.title.substring(0, 50)}...` : article.title}</td>
                          <td className="py-3 px-6">{article.category?.name}</td>
                          <td className="py-3 px-6">
                            {new Date(article.updated).toLocaleDateString()}
                          </td>
                          <td className="py-3 px-6 text-center">
                            <button
                              className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
                              onClick={() => onEdit(article)}
                            >
                              Modifier
                            </button>
                            <button
                              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                              onClick={() => handleDelete(article)}
                            >
                              Supprimer
                            </button>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            )}
          </Droppable>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          limit={3}
        />
      </DragDropContext>
    </div>
  );
};

export default ArticleTable; 