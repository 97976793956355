import React from "react";
import CategoryTag from "./CategoryTag";

const CardTextContent = ({ categoryName, title, content, date, isHero }) => {
    return (
        <div className={isHero ? "" : "pt-4 flex flex-col flex-grow"}>
            <CategoryTag categoryName={categoryName} />
            {!isHero && content && (
                <div 
                    dangerouslySetInnerHTML={{ 
                        __html: content.length > 80 ? `${content.substring(0, 110)}...` : content 
                    }} 
                    className="font-dmsans mb-4" 
                />
            )}
            <p
                className={`${
                    isHero ? "text-sm sm:text-base" : "text-sm sm:text-base"
                } text-[#97989F] font-worksans font-normal ${isHero ? "" : "mt-auto"}`}
            >
                {date}
            </p>
        </div>
    );
};

export default CardTextContent; 