import { http } from "../api";

export const fetchAll = () => http("/articles/categories");
export const fetchOne = (id) => http(`/articles/categories/${id}`);
export const create = (data) => http("/articles/categories", { 
  method: "POST", 
  body: JSON.stringify(data) 
});
export const update = (id, data) => http(`/articles/categories/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});
export const remove = (id) => http(`/articles/categories/${id}`, { 
  method: "DELETE" 
});
export const fetchTranslations = (id, language) => http(`/articles/categories/${id}/translations/${language}`);

export const updateTranslation = (categoryId, language, field, translation) => {
  return http(`/articles/categories/${categoryId}/translations`, {
    method: "PUT",
    body: JSON.stringify({ language, field, translation })
  });
};