import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from './useAuth';

export const AuthGuard = ({ children }) => {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);

    useEffect(() => {
        const checkAuth = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const existingToken = localStorage.getItem('token');
            const intendedPath = localStorage.getItem('intendedPath');

            if (code) {
                try {
                    await auth.retrieveToken(code);
                    if (intendedPath) {
                        localStorage.removeItem('intendedPath');
                        navigate(intendedPath);
                        return;
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération du token:', error);
                }
            }

            if (location.pathname.startsWith('/admin') && !existingToken) {
                localStorage.setItem('intendedPath', location.pathname);
                auth.displayLogin();
                return;
            }

            setIsCheckingAuth(false);
        };

        checkAuth();
    }, [auth, location, navigate]);

    if (isCheckingAuth) {
        return <p>Loading...</p>;
    }

    if (location.pathname.startsWith('/admin')) {
        return auth.isAuthenticated() ? children : null;
    }

    return children;
};

export default AuthGuard;