import { http } from "../api";

export const fetchAll = () => http("/clients");
export const fetchOne = (id) => http(`/clients/${id}`);
export const create = (data) => http("/clients", { 
  method: "POST", 
  body: JSON.stringify(data) 
});
export const update = (id, data) => http(`/clients/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});
export const remove = (id) => http(`/clients/${id}`, { 
  method: "DELETE" 
});

export const uploadImage = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return http(`/clients/${id}/uploadImage`, {
    method: "POST",
    body: formData
  });
};
