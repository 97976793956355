import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { generateSlug } from '../../services/specificServices/jobOffers';

const JobList = ({ jobs }) => {
  const { t } = useTranslation();
  return (
    <div className="space-y-6">
      {jobs.map(job => (
        <div key={job.id} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <div className="flex flex-col sm:flex-row">
            <div className="sm:w-1/3 p-4 flex justify-center items-center">
              <div className="h-full w-full rounded-lg md:max-h-[200px]">
                <img 
                  src={job.imagePath || './Lusis-ACO.png'} 
                  alt={job.title} 
                  className="w-48 h-48 sm:w-full sm:h-full object-cover sm:object-contain px-4" 
                />
              </div>
            </div>

            <div className="sm:w-2/3 p-6 flex flex-col">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-xl font-bold text-gray-800 mb-2 font-dmsans">
                    {job.title}
                  </h3>
                  <p className="text-gray-600 mb-2 font-dmsans">{job.location}</p>
                </div>
              </div>

              <div className="mb-4">
                <p className="text-gray-700 line-clamp-3 font-dmsans">{job.description}</p>
              </div>

              <div className="flex flex-wrap gap-2 mb-4">
                {job.tags?.map(tag => (
                  <span 
                    key={tag.id}
                    className="px-2 py-1 bg-[#0B86C8] text-white rounded-full text-sm font-dmsans"
                  >
                    {tag.name}
                  </span>
                ))}
              </div>

              <div className="mt-auto flex justify-end">
                <Link
                  to={`/carrieres/${generateSlug(job.title)}`}
                  className="flex items-center justify-center px-6 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#289EDC] transition-colors font-dmsans"
                >
                  {t('buttons.see_offers')}
                  <FaPlus className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default JobList; 