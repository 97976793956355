import { http } from "../api";

export const fetchAll = () => http("/services");

export const fetchOne = async (idOrSlug, language = 'fr') => {
    try {
        let id = idOrSlug;
        
        if (isNaN(idOrSlug)) {
            id = await findIdBySlug(idOrSlug, language);
            if (!id) {
                throw new Error('Service non trouvé');
            }
        }

        const service = await http(`/services/${id}`);
        if (!service) {
            throw new Error('Service non trouvé');
        }

        if (language !== 'fr') {
            try {
                const translations = await fetchTranslations(id, language);
                if (translations) {
                    const translatedService = {
                        ...service,
                        name: translations.name || service.name,
                        description: translations.description || service.description,
                        detailedTitle: translations.detailedTitle || service.detailedTitle,
                        detailedDescription: translations.detailedDescription || service.detailedDescription,
                        whyAlcyone: translations.whyAlcyone || service.whyAlcyone,
                        details: service.details.map(detail => ({
                            ...detail,
                            name: translations[`detail_${detail.id}_name`] || detail.name,
                            description: translations[`detail_${detail.id}_description`] || detail.description
                        }))
                    };

                    if (service.projects && service.projects.length > 0) {
                        translatedService.projects = service.projects.map(project => ({
                            ...project,
                            title: translations[`project_${project.id}_title`] || project.title,
                            client: translations[`project_${project.id}_client`] || project.client,
                            context: translations[`project_${project.id}_context`] || project.context,
                            technicalEnvironment: translations[`project_${project.id}_technicalEnvironment`] || project.technicalEnvironment,
                            details: project.details.map(detail => ({
                                ...detail,
                                name: translations[`project_${project.id}_detail_${detail.id}_name`] || detail.name
                            }))
                        }));
                    }

                    return translatedService;
                }
            } catch (error) {
                console.error(`Erreur lors de la récupération des traductions pour le service ${id}:`, error);
                return service;
            }
        }

        return service;
    } catch (error) {
        console.error('Erreur lors de la récupération du service:', error);
        throw error;
    }
};

export const findIdBySlug = async (slug, language = 'fr') => {
    try {
        const response = await http("/services");
        const services = await response;

        for (const service of services) {
            if (language === 'fr') {
                if (generateSlug(service.name) === slug) {
                    return service.id;
                }
            } else {
                try {
                    const translation = await fetchTranslations(service.id, language);
                    if (translation && translation.name) {
                        if (generateSlug(translation.name) === slug) {
                            return service.id;
                        }
                    }
                } catch (error) {
                    console.error(`Erreur lors de la récupération de la traduction pour le service ${service.id}:`, error);
                }
            }
        }

        if (language !== 'fr') {
            for (const service of services) {
                if (generateSlug(service.name) === slug) {
                    return service.id;
                }
            }
        }

        throw new Error('Service non trouvé');
    } catch (error) {
        console.error('Erreur lors de la recherche du service:', error);
        throw error;
    }
};

export const generateSlug = (title) => {
    return title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\./g, '')
        .replace(/\s*&\s*/g, '-')
        .replace(/[^a-z0-9-]/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-+|-+$/g, '')
        .trim();
};

export const fetchTranslations = (id, language) => http(`/services/${id}/translations/${language}`);

export const updateTranslation = async (serviceId, language, field, translation) => {
    const data = {
        language,
        field,
        translation
    };
    return await http(`/services/${serviceId}/translations`, {
        method: "PUT",
        body: JSON.stringify(data)
    });
};

export const create = (data) => http("/services", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
});

export const update = (id, data) => http(`/services/${id}`, {
    method: "PUT",
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
});

export const remove = (id) => http(`/services/${id}`, {
    method: "DELETE"
});

export const uploadIcon = async (serviceId, file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await http(`/services/${serviceId}/uploadIcon`, {
            method: "POST",
            body: formData
        });
        return response;
    } catch (error) {
        console.error('Upload error:', error);
        throw error;
    }
};

export const uploadDetailedImage = async (serviceId, file) => {

    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await http(`/services/${serviceId}/uploadImage`, {
            method: "POST",
            body: formData
        });
        return response;
    } catch (error) {
        console.error('Upload error:', error);
        throw error;
    }
};