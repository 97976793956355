import { http } from "../api";

export const fetchAll = () => http("/playlists");
export const fetchOne = (id) => http(`/playlists/${id}`);
export const create = (data) => http("/playlists", { 
  method: "POST", 
  body: JSON.stringify(data) 
});
export const update = (id, data) => http(`/playlists/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});
export const remove = (id) => http(`/playlists/${id}`, { 
  method: "DELETE" 
});