import React from 'react';
import TableContainer from '../containers/TableContainer';
import FormContainer from '../containers/FormContainer';
import { resourcesConfig } from './resourcesConfig';
import SettingsForm from '../components/forms/SettingsForm';
import MentionForm from '../components/forms/MentionForm';
import PrivacyForm from '../components/forms/PrivacyForm';
import SuperAdminTable from '../components/tables/SuperAdminTable';

const Dashboard = () => (
  <div className="p-6">
    <h1 className="text-2xl font-bold mb-4">Tableau de bord</h1>
    <p>Bienvenue dans l'interface d'administration</p>
  </div>
);

const generateSections = () => {
  const sections = [
    {
      key: "dashboard",
      Component: Dashboard,
      stateKey: null
    }
  ];

  Object.entries(resourcesConfig).forEach(([key, resource]) => {
    sections.push({
      key: resource.routes.list,
      Component: function ListComponent(props) {
        const { service, TableComponent, name, options } = resource;
        return (
          <TableContainer
            service={service}
            TableComponent={TableComponent}
            title={name}
            onEdit={props.onEdit}
            onCreate={props.onCreate}
            options={options}
          />
        );
      },
      stateKey: key
    });

    sections.push({
      key: resource.routes.create,
      Component: function CreateComponent(props) {
        const { service, FormComponent, options } = resource;
        return (
          <FormContainer
            service={service}
            FormComponent={FormComponent}
            onSave={() => props.onEdit(null)}
            onCancel={() => props.onEdit(null)}
            title={options.createFormTitle}
          />
        );
      },
      stateKey: key
    });

    sections.push({
      key: resource.routes.edit,
      Component: function EditComponent(props) {
        const { service, FormComponent, options } = resource;
        return (
          <FormContainer
            service={service}
            FormComponent={FormComponent}
            item={props.editingItem}
            onSave={() => props.onEdit(null)}
            onCancel={() => props.onEdit(null)}
            title={options.editFormTitle}
          />
        );
      },
      stateKey: key
    });
  });

  sections.push({
    key: "mentions",
    Component: () => <MentionForm />,
    stateKey: null
  });

  sections.push({
    key: "privacy",
    Component: () => <PrivacyForm />,
    stateKey: null
  });

  sections.push({
    key: "settingsForm",
    Component: () => <SettingsForm />,
    stateKey: null
  });

  sections.push({
    key: "superAdmin",
    Component: () => <SuperAdminTable />,
    stateKey: null
  });

  return sections;
};

export const sectionsConfig = generateSections();
