import React from 'react';
import Table from '../common/Table';
import { API_BASE_URL } from '../../config/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    success: {
        style: {
            background: '#0B86C8',
            color: 'white'
        }
    },
    error: {
        style: {
            background: '#f64915',
            color: 'white'
        }
    }
};

const ProjectTable = ({ items, onEdit, onDelete }) => {
    const columns = [
        {
            key: 'title',
            title: 'Nom'
        },
        {
            key: 'client',
            title: 'Secteur d\'activité du client',
            render: (item) => (
                <div className="flex items-center space-x-2">
                    <span>{item.client}</span>
                </div>
            )
        },
        {
            key: 'service',
            title: 'Service',
            render: (item) => (
                <div className="flex items-center space-x-2">
                    <span>{item.service?.name}</span>
                </div>
            )
        }
    ];

    const handleDelete = async (item) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce projet ?')) {
            try {
                await onDelete(item.id);
                toast.success('Projet supprimé avec succès', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style
                });
            } catch (error) {
                toast.error('Erreur lors de la suppression du projet', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.error.style
                });
            }
        }
    };

    return (
        <div className="relative">
            <Table
                columns={columns}
                data={items}
                onEdit={onEdit}
                onDelete={handleDelete}
                actions={(item) => (
                    <>
                        <button
                            className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
                            onClick={() => onEdit(item)}
                        >
                            Modifier
                        </button>
                        <button
                            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                            onClick={() => handleDelete(item)}
                        >
                            Supprimer
                        </button>
                    </>
                )}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
};

export default ProjectTable;