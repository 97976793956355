import React, { useEffect, useState } from 'react';
import Table from '../common/Table';
import { fetchTranslations } from '../../services/specificServices/categories';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const CategoryTable = ({ items, onEdit, onDelete }) => {
  const columns = [
    {
      key: 'name',
      title: 'Nom',
    }
  ];
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const loadTranslations = async () => {
      const updatedItems = [...items];
      for (const item of updatedItems) {
        const translation = await fetchTranslations(item.id, 'fr');
        item.name = translation.name;
      }
      setCategories(updatedItems);
    };
    
    loadTranslations();
  }, [items]);

  const handleDelete = (category) => {
    const confirmMessage = `Êtes-vous sûr de vouloir supprimer la catégorie "${category.name}" ?\n\nAttention : Cette action est irréversible et supprimera également tous les articles associés à cette catégorie.`;

    if (window.confirm(confirmMessage)) {
      onDelete(category.id);
      toast.success('Catégorie supprimée avec succès', {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        style: toastStyle.success.style
      });
    }
  };

  return (
    <div className='relative'>
      <Table
        columns={columns}
        data={categories}
        actions={(item) => (
          <>
            <button
              className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
              onClick={() => onEdit(item)}
            >
              Modifier
            </button>
            <button
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => handleDelete(item)}
            >
              Supprimer
            </button>
          </>
        )}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div >
  );
};

export default CategoryTable; 