import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from 'react-i18next';

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "flex", background: "#0B86C8", right: "20px", zIndex: "1000", borderRadius: "50%", width: "50px", height: "50px", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "flex", background: "#0B86C8", left: "20px", zIndex: "1000", borderRadius: "50%", width: "50px", height: "50px", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={onClick}
        />
    );
}

const ProjectCarousel = ({ projects }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useTranslation();

    if (!projects || !Array.isArray(projects)) {
        console.error("projects is not an array or is undefined:", projects);
        return <p className="text-center text-gray-500">Aucun projet disponible</p>;
    }

    const formattedProjects = projects.map(project => {
        try {
            if (typeof project.details === 'string' && project.details.trim() !== '') {
                return {
                    ...project,
                    details: JSON.parse(project.details)
                };
            }
            return {
                ...project,
                details: []
            };
        } catch (error) {
            console.error('Error parsing details for project:', project.id, error);
            return {
                ...project,
                details: []
            };
        }
    });

    const getServiceColor = (serviceName) => {
        switch (serviceName) {
            case 'Compliance & Audit':
                return '#F65824';
            case 'I.T.':
                return '#000000';
            case 'Project Governance':
                return '#0B86C8';
            default:
                return '#808080';
        }
    };

    const settings = {
        infinite: true,
        slidesToShow: 3,
        centerMode: true,
        dots: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                }
            }
        ],
        afterChange: (current) => setActiveIndex(current),
    };

    return (
        <div className="relative mb-8 md:mb-12 lg:mb-16 xl:mt-12">
            <div className="absolute left-0 right-0 h-[250px] sm:h-[280px] md:h-[300px] lg:h-[350px] bg-[#0B86C8] -z-10" />

            <div className="mx-auto w-full bg-custom-gradient-project slider-container">

                <Slider {...settings}>
                    {formattedProjects.map((project, index) => (
                        <div key={project.id} className="px-2 sm:px-3 md:px-4 outline-none">
                            <div className="rounded-lg transition-transform duration-300">
                                <div className="relative h-[550px] sm:h-[550px] md:h-[550px] lg:h-[650px] xl:h-[650px] 2xl:h-[650px] 3xl:h-[550px]">
                                    <div className="relative w-full h-full">
                                        <div
                                            style={{
                                                borderColor: getServiceColor(project.service)
                                            }}
                                            className={`absolute w-full h-full backface-hidden rounded-3xl border-2 shadow-md bg-white px-8 pt-8 pb-4 xs:px-12 xs:pt-8 xs:pb-6 lg:px-6 lg:pt-6 lg:pb-4 flex flex-col justify-between`}
                                        >
                                            <div className="flex items-center justify-center mb-4">
                                                <h5 className="text-xl md:text-2xl font-bold font-dmsans text-gray-800">
                                                    {project.title} - {t('project.sector')} {project.client}
                                                </h5>
                                            </div>

                                            <div className="space-y-3">
                                                <p className="text-sm md:text-base font-medium text-gray-700 font-dmsans">
                                                    {t('project.context')} : <strong> {project.context}</strong>
                                                </p>
                                                <p className="text-sm md:text-base font-medium text-gray-700 font-dmsans">
                                                    {t('project.technicalEnvironment')} :  <strong> {project.technicalEnvironment}</strong>
                                                </p>
                                                <p className="text-sm md:text-base font-medium text-gray-700 font-dmsans">
                                                    {t('project.details')} :
                                                </p>
                                                {project.details && project.details.length > 0 && (
                                                    <ul className="list-disc list-inside">
                                                        {project.details.map((detail, index) => (
                                                            <li key={index} className="text-sm font-dmsans text-gray-700">
                                                                {detail.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>

                                            <div className="mt-8">
                                                <button
                                                    style={{
                                                        backgroundColor: getServiceColor(
                                                            project.service
                                                        ),
                                                    }}
                                                    className={`w-full py-2 text-white rounded-md hover:bg-blue-600 transition-all text-sm md:text-base font-semibold`}
                                                >
                                                    {t('project.metier')} : {project.service}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default ProjectCarousel;
