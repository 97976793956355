import React, { useState, useMemo } from 'react';
import { useJobOffers } from '../../hooks/useJobOffers';
import { useJobTags } from '../../hooks/useJobTags';
import JobFilters from '../../components/jobs/JobFilters';
import JobList from '../../components/jobs/JobList';
import Heading from '../../components/common/Heading';
import { useTranslation } from 'react-i18next';
import LabelsSection from '../../components/sections/LabelsSection';

const Careers = () => {
    const { items, loading: jobsLoading } = useJobOffers();
    const { t } = useTranslation();
    const { tags, loading: tagsLoading } = useJobTags();
    const [filters, setFilters] = useState({
        search: '',
        contractType: '',
        location: '',
        tags: []
    });

    const availableLocations = useMemo(() => {
        const locations = items
            .filter(job => job.status === 'ACTIVE' && job.location)
            .map(job => job.location)
            .filter((location, index, self) => self.indexOf(location) === index)
            .sort();

        return locations.map((location, index) => ({
            id: index + 1,
            name: location
        }));
    }, [items]);

    const filteredJobs = useMemo(() => {
        return items
            .filter(job => job.status === 'ACTIVE')
            .filter(job => {
                if (filters.search && !job.title.toLowerCase().includes(filters.search.toLowerCase())) {
                    return false;
                }

                if (filters.contractType && job.contractType !== filters.contractType) {
                    return false;
                }

                if (filters.location && job.location !== filters.location) {
                    return false;
                }

                if (filters.tags.length > 0) {
                    const jobTagIds = job.tags?.map(t => t.id) || [];
                    if (!filters.tags.every(tagId => jobTagIds.includes(tagId))) {
                        return false;
                    }
                }

                return true;
            });
    }, [items, filters]);

    if (jobsLoading || tagsLoading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    return (
        <>
            <div className="pb-16 pt-8 px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto">
                    <Heading title={t('emploi.title')} subtitle={t('emploi.subtitle')} classChild='left' classParent='left' />
                    <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
                        <div className="lg:col-span-1">
                            <JobFilters
                                filters={filters}
                                setFilters={setFilters}
                                availableTags={tags}
                                availableLocations={availableLocations}
                                activeJobs={filteredJobs}
                            />
                        </div>

                        <div className="lg:col-span-3">
                            {filteredJobs.length === 0 ? (
                                <div className="bg-white rounded-lg shadow-md p-6 text-center">
                                    <p className="text-gray-600">
                                        {t('emploi.no_offer')}
                                    </p>
                                </div>
                            ) : (
                                <JobList jobs={filteredJobs} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <LabelsSection />
        </>
    );
};

export default Careers; 