import React from 'react';

const StatCard = ({ number, title, Icon, colorBackground, homePage }) => {
    return (
        <div className={`relative bg-[${colorBackground}] text-white rounded-lg ${homePage ? 'px-4 py-5 xs:py-6 xs:px-6 sm:py-5 sm:px-6 xs:justify-center lg:px-8 lg:py-8' : 'px-11 py-10'} flex flex-col items-start shadow-md`}>
            <div className={`${homePage ? 'text-xl xs:text-2xl sm:text-2xl lg:text-3xl' : 'text-3xl lg:text-6xl'} font-bold mb-2 font-dmsans`}>{number}</div>
            <div className={`${homePage ? 'text-sm xs:text-base sm:text-base lg:text-lg' : 'text-base lg:text-lg'} font-medium mb-2 font-dmsans`}>{title}</div>
            {Icon && (
                <div className={`${homePage ? 'absolute top-4 right-4 opacity-20 text-xl xs:text-3xl sm:text-5xl lg:text-5xl' : 'absolute top-4 right-4 opacity-20 text-6xl'}`}>
                    <Icon />
                </div>
            )}
        </div>
    );
};

export default StatCard; 