import React from 'react';
import { useCrud } from '../hooks/useCrud';

const TableContainer = ({
  service,
  TableComponent,
  title,
  onEdit,
  onCreate,
  additionalActions = {},
  options = {},
  reload
}) => {
  const {
    items,
    loading,
    error,
    delete: deleteItem,
    setItems,
    ...crudActions
  } = useCrud(service, options);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  const handleCreate = () => {
    if (onCreate) {
      onCreate();
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      if (typeof deleteItem === 'function') {
        await deleteItem(id);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
    }
  };

  const handleToggleFeatured = async (id, featured) => {
    try {
      if (service.toggleFeaturedArticle) {
        await service.toggleFeaturedArticle(id, featured);
        await crudActions.reload();
      }
    } catch (error) {
      console.error('Erreur lors de la mise en avant:', error);
    }
  };

  const handleDragEnd = async (orderedItems) => {
    try {
      
      const updatedItems = [...items].sort((a, b) => {
        const aIndex = orderedItems.findIndex(item => item.id === a.id);
        const bIndex = orderedItems.findIndex(item => item.id === b.id);
        return aIndex - bIndex;
      });
      setItems(updatedItems);

      if (service.updateTeamMemberOrder) {
        await service.updateTeamMemberOrder(orderedItems);
        await crudActions.reload();
      }
      await crudActions.reload();
    } catch (error) {
      console.error('Erreur lors de la réorganisation:', error);
      await crudActions.reload();
    }
  };

  return (
    <div className="p-6 bg-white rounded shadow-md space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{title}</h2>
        <button
          className="px-4 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#0B86C8]"
          onClick={handleCreate}
        >
          {options.addButtonLabel || 'Ajouter'}
        </button>
      </div>
      <TableComponent
        items={items}
        onEdit={onEdit}
        onDelete={handleDeleteItem}
        onToggleFeatured={handleToggleFeatured}
        onDragEnd={handleDragEnd}
        {...additionalActions}
        {...crudActions}
      />
    </div>
  );
};

export default TableContainer; 