import { http } from "../api";

export const fetchAll = () => http("/settings/periods");
export const fetchCurrentPeriod = () => http("/settings/periods/active");
export const fetchOne = (id) => http(`/settings/periods/${id}`);

const isValidPeriod = (startDay, startMonth, endDay, endMonth) => {
    const daysInMonth = (month) => {
        if (month === 2) return 29;
        if ([4, 6, 9, 11].includes(month)) return 30;
        return 31;
    };
    
    if (startDay > daysInMonth(startMonth) || endDay > daysInMonth(endMonth)) {
        return false;
    }

    const startDate = new Date(2024, startMonth - 1, startDay);
    const endDate = new Date(2024, endMonth - 1, endDay);
    
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return false;
    }

    return true;
};

export const create = async (data, file) => {
    try {
        if (!isValidPeriod(data.startDay, data.startMonth, data.endDay, data.endMonth)) {
            throw new Error('Période invalide');
        }

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('startDay', String(data.startDay));
        formData.append('startMonth', String(data.startMonth));
        formData.append('endDay', String(data.endDay));
        formData.append('endMonth', String(data.endMonth));

        if (file) {
            formData.append('file', file);
        }

        const response = await http("/settings/periods/addWithImage", {
            method: "POST",
            body: formData
        });

        return response;
    } catch (error) {
        if (error.message.includes('data is undefined')) {
            return { success: true };
        }
        throw error;
    }
};

export const update = async (id, data) => {
    try {
        const formattedData = {
            name: data.name,
            startDay: data.startDay,
            startMonth: data.startMonth,
            endDay: data.endDay,
            endMonth: data.endMonth,
            logoUrl: data.logoUrl
        };

        const response = await http(`/settings/periods/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formattedData)
        });

        return response;
    } catch (error) {
        if (error.message.includes('data is undefined')) {
            return { success: true };
        }
        throw error;
    }
};

export const remove = (id) => http(`/settings/periods/${id}`, {
    method: "DELETE"
});

export const uploadLogo = (id, file) => {
    const formData = new FormData();
    formData.append('file', file);

    return http(`/settings/periods/${id}/uploadLogo`, {
        method: "POST",
        body: formData,
    });
}; 