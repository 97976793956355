import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CardImage from "./CardImage";
import CardTextContent from "./CardTextContent";
import { fetchTranslations, generateSlug } from "../../services/specificServices/articles";
import { useTranslation } from "react-i18next";

const ArticleCard = ({ article, baseUrl, id }) => {
    const { i18n } = useTranslation();
    const [translatedTitle, setTranslatedTitle] = useState(article.title);

    useEffect(() => {
        const getTranslatedTitle = async () => {
            if (i18n.language === 'fr') {
                setTranslatedTitle(article.title);
                return;
            }

            try {
                const translation = await fetchTranslations(article.id, i18n.language);
                if (translation && translation.title) {
                    setTranslatedTitle(translation.title);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération de la traduction:', error);
                setTranslatedTitle(article.title);
            }
        };

        getTranslatedTitle();
    }, [article.id, article.title, i18n.language]);

    const articleLink = article.linkedInPostId
        ? `https://www.linkedin.com/feed/update/${article.linkedInPostId}`
        : `/actualites/${generateSlug(translatedTitle)}`;
    
    return (
        <Link 
            to={articleLink} 
            key={article.id} 
            target={article.linkedInPostId ? "_blank" : "_self"}
        >
            <div className="bg-white shadow-md rounded-xl overflow-hidden border hover:shadow-lg transition-shadow duration-300 p-4 flex flex-col h-full">
                <CardImage
                    imagePath={
                        article.imagePath === "MultiPhoto"
                            ? "./Lusis-ACO.png"
                            : article.imagePath.startsWith('http')
                                ? article.imagePath
                                : `${baseUrl}${article.imagePath}`
                    }
                    altText={translatedTitle}
                    className="h-64 w-full rounded-md"
                />
                <CardTextContent
                    categoryName={article.category}
                    title={translatedTitle}
                    content={article.content}
                    date={new Date(article.updated).toLocaleDateString()}
                />
            </div>
        </Link>
    );
};

export default ArticleCard; 