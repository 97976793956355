import { http } from "../api";

export const fetchAll = () => http("/team");
export const fetchOne = (id) => http(`/team/${id}`);
export const fetchTranslations = (id, language) => http(`/team/${id}/translations/${language}`);

export const updateTranslation = async (teamMemberId, language, field, translation) => {
  const data = {
    language,
    field,
    translation
  };
  return await http(`/team/${teamMemberId}/translations`, {
    method: "PUT",
    body: JSON.stringify(data)
  });
};

export const create = (data) => http("/team", { 
  method: "POST", 
  body: JSON.stringify(data) 
});
export const update = (id, data) => http(`/team/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});
export const remove = (id) => http(`/team/${id}`, { 
  method: "DELETE" 
});

export const updateTeamMemberOrder = (orderedItems) => {
  const orderedIds = orderedItems.map(item => Number(item.id));
  return http("/team/team-members/reorder", {
    method: "PUT",
    body: JSON.stringify(orderedIds)
  });
};

export const uploadImage = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return http(`/team/${id}/uploadImage`, {
    method: "POST",
    body: formData
  });
}; 