import { API_BASE_URL } from "../config/apiConfig";

export const http = async (endpoint, options = {}) => {
    const token = localStorage.getItem('token') || '';
    const url = endpoint.includes('/user/profile') 
        ? `https://tomcat.alcyone-consulting.lu/vitrine-api${endpoint}` 
        : `${API_BASE_URL}${endpoint}`;

    const method = options.method ? options.method.toUpperCase() : 'GET';

    const headers = {
        ...(method === 'GET' ? {} : { 
            ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
            ...(options.body instanceof FormData ? {} : { 'Content-Type': 'application/json' })
        }),
        ...options.headers,
    };

    try {
        const response = await fetch(url, {
            ...options,
            method,
            headers,
        });

        if (response.status === 204) {
            return { success: true };
        }

        const contentType = response.headers.get('Content-Type');

        if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Une erreur est survenue');
            }
            return data;
        }

        if (contentType && contentType.includes('text/plain')) {
            const text = await response.text();
            if (!response.ok) {
                throw new Error(text || 'Une erreur est survenue');
            }
            return text;
        }

        if (!response.ok) {
            const text = await response.text();
            throw new Error(text || 'Une erreur est survenue');
        }

        return { success: true };
    } catch (error) {
        console.error('Erreur API détaillée:', error.message || error);
        throw error;
    }
};
