import React, { useState, useEffect } from 'react';
import ServiceCards from '../../components/services/ServiceCards';
import ProjectCarousel from '../../components/services/ProjectCarousel';
import { fetchAll as fetchAllServices, fetchTranslations as fetchTranslations } from '../../services/specificServices/services';
import { fetchAll as fetchAllProjects, fetchTranslations as fetchTranslationsProjects } from '../../services/specificServices/projects';
import { fetchAll as fetchAllClients } from '../../services/specificServices/clients';
import Heading from '../../components/common/Heading';
import { useTranslation } from 'react-i18next';
import ClientCarousel from '../../components/clients/ClientCarousel';

const ServicesPage = () => {
    const [services, setServices] = useState([]);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        const loadData = async () => {
            try {
                const servicesData = await fetchAllServices();
                const translatedServicesData = await Promise.all(
                    servicesData.map(async (service) => {
                        const translation = await fetchTranslations(service.id, i18n.language);
                        return {
                            ...service,
                            name: translation?.name || service.name,
                            description: translation?.description || service.description
                        };
                    })
                );
                const projectsData = await fetchAllProjects();
                const translatedProjectsData = await Promise.all(
                    projectsData.map(async (project) => {
                        const translation = await fetchTranslationsProjects(project.id, i18n.language);
                        return {
                            ...project,
                            title: translation?.title || project.title,
                            client: translation?.client || project.client,
                            technicalEnvironment: translation?.technicalEnvironment || project.technicalEnvironment,
                            context: translation?.context || project.context,
                            details: translation?.details || project.details,
                            service: translation?.service || project.service
                        };
                    })
                );
                const clientsData = await fetchAllClients();
                setServices(translatedServicesData);
                console.log(translatedProjectsData);
                setProjects(translatedProjectsData);
                setClients(clientsData);
            } catch (err) {
                setError('Erreur lors du chargement des données');
                console.error('Erreur:', err);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [i18n.language]);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-[50vh]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-[50vh]">
                <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg">
                    <p className="font-semibold">{error}</p>
                    <button
                        onClick={() => window.location.reload()}
                        className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                    >
                        Réessayer
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="pb-6 sm:pb-8 md:pb-12 pt-6 space-y-8 sm:space-y-12 md:space-y-16 bg-gray-50">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Heading
                    subtitle={t('offre.subtitle')}
                    title={t('offre.title')}
                    classParent="center"
                    classChild="center"
                />
            </div>

            <ServiceCards services={services} />

            {clients.length > 0 && (
                <>
                    <Heading
                        subtitle={t('services.client_title')}
                        title={t('services.client_description')}
                        classParent="center"
                        classChild="center"
                    />

                    <ClientCarousel clients={clients} />
                </>
            )}

            {projects.length > 0 && (
                <div className="w-full mt-6">
                    <ProjectCarousel projects={projects} />
                </div>
            )}
        </div>
    );
};

export default ServicesPage; 