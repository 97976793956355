import { useState, useEffect } from 'react';
import { fetchAll, fetchTranslations } from '../services/specificServices/jobOffers';
import { useTranslation } from 'react-i18next';

export const useJobOffers = () => {
  const { i18n } = useTranslation();
  const [translatedItems, setTranslatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadJobOffers = async () => {
      try {
        setLoading(true);
        const jobOffers = await fetchAll();

        const translatedJobOffers = await Promise.all(
          jobOffers.map(async (jobOffer) => {
            const translation = await fetchTranslations(jobOffer.id, i18n.language);
            return {
              ...jobOffer,
              title: translation?.title || jobOffer.title,
              description: translation?.description || jobOffer.description,
              location: translation?.location || jobOffer.location,
              contractType: translation?.contractType || jobOffer.contractType,
              tags: translation?.tags || jobOffer.tags,
              status: translation?.status || jobOffer.status
            };
          })
        );
        setTranslatedItems(translatedJobOffers);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadJobOffers();
  }, [i18n.language]);

  return { items: translatedItems, loading, error };
}; 