import React from 'react';
import Form from '../common/Form';

const PlaylistForm = ({ item = null, onSubmit, onCancel }) => {
    const fields = [
        {
            name: 'name',
            label: 'Titre',
            type: 'text',
            placeholder: 'Titre de la playlist',
            validation: { required: 'Le titre est requis' }
        },
        {
            name: 'url',
            label: 'URL',
            type: 'text',
            placeholder: 'https://www.youtube.com/watch?',
            validation: { required: 'L\'URL est requise' }
        },
    ];

    return (
        <Form
            onSubmit={onSubmit}
            onCancel={onCancel}
            fields={fields}
            initialValues={{
                name: item?.name || '',
                url: item?.url || '',
                ...item
            }}
            title={item ? "Modifier la playlist" : "Nouvelle playlist"}
            submitLabel={item ? "Mettre à jour" : "Créer"}
        />
    );
};

export default PlaylistForm; 