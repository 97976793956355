import React from 'react';
import CeoSection from '../../components/sections/CeoSection';
import StorytellingSection from '../../components/sections/StorytellingSection';
import CultureSection from '../../components/sections/CultureSection';
import GreatPlaceSection from '../../components/sections/GreatPlaceSection';
import StatsSection from '../../components/sections/StatsSection';
import { useCeo } from '../../hooks/useCeo';

const About = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <CeoSection />
            <StorytellingSection />
            <StatsSection colorBack={'#0B86C8'} homePage={false} />
        </div>
    );
};

export default About; 