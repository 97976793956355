import { useState, useEffect, useRef } from 'react';
import { fetchById, fetchBySlug, generateSlug } from '../services/specificServices/jobOffers';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useJobOffer = (slug) => {
    const [jobOffer, setJobOffer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const jobOfferId = useRef(null);
    const initialLoad = useRef(true);

    useEffect(() => {
        let isMounted = true;

        const loadJobOffer = async () => {
            if (!slug) return;
            
            try {
                if (initialLoad.current) {
                    setLoading(true);
                    initialLoad.current = false;
                }
                
                let updatedOffer;
                if (jobOfferId.current) {
                    updatedOffer = await fetchById(jobOfferId.current, i18n.language);
                } else {
                    updatedOffer = await fetchBySlug(slug, i18n.language);
                }

                if (!isMounted) return;

                if (updatedOffer) {
                    jobOfferId.current = updatedOffer.id;
                    setJobOffer(updatedOffer);
                    const newSlug = generateSlug(updatedOffer.title);
                    if (newSlug !== slug) {
                        navigate(`/carrieres/${newSlug}`, { replace: true });
                    }
                    setError(null);
                }
            } catch (err) {
                if (!isMounted) return;
                
                try {
                    const otherLang = i18n.language === 'fr' ? 'en' : 'fr';
                    const data = await fetchBySlug(slug, otherLang);
                    if (!isMounted) return;
                    
                    if (data) {
                        jobOfferId.current = data.id;
                        setJobOffer(data);
                        setError(null);
                        return;
                    }
                } catch (error) {
                    console.error("Erreur lors de la tentative avec l'autre langue:", error);
                }
                
                setError(err);
                setJobOffer(null);
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        };

        loadJobOffer();

        return () => {
            isMounted = false;
        };
    }, [slug, i18n.language, navigate]);

    useEffect(() => {
        initialLoad.current = true;
    }, [slug]);

    return { jobOffer, loading, error };
}; 