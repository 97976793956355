import React, { useEffect, useState } from 'react';
import Form from '../common/Form';
import { useJobTags } from '../../hooks/useJobTags';
import { create, update, addTagToJobOffer, removeTagFromJobOffer, createTag, fetchOne, deleteTag, fetchTranslations, updateTranslation, fetchById } from '../../services/specificServices/jobOffers';
import { IoClose } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const JobOfferForm = ({ item = null, onSubmit, onCancel }) => {
  const { tags, setTags } = useJobTags();
  const [selectedTags, setSelectedTags] = useState(item?.tags?.map(t => t.id) || []);
  const [newTag, setNewTag] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const [translations, setTranslations] = useState({});
  const [allTranslations, setAllTranslations] = useState({
    fr: { title: '', location: '', contractType: '', description: '', status: '' },
    en: { title: '', location: '', contractType: '', description: '', status: '' }
  });
  const availableLanguages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'Anglais' },
  ];

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
    if (item?.id) {
      await loadTranslations(item.id, newLanguage);
    }
  };

  useEffect(() => {
    if (item) {
      if (item.id) {
        loadTranslations(item.id, selectedLanguage);
      }
    }
  }, [item, selectedLanguage]);

  const loadTranslations = async (jobOfferId, language) => {
    try {
      const data = await fetchTranslations(jobOfferId, language);
      setAllTranslations(prev => ({
        ...prev,
        [language]: {
          title: data?.title || '',
          location: data?.location || '',
          contractType: data?.contractType || '',
          description: data?.description || '',
          status: data?.status || ''
        }
      }));
    } catch (error) {
      console.error('Erreur lors du chargement des traductions:', error);
    }
  };

  const fields = [
  ];

  const handleCreateTag = async (e) => {
    e.preventDefault();
    if (!newTag.trim()) return;

    try {
      const createdTag = await createTag({ name: newTag.trim() });
      setTags(prevTags => [...prevTags, createdTag]);
      setSelectedTags(prev => [...prev, createdTag.id]);
      setNewTag('');
    } catch (error) {
      console.error('Erreur lors de la création du tag:', error);
    }
  };

  const handleTranslationChange = (field, value) => {
    setAllTranslations(prev => ({
      ...prev,
      [selectedLanguage]: {
        ...prev[selectedLanguage],
        [field]: value
      }
    }));
  };

  const handleDeleteTag = async (tagToDelete) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer le tag "${tagToDelete.name}" ?`)) {
      try {
        await deleteTag(tagToDelete.id);
        setTags(prevTags => prevTags.filter(tag => tag.id !== tagToDelete.id));
        setSelectedTags(prev => prev.filter(id => id !== tagToDelete.id));
      } catch (error) {
        console.error('Erreur lors de la suppression du tag:', error);
      }
    }
  };

  const handleTranslationBlur = async (field, value) => {
    if (item?.id) {
      try {
        await updateTranslation(item.id, selectedLanguage, field, value);
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la traduction:', error);
      }
    }
  };

  const handleSubmit = async (data) => {
    try {
      const submitData = {
        ...data,
        tags: null,
        title: allTranslations.fr.title,
        location: allTranslations.fr.location,
        contractType: allTranslations.fr.contractType,
        description: allTranslations.fr.description,
        status: allTranslations.fr.status
      };

      let savedOffer;

      if (item?.id) {
        savedOffer = await update(item.id, submitData);
      } else {
        savedOffer = await create(submitData);
      }

      if (!savedOffer) {
        toast.error('Erreur lors de la sauvegarde de l\'offre', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.error.style
        });
        return;
      }

      try {
        if (item?.id) {
          const currentTags = item?.tags?.map(t => t.id) || [];
          for (const tagId of currentTags) {
            await removeTagFromJobOffer(savedOffer.id, tagId);
          }
        }

        for (const tagId of selectedTags) {
          await addTagToJobOffer(savedOffer.id, tagId);
        }

        const updatedOffer = await fetchById(savedOffer.id);

        for (const lang of Object.keys(allTranslations)) {
          const translation = allTranslations[lang];
          if (translation.title || translation.location || translation.contractType || translation.description || translation.status) {
            await updateTranslation(savedOffer.id, lang, 'title', translation.title);
            await updateTranslation(savedOffer.id, lang, 'location', translation.location);
            await updateTranslation(savedOffer.id, lang, 'contractType', translation.contractType);
            await updateTranslation(savedOffer.id, lang, 'description', translation.description);
            await updateTranslation(savedOffer.id, lang, 'status', translation.status);
          }
        }

        await new Promise((resolve) => {
          toast.success(item?.id ? 'Offre mise à jour avec succès' : 'Offre créée avec succès', {
            position: "bottom-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            style: toastStyle.success.style,
            onClose: resolve
          });
        });

        setTimeout(() => {
          if (typeof onSubmit === 'function') {
            onSubmit(updatedOffer);
          }
        }, 1500);

      } catch (error) {
        console.error('Erreur lors de la gestion des tags ou des traductions:', error);
        toast.warning("L'offre a été sauvegardée mais il y a eu un problème avec les tags ou les traductions", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored"
        });
        setTimeout(() => {
          if (typeof onSubmit === 'function') {
            onSubmit(savedOffer);
          }
        }, 1500);
      }

    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error("Une erreur est survenue lors de la sauvegarde de l'offre", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        style: toastStyle.error.style
      });
    }
  };

  return (
    <div className='relative'>
      <Form
        onSubmit={handleSubmit}
        onCancel={onCancel}
        fields={fields}
        initialValues={item}
        title={item ? "Modifier l'offre" : "Nouvelle offre"}
        submitLabel={item ? "Mettre à jour" : "Créer"}
      >
        <div className="mb-4">
          <label className="block font-semibold mb-1">Langue</label>
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            {availableLanguages.map(lang => (
              <option key={lang.code} value={lang.code}>
                {lang.label}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block font-semibold mb-1">Titre</label>
          <input
            type="text"
            value={allTranslations[selectedLanguage].title}
            onChange={(e) => handleTranslationChange('title', e.target.value)}
            onBlur={(e) => handleTranslationBlur('title', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder={`Titre`}
          />
        </div>

        <div className="mb-4">
          <label className="block font-semibold mb-1">Lieu</label>
          <input
            type="text"
            value={allTranslations[selectedLanguage].location}
            onChange={(e) => handleTranslationChange('location', e.target.value)}
            onBlur={(e) => handleTranslationBlur('location', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder={`Lieu`}
          />
        </div>

        <div className="mb-4">
          <label className="block font-semibold mb-1">Description</label>
          <textarea
            value={allTranslations[selectedLanguage].description}
            onChange={(e) => handleTranslationChange('description', e.target.value)}
            onBlur={(e) => handleTranslationBlur('description', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder={`Description`}
          />
        </div>

        <div className="mb-4">
          <label className="block font-semibold mb-1">Statut</label>
          <select
            name="status"
            value={allTranslations[selectedLanguage].status}
            onChange={(e) => handleTranslationChange('status', e.target.value)}
            onBlur={(e) => handleTranslationBlur('status', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Sélectionner un statut</option>
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block font-semibold mb-2">Tags</label>

          <div className="flex gap-2 mb-4">
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              placeholder="Nouveau tag"
              className="flex-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-[#0B86C8]"
            />
            <button
              type="button"
              onClick={handleCreateTag}
              className="px-4 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#289EDC] transition-colors"
            >
              Ajouter
            </button>
          </div>

          <div className="flex flex-wrap gap-2">
            {tags.map(tag => (
              <div
                key={tag.id}
                className={`group flex items-center gap-1 px-3 py-1 rounded-full transition-colors ${selectedTags.includes(tag.id)
                  ? 'bg-[#0B86C8] text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
              >
                <button
                  type="button"
                  onClick={() => {
                    setSelectedTags(prev =>
                      prev.includes(tag.id)
                        ? prev.filter(id => id !== tag.id)
                        : [...prev, tag.id]
                    );
                  }}
                  className="text-sm"
                >
                  {tag.name}
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteTag(tag)}
                  className="opacity-1 group-hover:opacity-100 hover:text-red-500 transition-opacity ml-1"
                  title="Supprimer ce tag"
                >
                  <IoClose className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </Form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={3}
        toastStyle={toastStyle}
      />
    </div>
  );
};

export default JobOfferForm; 