import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { useSettings } from '../../hooks/useSettings';
import { useNavigationConfig } from '../../config/navigationConfig';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Footer = () => {
  const { settings, loadingSettings, errorSettings } = useSettings();
  const { t } = useTranslation();
  const navigationConfig = useNavigationConfig();
  if (loadingSettings) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
      </div>
    );
  }

  if (errorSettings) {
    return (
      <div className="text-red-500 text-center py-8">
        {errorSettings}
      </div>
    );
  }

  return (
    <footer className="bg-custom-gradient-footer-mobile md:bg-custom-gradient-footer-mobile-2 lg:bg-custom-gradient-footer">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:pb-8 lg:py-11 lg:px-10">
        <div className="md:flex md:justify-evenly lg:gap-36 mb-24">
          <div className="mb-6 md:mb-0 w-full md:w-1/2 lg:w-1/5">
            <img
              src={"./Lusis-ACO.png"}
              alt="Logo"
              style={{ filter: 'brightness(0) invert(1)' }}
              className="h-16 w-auto lg:h-24 mb-4 lg:w-auto object-contain sm:h-16 sm:w-auto"
            />
            <p className="text-white font-dmsans font-light text-sm">
              RCS Luxembourg B23955
            </p>
            <div className="flex-row gap-2 flex mt-4">
              <a
                href="https://www.linkedin.com/company/lusis-luxembourg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-[#289EDC] border-white rounded-full"
                aria-label="LinkedIn"
              >
                <FaLinkedin className="w-8 h-8 border p-1.5 rounded-full" />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-5 gap-8 sm:gap-6 sm:grid-cols-5">
            <div className="flex flex-col gap-4 col-span-4 md:col-span-2">
              <h2 className="mb-2 text-lg font-semibold text-white font-dmsans uppercase">
                Contact
              </h2>
              <ul className="text-white font-dmsans font-light text-sm lg:text-base">
                <li className="mb-4">
                  <a href={`https://maps.google.com/?q=${settings?.address}`} target="_blank" rel="noopener noreferrer" className="hover:underline">
                    {settings?.address}
                  </a>
                </li>
                <li className="mb-4">
                  <a href={`mailto:${settings?.email}`} className="hover:underline">
                    {settings?.email}
                  </a>
                </li>
                <li className="">
                  <a href={`tel:${settings?.phone}`} className="hover:underline">
                    {settings?.phone}
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-4 col-span-2 md:col-span-1">
              <h2 className="mb-2 text-lg font-semibold text-white font-dmsans uppercase">
                Pages
              </h2>
              <ul className="text-white font-dmsans font-light text-sm lg:text-base">
                {navigationConfig.slice(1, -1).map((item) => (
                  <li key={item.path} className="mb-4">
                    <Link
                      to={item.path}
                      className="hover:underline"
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col gap-4 col-span-2 md:col-span-2">
              <h2 className="mb-2 text-lg font-semibold text-white font-dmsans uppercase">
                {t('footer.links')}
              </h2>
              <ul className="text-white font-dmsans font-light text-sm lg:text-base">
                <li className="mb-4">
                  <Link
                    to="/mentions-legales"
                    className="hover:underline"
                  >
                    {t('footer.mentions')}
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/politique-confidentialite"
                    className="hover:underline"
                  >
                    {t('footer.policy')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="hover:underline"
                  >
                    {t('footer.support')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-sm lg:text-base text-white text-center font-dmsans">
            &copy; {new Date().getFullYear()}{' '}
            <a href="https://www.alcyone-consulting.lu/" className="hover:underline">
              {settings?.companyName}
            </a>
            . {t('copyright')}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
