import React from 'react';

const ContactMap = ({ mapUrl }) => {
  return (
    <div className="lg:w-2/5 flex items-center justify-center relative px-6 py-12">
      <div className="w-full h-72 lg:h-full bg-white rounded-lg shadow-lg overflow-hidden">
        <iframe
          src={mapUrl}
          title="Google Maps"
          className="absolute inset-0 w-full h-full"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap; 