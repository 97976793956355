import React, { useState, useEffect } from 'react';
import { fetchAll } from '../../services/specificServices/offices';
import OfficeCard from './OfficeCard';
import Heading from '../common/Heading';
import { useTranslation } from 'react-i18next';

const OfficeList = () => {
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const loadOffices = async () => {
      try {
        const data = await fetchAll();
        setOffices(data);
      } catch (err) {
        console.error('Erreur lors du chargement des bureaux:', err);
        setError('Une erreur est survenue lors du chargement des bureaux.');
      } finally {
        setLoading(false);
      }
    };

    loadOffices();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center py-8">
        {error}
      </div>
    );
  }

  return (
    <>
      {offices.length > 1 && (
        <>
          <Heading
            subtitle={t('offices.subtitle')}
            title={t('offices.title')}
            classParent="center"
            classChild="center"
          />
          <div className="container mx-auto p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {offices.map((office) => (
                <OfficeCard key={office.id} office={office} />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OfficeList; 