import React, { useState, useEffect } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import { create, update, uploadImage, fetchOne, fetchTranslations, updateTranslation } from '../../services/specificServices/projects';
import { fetchAll as fetchAllServices } from '../../services/specificServices/services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    success: {
        style: {
            background: '#0B86C8',
            color: 'white'
        }
    },
    error: {
        style: {
            background: '#f64915',
            color: 'white'
        }
    }
};

const ProjectForm = ({ item = null, onSubmit, onCancel }) => {
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(
        item?.imageUrl ? `${API_BASE_URL}${item.imageUrl}` : ''
    );
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(item?.service?.id || '');
    const [clientSector, setClientSector] = useState(item?.client || '');
    const [context, setContext] = useState(item?.context || '');
    const [successes, setSuccesses] = useState(item?.successes || '');
    const [technicalEnvironment, setTechnicalEnvironment] = useState(item?.technicalEnvironment || '');
    const [selectedLanguage, setSelectedLanguage] = useState('fr');
    const [allTranslations, setAllTranslations] = useState({
        fr: { title: '', client: '', context: '', successes: '', technicalEnvironment: '', details: [], service: '' },
        en: { title: '', client: '', context: '', successes: '', technicalEnvironment: '', details: [], service: '' }
    });
    const availableLanguages = [
        { code: 'fr', label: 'Français' },
        { code: 'en', label: 'English' }
    ];

    useEffect(() => {
        const loadData = async () => {
            try {
                const servicesData = await fetchAllServices();
                setServices(servicesData);

                if (item?.service?.id) {
                    setSelectedService(item.service.id.toString());
                }

                if (item?.details && Array.isArray(item.details)) {
                    setAllTranslations(prev => ({
                        ...prev,
                        fr: {
                            ...prev.fr,
                            details: item.details.map(detail => ({
                                id: detail.id,
                                name: detail.name
                            }))
                        }
                    }));
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error);
            }
        };
        loadData();
    }, [item]);

    useEffect(() => {
        if (item?.id) {
            loadTranslations(item.id, selectedLanguage);
        }
    }, [item, selectedLanguage]);

    const handleServiceChange = (e) => {
        setSelectedService(e.target.value);
    };

    const handleAddAction = () => {
        const newAction = { name: '' };
        setAllTranslations(prev => ({
            ...prev,
            [selectedLanguage]: {
                ...prev[selectedLanguage],
                details: [...prev[selectedLanguage].details, newAction]
            }
        }));
    };

    const handleRemoveAction = (index) => {
        setAllTranslations(prev => ({
            ...prev,
            [selectedLanguage]: {
                ...prev[selectedLanguage],
                details: prev[selectedLanguage].details.filter((_, i) => i !== index)
            }
        }));
    };

    const handleActionChange = (index, field, value) => {
        setAllTranslations(prev => ({
            ...prev,
            [selectedLanguage]: {
                ...prev[selectedLanguage],
                details: prev[selectedLanguage].details.map((action, i) =>
                    i === index ? { ...action, [field]: value } : action
                )
            }
        }));
    };

    const handleSubmit = async (formData) => {
        try {
            const formattedDetails = allTranslations.fr.details
                .filter(action => action.name.trim())
                .map(action => ({
                    id: action.id || null,
                    name: action.name.trim()
                }));

            const dataToSubmit = {
                title: formData.title,
                service: { id: parseInt(selectedService) },
                client: clientSector,
                context: context,
                successes: successes,
                technicalEnvironment: technicalEnvironment,
                details: formattedDetails,
                imageUrl: !file && item ? item.imageUrl : undefined
            };

            const submitData = {
                ...dataToSubmit,
                title: allTranslations.fr.title,
                client: allTranslations.fr.client,
                context: allTranslations.fr.context,
                successes: allTranslations.fr.successes,
                technicalEnvironment: allTranslations.fr.technicalEnvironment,
                details: allTranslations.fr.details,
            };

            let finalProject;

            if (item) {
                finalProject = await update(item.id, submitData);

                if (file) {
                    const uploadResponse = await uploadImage(item.id, file);
                    const imageUrl = extractImageUrlFromResponse(uploadResponse);
                    if (imageUrl) {
                        finalProject = {
                            ...finalProject,
                            imageUrl: imageUrl
                        };
                    }
                }
                toast.success('Projet mis à jour avec succès', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style,
                    onClose: () => {
                        if (typeof onSubmit === 'function') {
                            onSubmit(finalProject);
                        }
                    }
                });
            } else {
                finalProject = await create(submitData);

                if (file && finalProject?.id) {
                    const uploadResponse = await uploadImage(finalProject.id, file);
                    const imageUrl = extractImageUrlFromResponse(uploadResponse);
                    if (imageUrl) {
                        finalProject = {
                            ...finalProject,
                            imageUrl: imageUrl
                        };
                    }
                }
                toast.success('Projet créé avec succès', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style,
                    onClose: () => {
                        if (typeof onSubmit === 'function') {
                            onSubmit(finalProject);
                        }
                    }
                });
            }

            for (const language of availableLanguages) {
                const translationData = allTranslations[language.code];

                await updateTranslation(finalProject.id, language.code, 'details', JSON.stringify(translationData.details));

                const selectedServiceName = services.find(s => s.id.toString() === selectedService)?.name || '';
                await updateTranslation(finalProject.id, language.code, 'service', selectedServiceName);

                await updateTranslation(finalProject.id, language.code, 'title', translationData.title);
                await updateTranslation(finalProject.id, language.code, 'client', translationData.client);
                await updateTranslation(finalProject.id, language.code, 'context', translationData.context);
                await updateTranslation(finalProject.id, language.code, 'successes', translationData.successes);
                await updateTranslation(finalProject.id, language.code, 'technicalEnvironment', translationData.technicalEnvironment);
            }
        } catch (error) {
            console.error('Erreur lors de la soumission:', error);
            toast.error('Erreur lors de la sauvegarde du projet', {
                position: "bottom-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                style: toastStyle.error.style
            });
        }
    };

    const extractImageUrlFromResponse = (response) => {
        if (typeof response === 'string' && response.includes('uploaded successfully:')) {
            const match = response.match(/uploaded successfully: (.+)$/);
            if (match && match[1]) {
                return match[1].trim();
            }
        }
        return null;
    };

    const handleLanguageChange = async (e) => {
        const newLanguage = e.target.value;
        setSelectedLanguage(newLanguage);
        if (item?.id) {
            await loadTranslations(item.id, newLanguage);
        }
    };

    const loadTranslations = async (projectId, language) => {
        try {
            const data = await fetchTranslations(projectId, language);

            let parsedDetails = [];
            if (data?.details) {
                try {
                    parsedDetails = typeof data.details === 'string'
                        ? JSON.parse(data.details)
                        : (Array.isArray(data.details) ? data.details : []);
                } catch (e) {
                    console.error('Erreur lors du parsing des détails:', e);
                    parsedDetails = [];
                }
            }

            setAllTranslations(prev => ({
                ...prev,
                [language]: {
                    title: data?.title || '',
                    client: data?.client || '',
                    context: data?.context || '',
                    successes: data?.successes || '',
                    technicalEnvironment: data?.technicalEnvironment || '',
                    details: parsedDetails,
                    service: data?.service || ''
                }
            }));
        } catch (error) {
            console.error('Erreur lors du chargement des traductions:', error);
        }
    };

    const handleTranslationChange = (field, value) => {
        setAllTranslations(prev => ({
            ...prev,
            [selectedLanguage]: {
                ...prev[selectedLanguage],
                [field]: value
            }
        }));
    };

    const handleTranslationBlur = async (field, value) => {
        if (item?.id) {
            try {
                const valueToSend = field === 'details' ? JSON.stringify(value) : value;
                await updateTranslation(item.id, selectedLanguage, field, valueToSend);
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la traduction:', error);
            }
        }
    };

    const fields = [
    ];

    const initialValues = item ? {
        title: item.title,
        context: item.context,
        successes: item.successes,
        technicalEnvironment: item.technicalEnvironment,
    } : {};

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                onCancel={onCancel}
                fields={fields}
                initialValues={initialValues}
                title={item ? "Modifier le projet" : "Nouveau projet"}
                submitLabel={item ? "Mettre à jour" : "Créer"}
            >
                <div className="mt-4">
                    <label className="block font-semibold mb-1">Langue</label>
                    <select
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        {availableLanguages.map(lang => (
                            <option key={lang.code} value={lang.code}>
                                {lang.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Nom du projet */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Nom du projet
                    </label>
                    <input
                        type="text"
                        value={allTranslations[selectedLanguage].title}
                        onChange={(e) => handleTranslationChange('title', e.target.value)}
                        onBlur={(e) => handleTranslationBlur('title', e.target.value)}
                        placeholder="Titre"
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Secteur d’activité du client
                    </label>
                    <input
                        type="text"
                        value={allTranslations[selectedLanguage].client}
                        onChange={(e) => handleTranslationChange('client', e.target.value)}
                        onBlur={(e) => handleTranslationBlur('client', e.target.value)}
                        placeholder="Secteur d'activité"
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Service lié
                    </label>
                    <select
                        value={selectedService}
                        onChange={handleServiceChange}
                        className="w-full p-2 border border-gray-300 rounded"
                        required
                    >
                        <option value="">Sélectionner un service</option>
                        {services.map(service => (
                            <option key={service.id} value={service.id}>
                                {service.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Contexte</label>
                    <textarea
                        value={allTranslations[selectedLanguage].context}
                        onChange={(e) => handleTranslationChange('context', e.target.value)}
                        onBlur={(e) => handleTranslationBlur('context', e.target.value)}
                        placeholder="Contexte du projet"
                        className="w-full p-2 border border-gray-300 rounded"
                        rows={4}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Environnement technique/Compétences techniques mobilisées
                    </label>
                    <textarea
                        value={allTranslations[selectedLanguage].technicalEnvironment}
                        onChange={(e) => handleTranslationChange('technicalEnvironment', e.target.value)}
                        onBlur={(e) => handleTranslationBlur('technicalEnvironment', e.target.value)}
                        placeholder="Compétences techniques utilisées"
                        className="w-full p-2 border border-gray-300 rounded"
                        rows={4}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Actions
                    </label>
                    {allTranslations[selectedLanguage].details.map((action, index) => (
                        <div key={index} className="flex items-center space-x-2 mb-2">
                            <input
                                type="text"
                                value={action.name || ''}
                                onChange={(e) => handleActionChange(index, 'name', e.target.value)}
                                onBlur={() => handleTranslationBlur('details', allTranslations[selectedLanguage].details)}
                                placeholder=""
                                className="w-1/3 p-2 border border-gray-300 rounded"
                                required
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveAction(index)}
                                className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                            >
                                Supprimer
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddAction}
                        className="px-4 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#289EDC] mt-2"
                    >
                        Ajouter une action
                    </button>
                </div>
            </Form>
            <ToastContainer
                position="bottom-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                limit={3}
                toastStyle={toastStyle}
            />
        </>
    );
};

export default ProjectForm;
