import { useState, useEffect } from 'react';
import { fetchAll, fetchTranslations } from '../services/specificServices/articles';
import { fetchTranslations as fetchCategoryTranslations } from '../services/specificServices/categories';
import { useTranslation } from 'react-i18next';

export const useArticles = () => {
  const [translatedItems, setTranslatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadArticles = async () => {
      try {
        setLoading(true);
        const articles = await fetchAll();

        const translatedArticles = await Promise.all(
          articles.map(async (article) => {
            const translation = await fetchTranslations(article.id, i18n.language);
            const category = await fetchCategoryTranslations(article.categoryId, i18n.language);
            return {
              ...article,
              title: translation?.title || article.title,
              content: translation?.content || article.content,
              category: category?.name,
            };
          })
        );
        setTranslatedItems(translatedArticles);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadArticles();
  }, [i18n.language]);

  return { items: translatedItems, loading, error };
}; 