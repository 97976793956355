import React, { useEffect, useState } from 'react';
import Form from '../common/Form';
import { create, update, updateTranslation, fetchTranslations, fetchAll } from '../../services/specificServices/privacy';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const PrivacyForm = ({ item = null, onSubmit, onCancel }) => {
  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const [allTranslations, setAllTranslations] = useState({
    fr: { content: '<p><br></p>' },
    en: { content: '<p><br></p>' }
  });
  const [existingPrivacy, setExistingPrivacy] = useState(item);

  const availableLanguages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' }
  ];

  useEffect(() => {
    const loadPrivacy = async () => {
      try {
        if (!item) {
          const privacies = await fetchAll();
          if (privacies && privacies.length > 0) {
            setExistingPrivacy(privacies[0]);
            const promises = availableLanguages.map(async lang => {
              const data = await fetchTranslations(privacies[0].id, lang.code);
              return { lang: lang.code, data };
            });

            const results = await Promise.all(promises);
            setAllTranslations(prev => {
              const newTranslations = { ...prev };
              results.forEach(({ lang, data }) => {
                newTranslations[lang] = {
                  content: data?.content || prev[lang].content
                };
              });
              return newTranslations;
            });
          }
        }
      } catch (error) {
        console.error('Erreur lors du chargement de la politique:', error);
      }
    };

    loadPrivacy();
  }, [item]);

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setAllTranslations(prev => ({
      ...prev,
      [selectedLanguage]: {
        ...prev[selectedLanguage],
        content: prev[selectedLanguage].content === '<p><br></p>' ? 
          prev[selectedLanguage].content : 
          prev[selectedLanguage].content
      }
    }));
    setSelectedLanguage(newLanguage);
  };

  const handleTranslationChange = (field, value) => {
    if (value !== '<p><br></p>' || allTranslations[selectedLanguage][field] === '<p><br></p>') {
      setAllTranslations(prev => ({
        ...prev,
        [selectedLanguage]: {
          ...prev[selectedLanguage],
          [field]: value
        }
      }));
    }
  };

  const handleTranslationBlur = async (field, value) => {
    if (existingPrivacy?.id) {
      try {
        await updateTranslation(existingPrivacy.id, selectedLanguage, field, value);
        toast.success('Traduction mise à jour avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style
        });
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la traduction:', error);
        toast.error('Erreur lors de la mise à jour de la traduction', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.error.style
        });
      }
    }
  };

  const handleSubmit = async (data) => {
    try {
      const submitData = {
        ...data,
        privacy: allTranslations[selectedLanguage].content
      };

      let savedPrivacy;

      if (existingPrivacy?.id) {
        savedPrivacy = await update(existingPrivacy.id, submitData);
      } else {
        savedPrivacy = await create(submitData);
      }

      for (const language of availableLanguages) {
        await updateTranslation(savedPrivacy.id, language.code, 'content', allTranslations[language.code].content);
      }

      setExistingPrivacy(savedPrivacy);

      const updatedTranslations = {};
      for (const lang of availableLanguages) {
        const data = await fetchTranslations(savedPrivacy.id, lang.code);
        updatedTranslations[lang.code] = {
          content: data?.content || allTranslations[lang.code].content
        };
      }
      setAllTranslations(updatedTranslations);

      toast.success('Politique de confidentialité mise à jour avec succès', {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        style: toastStyle.success.style
      });

      if (typeof onSubmit === 'function') {
        onSubmit(savedPrivacy);
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      toast.error('Erreur lors de la sauvegarde de la politique de confidentialité', {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        style: toastStyle.error.style
      });
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      ['clean']
    ],
  };

  return (
    <div className="relative">
      <Form
        onSubmit={handleSubmit}
        onCancel={onCancel}
        fields={[]}
        initialValues={existingPrivacy}
        title={existingPrivacy?.id ? "Modifier la politique de confidentialité" : "Créer la politique de confidentialité"}
        submitLabel={existingPrivacy?.id ? "Mettre à jour" : "Créer"}
      >
        <div className="mt-8">
          <div className="mb-4">
            <label className="block font-semibold mb-1">Langue</label>
            <select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              {availableLanguages.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.label}
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block font-semibold mb-1">Contenu</label>
              <ReactQuill
                theme="snow"
                value={allTranslations[selectedLanguage].content}
                onChange={(value) => handleTranslationChange('content', value)}
                onBlur={() => handleTranslationBlur('content', allTranslations[selectedLanguage].content)}
                modules={modules}
                className="bg-white h-[500px] mb-16"
              />
            </div>
          </div>
        </div>
      </Form>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={3}
        toastStyle={toastStyle}
      />
    </div>
  );
};

export default PrivacyForm; 