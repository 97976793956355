import { useState, useEffect } from 'react';
import { fetchAll, fetchTranslations } from '../services/specificServices/mentions';
import { useTranslation } from 'react-i18next';

export const useMentions = () => {
  const [translatedItems, setTranslatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadMentions = async () => {
      try {
        setLoading(true);
        const mentions = await fetchAll();

        const translatedMentions = await Promise.all(
          mentions.map(async (mention) => {
            const translation = await fetchTranslations(mention.id, i18n.language);
            return {
              ...mention,
              mentions: translation?.mentions || mention.mentions,
            };
          })
        );
        setTranslatedItems(translatedMentions);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadMentions();
  }, [i18n.language]);

  return { items: translatedItems, loading, error };
}; 