import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { fetchOne, generateSlug } from '../../services/specificServices/services';
import Heading from '../../components/common/Heading';
import { API_BASE_URL } from '../../config/apiConfig';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Trans, useTranslation } from 'react-i18next';

const AccordionItem = ({ detail, isOpen, onClick, index }) => {
    return (
        <div className="border-b border-gray-200 last:border-b-0">
            <button
                className={`w-full py-4 sm:py-6 px-4 sm:px-6 text-left focus:outline-none flex items-center transition-colors group
                    ${isOpen ? 'bg-[#0B86C8]' : 'bg-[#EFF0F1]'} ${isOpen ? 'text-white' : 'text-[#898D93]'}`}
                onClick={onClick}
            >
                <span className={`font-bold mr-2 sm:mr-4 text-base sm:text-lg ${isOpen ? 'text-white' : 'text-gray-500'}`}>
                    {String(index + 1).padEnd(2, '.')}
                </span>
                <div className="flex-grow">
                    <span className={`text-base sm:text-lg font-semibold ${isOpen ? 'text-white' : 'text-gray-500'} font-dmsans`}>
                        <Trans i18nKey={detail.name} components={{ strong: <strong />, em: <em /> }} />
                    </span>
                </div>
                <svg className={`w-5 h-5 sm:w-6 sm:h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-180 text-white' : 'text-[#17508A]'}`}
                    fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            <div className={`overflow-hidden transition-all shadow-md mb-4 duration-300 ease-in-out bg-gray-50 ${isOpen ? 'max-h-96' : 'max-h-0'}`}>
                <div className="p-4 sm:p-6">
                    <p className="text-[#B9BBBF] font-dmsans leading-relaxed text-sm sm:text-base whitespace-pre-wrap">
                        <Trans i18nKey={(detail.description)} components={{ strong: <strong />, em: <em />, br: <br />, u: <u /> }} />
                    </p>
                </div>
            </div>
        </div>
    );
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "flex", background: "#17508A", right: "20px", zIndex: "1000", borderRadius: "50%", width: "50px", height: "50px", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={onClick}
        />
    );
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "flex", background: "#17508A", left: "-60px", zIndex: "1000", borderRadius: "50%", width: "50px", height: "50px", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
            onClick={onClick}
        />
    );
}

const ProjectSlider = ({ projects }) => {
    const hasMultipleProjects = projects.length > 1;

    const settings = {
        dots: false,
        infinite: hasMultipleProjects,
        speed: 5000,
        autoplaySpeed: 10000,
        slidesToShow: 2,
        dots: true,
        slidesToScroll: hasMultipleProjects ? 2 : 1,
        autoplay: hasMultipleProjects,
        arrows: hasMultipleProjects,
        variableWidth: hasMultipleProjects,
        nextArrow: hasMultipleProjects ? <SampleNextArrow /> : null,
        prevArrow: hasMultipleProjects ? <SamplePrevArrow /> : null,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: hasMultipleProjects ? 2 : 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: hasMultipleProjects ? 1 : 1,
                    variableWidth: hasMultipleProjects,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: hasMultipleProjects ? 1.5 : 1,
                    variableWidth: hasMultipleProjects,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: hasMultipleProjects ? 1.2 : 1,
                    variableWidth: hasMultipleProjects,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: hasMultipleProjects ? 1 : 1,
                    variableWidth: hasMultipleProjects,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: hasMultipleProjects ? 1 : 1,
                    variableWidth: hasMultipleProjects,
                }
            },
            {
                breakpoint: 280,
                settings: {
                    slidesToShow: hasMultipleProjects ? 1 : 1,
                    variableWidth: hasMultipleProjects,
                }
            },
        ]
    };

    return (
        <Slider {...settings} className="detailPageSlider">
            {projects.map((project, index) => (
                <div key={project.id} className="pr-2 sm:pr-4"
                    // style={{ width: '450px'}}
                >
                    <div className="bg-white rounded-lg  shadow-lg h-[450px] sm:h-[470px] md:h-[450px] flex flex-col">
                        <div className="p-3 sm:p-4 flex-1 flex flex-col">
                            {project.title && (
                                <p className="text-[#0B86C8] font-semibold font-dmsans text-sm sm:text-base underline">
                                    {project.title} <br /> Secteur : {project.client}
                                </p>
                            )}
                            <p className="text-gray-600 font-dmsans text-xs sm:text-sm mt-2">
                                Contexte : <strong>{project.context}</strong>
                            </p>
                            <p className="text-gray-600 font-dmsans text-xs sm:text-sm mt-2">
                                Environnement technique : <strong>{project.technicalEnvironment}</strong>
                            </p>
                            <p className="text-gray-600 font-dmsans text-xs sm:text mt-2">
                                Mission :
                                <ul className="list-disc list-inside">
                                    {project.details.map((detail, index) => (
                                        <li key={index} className="font-dmsans text-xs">{detail.name}</li>
                                    ))}
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

const ServiceDetailPage = () => {
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [service, setService] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openAccordions, setOpenAccordions] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const loadInitialService = async () => {
            try {
                const data = await fetchOne(slug, i18n.language);
                if (!data) {
                    setError('Service non trouvé');
                    return;
                }
                setServiceId(data.id);
                setService(data);
            } catch (err) {
                try {
                    const otherLang = i18n.language === 'fr' ? 'en' : 'fr';
                    const data = await fetchOne(slug, otherLang);
                    if (data) {
                        await i18n.changeLanguage(otherLang);
                        setServiceId(data.id);
                        setService(data);
                        return;
                    }
                } catch (error) {
                    console.error("Erreur lors de la tentative avec l'autre langue:", error);
                }

                console.error("Erreur lors du chargement initial:", err);
                setError('Erreur lors du chargement du service');
            } finally {
                setLoading(false);
            }
        };

        loadInitialService();
    }, [slug]);

    useEffect(() => {
        const updateServiceTranslation = async () => {
            if (!serviceId) return;

            try {
                setLoading(true);
                const serviceData = await fetchOne(serviceId, i18n.language);
                if (serviceData) {
                    setService(serviceData);
                    const newSlug = generateSlug(serviceData.name);
                    if (newSlug !== slug) {
                        navigate(`/services/${newSlug}`, {
                            replace: true,
                            state: { language: i18n.language }
                        });
                    }
                }
            } catch (err) {
                console.error("Erreur lors de la mise à jour de la traduction:", err);
                setError('Erreur lors du chargement du service');
            } finally {
                setLoading(false);
            }
        };

        updateServiceTranslation();
    }, [i18n.language, serviceId]);

    useEffect(() => {
        if (service?.details) {
            setOpenAccordions(Array.from({ length: service.details.length }, (_, i) => i));
        }
    }, [service]);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-[50vh]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    if (error || !service) {
        return (
            <div className="flex justify-center items-center min-h-[50vh]">
                <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg">
                    <p className="font-semibold">{error || 'Service non trouvé'}</p>
                    <button
                        onClick={() => window.location.reload()}
                        className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                    >
                        Réessayer
                    </button>
                </div>
            </div>
        );
    }

    const toggleAccordion = (index) => {
        if (openAccordions.includes(index)) {
            setOpenAccordions(openAccordions.filter(i => i !== index));
        } else {
            setOpenAccordions([...openAccordions, index]);
        }
    };

    return (
        <div className="pb-4 sm:pb-8 md:pb-12">
            <div>
                <div className="bg-cover bg-center h-[40vh] sm:h-[50vh] md:h-[60vh] w-full object-cover relative"
                    style={{ backgroundImage: `url(${API_BASE_URL}${service.detailedImageUrl})` }}>
                    <div className="absolute inset-0 bg-[#17508A] opacity-80 z-10"></div>
                    <div className="absolute inset-0 flex flex-col items-start justify-center container mx-auto px-4 sm:px-6 lg:px-8 z-20">
                        <div className="w-full md:w-[65%] lg:w-[55%]">
                            <h2 className="text-white z-30 text-3xl sm:text-5xl md:text-7xl font-bold dm-sans mt-3 sm:mt-5 mb-4 sm:mb-7 capitalize font-dmsans">
                                {service.name}
                            </h2>
                            <p className="text-white z-30 font-dmsans text-base sm:text-lg line-clamp-3">
                                <Trans i18nKey={service.detailedDescription} components={{ strong: <strong />, em: <em /> }} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Link to="/services"
                    className="inline-flex items-center text-[#0B86C8] hover:text-[#17508A] mb-4 sm:mb-6 transition-colors mt-6 sm:mt-10 font-dmsans">
                    <span className="mr-2">←</span>
                    {t('buttons.return_services')}
                </Link>
            </div>

            <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row gap-6 sm:gap-10">
                <div className="w-full md:w-1/2">
                    <div className="w-full md:w-full xl:w-4/5 2xl:w-full">
                        <Heading
                            subtitle={service.name}
                            title={service.detailedTitle}
                            classParent="left"
                            classChild="left"
                        />
                    </div>
                    <p className="text-base sm:text-lg font-dmsans mb-6 sm:mb-8 text-gray-700">
                        <Trans i18nKey={service.whyAlcyone} components={{ strong: <strong />, em: <em /> }} />
                    </p>

                    {service.details && service.details.length > 0 && (
                        <div className="mt-6 sm:mt-8">
                            <div className="rounded-xl">
                                {service.details.sort((a, b) => a.id - b.id).map((detail, index) => (
                                    console.log(detail),
                                    <AccordionItem
                                        key={index}
                                        detail={detail}
                                        index={index}
                                        isOpen={openAccordions.includes(index)}
                                        onClick={() => toggleAccordion(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-full md:w-2/5 mx-auto flex justify-center items-center mt-6 md:mt-0">
                    <img
                        src="/Pic-10.jpg"
                        alt="Test"
                        className="w-full h-auto rounded-lg"
                    />
                </div>
            </div>

            {service.projects && service.projects.length > 0 && (
                <div className="bg-[#0B86C8] mt-8 sm:mt-12 md:mt-20 py-6 sm:py-10 md:py-16 overflow-hidden">
                    <div className="container mx-auto">
                        <div className="flex flex-col md:flex-row gap-6 md:gap-0">
                            <div className="w-full md:w-1/2 px-4 sm:px-6 lg:px-8">
                                <div className="text-white max-w-xl">
                                    <span className="text-sm sm:text-base md:text-lg font-semibold mb-1 sm:mb-2 block font-dmsans">
                                        {t('services.ref_client')}
                                    </span>
                                    <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-3 sm:mb-4 md:mb-6 font-dmsans">
                                        {t('services.success_title')} {service.name}
                                    </h2>
                                    <p className="text-white/90 text-sm sm:text-base md:text-lg leading-relaxed line-clamp-3 md:line-clamp-none font-worksans">
                                        {t('services.description1')} {service.name} {t('services.description2')}
                                    </p>
                                </div>
                            </div>

                            <div className="w-full md:w-3/5 -mr-4 sm:-mr-6 md:mr-0">
                                <div className="overflow-visible mt-4 md:mt-0">
                                    {service.projects && service.projects.length > 0 && (
                                        <div className="ml-4 md:ml-6">
                                            <ProjectSlider projects={service.projects} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceDetailPage; 