import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_BASE_URL } from "../../config/apiConfig";

const InputField = ({ type = 'text', placeholder, className = '', value, onChange }) => (
  <div className="mt-4">
    <input
      type={type}
      placeholder={placeholder}
      className={`w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#0B86C8] ${className}`}
      value={value}
      onChange={onChange}
      required
    />
  </div>
);

const ContactForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [emailTo, setEmailTo] = useState('contact@alcyone-consulting.lu');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const content = `
      Email: ${email}
      Sujet: ${subject}
      Message: ${message}
    `;

    try {
      const response = await fetch(`${API_BASE_URL}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: emailTo,
          subject: `Message de ${firstname} ${lastname.toUpperCase()}`,
          content: content
        }),
      });

    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email", error);
    }
  };

  return (
    <div className="lg:w-3/6 px-6 lg:px-12 py-12">
      <div className="mb-8">
        <h2 className="text-3xl lg:text-5xl font-bold mb-4 font-dmsans">
          {t('contact.title1')} <span className="text-[#0B86C8]">{t('contact.title2')}</span>
        </h2>
        <p className="text-gray-500 text-base mb-6 font-worksans">
          {t('contact.description')}
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <InputField placeholder={t('contact.name')} value={lastname} onChange={(e) => setLastname(e.target.value)} />
        <InputField placeholder={t('contact.firstname')} value={firstname} onChange={(e) => setFirstname(e.target.value)} />
        <InputField type="email" placeholder={t('contact.email')} value={email} onChange={(e) => setEmail(e.target.value)} />

        <div className="mt-4">
          <select className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#0B86C8]" required value={subject} onChange={(e) => setSubject(e.target.value)}>
            <option value="">{t('contact.select')}</option>
            <option value="general">{t('contact.value1')}</option>
            <option value="support">{t('contact.value2')}</option>
            <option value="feedback">{t('contact.value3')}</option>
          </select>
        </div>

        <div className="mt-4">
          <textarea
            placeholder={t('contact.message')}
            rows="5"
            className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#0B86C8]"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-full bg-[#0B86C8] text-white py-3 rounded-md mt-6 hover:bg-[#289EDC] transition duration-300 uppercase font-worksans font-bold text-base"
        >
          {t('contact.submit')}
        </button>
      </form>
    </div>
  );
};

export default ContactForm; 