import { http } from "../api";

export const fetchAll = () => http("/projects");
export const fetchOne = (id) => http(`/projects/service/${id}`);

export const fetchTranslations = (projectId, language) => http(`/projects/${projectId}/translations/${language}`);

export const updateTranslation = async (projectId, language, field, translation) => {
  const data = {
    language,
    field,
    translation
  };
  return await http(`/projects/${projectId}/translations`, {
    method: "PUT",
    body: JSON.stringify(data)
  });
};

export const create = (data) => http("/projects", {
    method: "POST",
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
});

export const update = (id, data) => http(`/projects/${id}`, {
    method: "PUT",
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
});

export const remove = (id) => http(`/projects/${id}`, {
    method: "DELETE",
});

export const uploadImage = async (projectId, file) => {
    
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await http(`/projects/${projectId}/uploadImage`, {
            method: "POST",
            body: formData
        });
        return response;
    } catch (error) {
        console.error('Upload error:', error);
        throw error;
    }
};
