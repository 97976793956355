import React from "react";

const CategoryTag = ({ categoryName }) => {
    if (!categoryName) return null;
    
    return (
        <span
            className="inline-block max-w-max px-3 py-1 text-xs sm:text-sm font-medium text-white rounded-md mb-4 font-worksans bg-[#0B86C8]"
        >
            {categoryName}
        </span>
    );
};

export default CategoryTag; 