import { useState, useEffect } from 'react';
import { fetchAll, fetchTranslations } from '../services/specificServices/labels';
import { useTranslation } from 'react-i18next';

export const useLabels = () => {
  const [translatedItems, setTranslatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadLabels = async () => {
      try {
        setLoading(true);
        const labels = await fetchAll();
        
        const translatedLabels = await Promise.all(
          labels.map(async (label) => {
            try {
              const translation = await fetchTranslations(label.id, i18n.language);
              return {
                ...label,
                title: translation?.title || label.title,
                description: translation?.description || label.description,
              };
            } catch (error) {
              return label;
            }
          })
        );

        setTranslatedItems(translatedLabels);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadLabels();
  }, [i18n.language]);

  return { items: translatedItems, loading, error };
}; 