import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { useSettings } from "../../hooks/useSettings";

const ContactInfo = () => {
    const { settings, loading, error } = useSettings();
    
    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-red-500 text-center py-8">
                {error}
            </div>
        );
    }

    return (
        <div className="text-xs text-center md:text-base lg:text-sm xl:text-base font-dmsans font-light text-[#757474] flex flex-col lg:flex-row">
            <div className="flex flex-col justify-center md:flex-row items-center md:space-x-6 space-x-4 space-y-0 md:space-y-0">
                <div className="flex items-center space-x-2">
                    <span className="text-[#0B86C8]"><FaPhoneAlt /></span>
                    <a href={`tel:${settings.phone}`} className="hover:text-[#0B86C8] transition-colors">{settings.phone}</a>
                </div>
                <div className="flex items-center space-x-2">
                    <span className="text-[#0B86C8]"><MdEmail /></span>
                    <a href={`mailto:${settings.email}`} className="hover:text-[#0B86C8] transition-colors">{settings.email}</a>
                </div>
            </div>
            <div className="flex flex-col md:flex-row items-center space-x-1 md:space-x-6">
                <div className="flex items-center ml-2 space-x-2">
                    <span className="text-[#0B86C8]"><FaLocationDot /></span>
                    <a href={`https://maps.google.com/?q=${settings.address}`} className="hover:text-[#0B86C8] transition-colors" target="_blank" rel="noopener noreferrer">{settings.address}</a>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
