import React from 'react';
import { HiUserGroup } from 'react-icons/hi';
import Heading from '../common/Heading';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { FiCpu } from 'react-icons/fi';
import { MdOutlineInsights } from 'react-icons/md';
import { Trans, useTranslation } from 'react-i18next';

const StorytellingSection = () => {
    const { t } = useTranslation();

    const valeurs = [
        {
            id: 1,
            title: t('values.valeur1-title'),
            name: t('values.valeur1-title2'),
            desc: t('values.valeur1-description'),
            iconSrc: <MdOutlineInsights className="w-12 h-12 text-[#0B86C8]" />
        },
        {
            id: 2,
            title: t('values.valeur2-title'),
            name: t('values.valeur2-title2'),
            desc: t('values.valeur2-description'),
            iconSrc: <FiCpu className="w-12 h-12 text-[#0B86C8]" />
        },
        {
            id: 3,
            title: t('values.valeur3-title'),
            name: t('values.valeur3-title2'),
            desc: t('values.valeur3-description'),
            iconSrc: <AiOutlineFieldTime className="w-12 h-12 text-[#0B86C8]" />
        }
    ];

    return (
        <section className="py-8 sm:py-12 lg:py-16 xl:py-20 2xl:py-24 px-4 sm:px-6 lg:px-8 xl:px-12 mt-12">
            <div className="max-w-[2000px] mx-auto">
                <div className="max-w-7xl xl:max-w-[1400px] 2xl:max-w-[1800px] mx-auto grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-12 xl:gap-16 items-start">
                    <div className="lg:col-span-5 xl:col-span-4 2xl:col-span-5 lg:sticky lg:top-8">
                        <div className="relative w-full max-w-[500px] 2xl:max-w-[520px] mx-auto lg:mx-0 aspect-[4/5]">
                            <div className="absolute inset-0 bg-[#0B86C8] opacity-20 -rotate-6 transform-gpu"></div>
                            <img
                                src="/cropped-photosalle.jpg"
                                alt="Decorative"
                                className="relative z-10 w-full h-full object-cover shadow-lg object-[35%]"
                            />
                        </div>
                    </div>

                    <div className="lg:col-span-7 xl:col-span-8 2xl:col-span-7 space-y-6 sm:space-y-8 lg:space-y-10">
                        <div className="space-y-4 sm:space-y-6">
                            <Heading 
                                subtitle={t('values.subtitle')} 
                                title={t('values.title')} 
                                classParent="left" 
                                classChild="start"
                            />
                        </div>

                        <ul role="list" className="space-y-4 sm:space-y-5 lg:space-y-6">
                            {valeurs.map((valeur) => (
                                <li 
                                    key={valeur.id} 
                                    className="flex flex-col sm:flex-row items-start p-4 sm:p-6 lg:p-8 shadow-lg rounded-lg gap-4 sm:gap-6 bg-white transition-transform hover:scale-[1.01]"
                                >
                                    <div className="shrink-0 bg-[#0B86C8]/10 p-3 rounded-lg">
                                        <div className="w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12">
                                            {valeur.iconSrc}
                                        </div>
                                    </div>
                                    <div className="flex-1 space-y-2">
                                        <h3 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-700 font-dmsans">
                                            <span className="text-[#0B86C8]">{valeur.title}</span> {valeur.name}
                                        </h3>
                                        <p className="text-sm sm:text-base lg:text-lg text-gray-700 font-worksans font-normal leading-relaxed text-justify">
                                            <Trans i18nKey={valeur.desc} components={{ strong: <strong />, em: <em /> }} />
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StorytellingSection; 