import React, { useEffect, useState } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import { create, update, uploadImage, fetchOne, updateTranslation, fetchTranslations } from '../../services/specificServices/labels';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const LabelForm = ({ item = null, onSubmit, onCancel }) => {
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(
    item?.imageUrl ? `${API_BASE_URL}${item.imageUrl}` : ''
  );
  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const [allTranslations, setAllTranslations] = useState({
    fr: { title: '', description: '' },
    en: { title: '', description: '' }
  });
  const availableLanguages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' }
  ];

  const fields = [
  ];


  useEffect(() => {
    if (item?.id) {
      loadTranslations(item.id, selectedLanguage);
    }
  }, [item?.id, selectedLanguage]);

  const loadTranslations = async (labelId, language) => {
    try {
      const data = await fetchTranslations(labelId, language);
      setAllTranslations(prev => ({
        ...prev,
        [language]: {
          title: data?.title || '',
          description: data?.description || ''
        }
      }));
    } catch (error) {
      console.error('Erreur lors du chargement des traductions:', error);
      setAllTranslations(prev => ({
        ...prev,
        [language]: { title: '', description: '' }
      }));
    }
  };

  const handleLanguageChange = async (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
    if (item?.id) {
      await loadTranslations(item.id, newLanguage);
    }
  };

  const handleSubmit = async (data) => {
    try {
      let savedLabel;

      const clientData = {
        ...data,
        title: allTranslations.fr.title,
        description: allTranslations.fr.description
      };


      if (item?.id && !file) {
        clientData.imageUrl = item.imageUrl;
      }

      if (item?.id) {
        savedLabel = await update(item.id, clientData);
        toast.success('Label mis à jour avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedLabel);
            }
          }
        });
      } else {
        savedLabel = await create(clientData);
        toast.success('Label créé avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedLabel);
            }
          }
        });
      }

      if (file) {
        await uploadImage(savedLabel.id, file);
        savedLabel = await fetchOne(savedLabel.id);
      }

      for (const lang of Object.keys(allTranslations)) {
        const translation = allTranslations[lang];
        if (translation.title || translation.description) {
          await updateTranslation(savedLabel.id, lang, 'title', translation.title);
          await updateTranslation(savedLabel.id, lang, 'description', translation.description);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
    }
  };

  const handleTranslationChange = async (field, value) => {
    setAllTranslations(prev => ({
      ...prev,
      [selectedLanguage]: {
        ...prev[selectedLanguage],
        [field]: value
      }
    }));
  };

  const handleTranslationBlur = async (field, value) => {
    if (item?.id) {
      try {
        await updateTranslation(item.id, selectedLanguage, field, allTranslations[selectedLanguage][field]);
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la traduction:', error);
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setPreviewImage(URL.createObjectURL(selectedFile));
    }
  };

  return (
    <div className='relative'>
      <Form
        onSubmit={handleSubmit}
        onCancel={onCancel}
        fields={fields}
        initialValues={item}
        title={item ? "Modifier le label" : "Nouveau label"}
        submitLabel={item ? "Mettre à jour" : "Créer"}
      >
        <div className="mt-8 border-t pt-6">
          <div className="mb-4">
            <label className="block font-semibold mb-1">Langue</label>
            <select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              {availableLanguages.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.label}
                </option>
              ))}
            </select>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block font-semibold mb-1">Nom</label>
              <input
                type="text"
                value={allTranslations[selectedLanguage].title}
                onChange={(e) => handleTranslationChange('title', e.target.value)}
                onBlur={(e) => handleTranslationBlur('title', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                placeholder={`Titre`}
              />
            </div>

            <div>
              <label className="block font-semibold mb-1">Description</label>
              <textarea
                value={allTranslations[selectedLanguage].description}
                onChange={(e) => handleTranslationChange('description', e.target.value)}
                onBlur={(e) => handleTranslationBlur('description', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                rows="3"
                placeholder={`Description du label`}
              />
            </div>

            {previewImage && (
              <div>
                <label className="block font-semibold mb-1">Logo actuel</label>
                <img
                  src={previewImage}
                  alt="Aperçu"
                  className="w-48 h-48 object-contain rounded border"
                />
              </div>
            )}

            <div>
              <label className="block font-semibold mb-1">Logo</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="w-full p-2 border border-gray-300 rounded"
                required={!item}
              />
            </div>
          </div>
        </div>
      </Form>
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        limit={3}
        toastStyle={toastStyle}
      />
    </div>
  );
};

export default LabelForm; 