import React, { useState } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import { create, update, uploadLogo } from '../../services/specificServices/logoPeriods';

const LogoPeriodForm = ({ item = null, onSubmit, onCancel }) => {
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(
        item?.logoUrl ? `${API_BASE_URL}${item.logoUrl}` : ''
    );

    const fields = [
        {
            name: 'name',
            label: 'Nom',
            type: 'text',
            placeholder: 'Nom de la période',
            validation: { required: 'Le nom est requis' }
        },
        {
            name: 'startDay',
            label: 'Jour de début',
            type: 'number',
            placeholder: '01',
            validation: { required: 'Le jour de début est requis', min: 1, max: 31 }
        },
        {
            name: 'startMonth',
            label: 'Mois de début',
            type: 'number',
            placeholder: '01',
            validation: { required: 'Le mois de début est requis', min: 1, max: 12 }
        },
        {
            name: 'endDay',
            label: 'Jour de fin',
            type: 'number',
            placeholder: '31',
            validation: { required: 'Le jour de fin est requis', min: 1, max: 31 }
        },
        {
            name: 'endMonth',
            label: 'Mois de fin',
            type: 'number',
            placeholder: '12',
            validation: { required: 'Le mois de fin est requis', min: 1, max: 12 }
        }
    ];

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreviewImage(URL.createObjectURL(selectedFile));
        }
    };

    const handleSubmit = async (formData) => {
        try {
            const saveData = {
                ...formData,
                startDay: parseInt(formData.startDay),
                startMonth: parseInt(formData.startMonth),
                endDay: parseInt(formData.endDay),
                endMonth: parseInt(formData.endMonth)
            };

            if (!saveData.name || !saveData.startDay || !saveData.startMonth || !saveData.endDay || !saveData.endMonth) {
                throw new Error('Veuillez remplir tous les champs requis');
            }

            if (!item && !file) {
                throw new Error('Veuillez sélectionner un logo');
            }

            if (item) {
                await update(item.id, saveData);
                if (file) {
                    await uploadLogo(item.id, file);
                }
            } else {
                await create(saveData, file);
            }
        } finally {
            if (typeof onSubmit === 'function') {
                onSubmit();
            }
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            onCancel={onCancel}
            fields={fields}
            initialValues={item}
            title={item ? "Modifier la période" : "Nouvelle période"}
            submitLabel={item ? "Mettre à jour" : "Créer"}
        >
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    Logo
                </label>
                {previewImage && (
                    <img
                        src={previewImage}
                        alt="Preview"
                        className="w-32 h-32 object-contain mb-2 border rounded"
                    />
                )}
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    required={!item}
                />
            </div>
        </Form>
    );
};

export default LogoPeriodForm; 