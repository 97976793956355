import { useState, useEffect } from 'react';
import { fetchAll, fetchOne, create, update, remove, fetchTranslations } from '../services/specificServices/categories';
import i18next from 'i18next';

export const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesInit, setCategoriesInit] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadCategories = async () => {
    try {
      setLoading(true);
      const data = await fetchAll();
      setCategoriesInit(data);

      const translatedCategories = await Promise.all(
        data.map(async (category) => {
          const translation = await fetchTranslations(category.id, i18next.language);
          return {
            ...category,
            name: translation?.name || category.name,
          };
        })
      );
      setCategories(translatedCategories);
    } catch (err) {
      setError(err);
      console.error('Erreur lors du chargement des catégories:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCategories();
  }, [i18next.language]);

  return {
    categories,
    categoriesInit,
    loading,
    error,
    reload: loadCategories
  };
}; 