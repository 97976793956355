import { http } from "../api";

export const fetchAll = () => http("/offices");
export const fetchOne = (id) => http(`/offices/${id}`);
export const create = (data) => http("/offices", { 
  method: "POST", 
  body: JSON.stringify(data) 
});
export const update = (id, data) => http(`/offices/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});
export const remove = (id) => http(`/offices/${id}`, { 
  method: "DELETE" 
});

export const uploadImage = async (id, file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    
    const response = await http(`/offices/${id}/uploadImage`, {
      method: "POST",
      body: formData,
    });

    return response;
  } catch (error) {
    console.error('Erreur lors de l\'upload:', error);
    throw error;
  }
}; 