import React from "react";
import { Link } from "react-router-dom";
import { useLogo } from "../../hooks/useLogo";

const Logo = () => {
    const { logo, loading, isPeriodLogo } = useLogo();

    const logoClasses = isPeriodLogo
        ? "h-16 sm:h-20 object-contain ml-2 lg:py-3 lg:h-[155px] xl:h-[165px]"
        : "h-16 sm:h-20 object-contain ml-2 lg:h-[75px] xl:h-[85px]";

    return (
        <div className="container flex items-end justify-center bg-white h-full px-4 md:px-5 lg:w-[50%] xl:w-[45%] 2xl:w-[30%] polygon-white-bg">

            <Link to="/" className="flex-shrink-0">
                <img
                    className={logoClasses}
                    src={logo}
                    alt="Logo Alcyone"
                />
            </Link>
        </div>
    );
};

export default Logo;