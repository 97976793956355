import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import SocialLinks from "./SocialLinks";
import Navigation from "./Navigation";
import ContactInfo from "./ContactInfo";
import LanguageSelector from "./LanguageSelector";
import MobileMenu from "./MobileMenu";

const Header = () => {
    const [lastScrollY, setLastScrollY] = useState(0);
    const [showTopBar, setShowTopBar] = useState(true);
    const [scrollState, setScrollState] = useState('top');

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        
        if (currentScrollY === 0) {
            setScrollState('top');
            setShowTopBar(true);
        } else {
            setShowTopBar(false);
            if (currentScrollY < lastScrollY) {
                setScrollState('scrollingUp');
            } else {
                setScrollState('scrollingDown');
            }
        }

        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return (
        <header className="bg-white border-b border-gray-300">
            <div 
                className={`
                    relative justify-around items-center px-4 sm:px-6 py-1 flex-col 
                    lg:flex-row transition-all duration-500 ease-in-out z-[99]
                    hidden lg:flex
                    ${showTopBar ? "opacity-100 h-auto visible" : "opacity-0 h-0 invisible"}
                `}
            >
                <ContactInfo />
                <SocialLinks />
            </div>
                    
            <div 
                className={`
                    w-full text-gray-700 bg-custom-navbar z-50
                    transition-all duration-500 ease-in-out
                    ${scrollState === 'top' 
                        ? 'relative opacity-100 visible translate-y-0'
                        : scrollState === 'scrollingUp'
                            ? 'fixed top-0 left-0 right-0 opacity-100 visible translate-y-0 shadow-md z-50'
                            : 'fixed -top-20 left-0 right-0 opacity-0 invisible translate-y-0 z-50'
                    }
                `}
            >
                <div className="w-full 2xl:container 2xl:mx-auto sm:px-4 md:px-4 2xl:px-8 lg:px-4">
                    <div className="flex items-center justify-between h-16 sm:h-20 bg-aco-Grey">
                        <Logo />
                        <div className="hidden lg:flex lg:w-full lg:justify-center justify-start items-center divide-x divide-gray-200 w-[70%] 2xl:w-[70%]">
                            <Navigation />
                            {/* <div className="pl-2 xl:pl-6">
                                <LanguageSelector />
                            </div> */}
                        </div>
                        
                        <div className="lg:hidden flex items-center">
                            <MobileMenu />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
