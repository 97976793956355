import { http } from "../api";

export const fetchAll = () => http("/mentions");

export const fetchTranslations = (mentionId, language) => http(`/mentions/${mentionId}/translations/${language}`);

export const fetchOne = (id) => http(`/mentions/${id}`);

export const create = (data) => http("/mentions", { 
  method: "POST", 
  body: JSON.stringify(data) 
});

export const remove = (id) => http(`/mentions/${id}`, { 
  method: "DELETE" 
});

export const update = (id, data) => http(`/mentions/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});

export const updateTranslation = async (id, language, field, translation) => {
  return http(`/mentions/${id}/translations`, {
    method: "PUT",
    body: JSON.stringify({
      language,
      field,
      translation
    })
  });
}; 