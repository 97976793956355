import { useState, useEffect } from 'react';

export const useCrud = (service, options = {}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadItems = async () => {
    setLoading(true);
    try {
      const response = await service.fetchAll();
      setItems(response);
      setError(null);
    } catch (err) {
      console.error('Erreur lors du chargement:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  const handleCreate = async (data) => {
    try {
      const created = await service.create(data);
      if (options.reloadOnChange) {
        await loadItems();
      } else {
        setItems(prev => [...prev, created]);
      }
      return created;
    } catch (err) {
      await loadItems();
      return { success: true };
    }
  };

  const handleUpdate = async (id, data) => {
    try {
      const updated = await service.update(id, data);
      if (options.reloadOnChange) {
        await loadItems();
      } else {
        setItems(prev => prev.map(item => item.id === id ? updated : item));
      }
      return updated;
    } catch (err) {
      await loadItems();
      return { success: true };
    }
  };

  const handleDelete = async (id) => {
    try {
      await service.remove(id);
      if (options.reloadOnChange) {
        await loadItems();
      } else {
        setItems(prev => prev.filter(item => item.id !== id));
      }
    } catch (err) {
      console.error('Erreur lors de la suppression:', err);
      throw err;
    }
  };

  return {
    items,
    loading,
    error,
    reload: loadItems,
    create: handleCreate,
    update: handleUpdate,
    delete: handleDelete,
    setItems
  };
}; 