import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import LanguageSelector from './LanguageSelector';

const SocialLinks = () => {
    return (
        <div className="flex-row gap-2 hidden lg:flex justify-center items-center">
            <a
                href="https://www.linkedin.com/company/lusis-luxembourg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#0B86C8] hover:text-[#289EDC] border-[#0B86C8] rounded-full"
                aria-label="LinkedIn"
            >
                <FaLinkedin className="w-8 h-8 border p-1.5 rounded-full" />
            </a>
            <LanguageSelector />
        </div>
    );
};

export default SocialLinks;
