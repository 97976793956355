import React, { useState } from 'react';
import { API_BASE_URL } from '../../config/apiConfig';
import Table from '../common/Table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const ClientTable = ({ items, onEdit, onDelete }) => {
  const [selectedClients, setSelectedClients] = useState([]);

  const columns = [
    {
      key: 'select',
      cellClassName: 'w-10',
      title: (
        <input
          type="checkbox"
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedClients(items.map(item => item.id));
            } else {
              setSelectedClients([]);
            }
          }}
          checked={selectedClients.length === items.length && items.length > 0}
        />
      ),
      render: (client) => (
        <input
          type="checkbox"
          className="w-4 h-4"
          checked={selectedClients.includes(client.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedClients([...selectedClients, client.id]);
            } else {
              setSelectedClients(selectedClients.filter(id => id !== client.id));
            }
          }}
        />
      ),
    },
    {
      key: 'imageUrl',
      title: 'Logo',
      render: (client) => (
        client.imageUrl ? (
          <img
            src={client.imageUrl.startsWith('http')
              ? client.imageUrl
              : `${API_BASE_URL}${client.imageUrl}`
            }
            alt={client.name}
            className="w-16 h-16 object-contain"
          />
        ) : (
          'Aucun logo'
        )
      ),
    }
  ];

  const handleDelete = (client) => {
    const confirmMessage = `Êtes-vous sûr de vouloir supprimer le client "${client.title}" ?`;

    if (window.confirm(confirmMessage)) {
      try {
        onDelete(client.id);
        toast.success('Client supprimé avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style
        });
      } catch (error) {
        toast.error('Erreur lors de la suppression du client', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.error.style
        });
      }
    }
  };

  const handleDeleteSelected = () => {
    if (selectedClients.length === 0) return;

    const confirmMessage = `Êtes-vous sûr de vouloir supprimer les ${selectedClients.length} clients sélectionnés ?`;

    if (window.confirm(confirmMessage)) {
      selectedClients.forEach(clientId => {
        try {
          onDelete(clientId);
        } catch (error) {
          toast.error('Erreur lors de la suppression des clients', {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            style: toastStyle.error.style
          });
        }
      });
      if (selectedClients.length === 1) {
        toast.success('Client supprimé avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style
        });
      } else if (selectedClients.length > 1) {
        toast.success('Clients supprimés avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style
        });
      }
      setSelectedClients([]);
    }
  };

  return (
    <div>
      {selectedClients.length > 0 && (
        <div className="mb-4">
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={handleDeleteSelected}
          >
            Supprimer les {selectedClients.length} clients sélectionnés
          </button>
        </div>
      )}
      <Table
        columns={columns}
        data={items}
        actions={(item) => (
          <>
            <button
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={() => handleDelete(item)}
            >
              Supprimer
            </button>
          </>
        )}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ClientTable; 