import React from 'react';
import { API_BASE_URL } from '../../config/apiConfig';

const ClientCarousel = ({ clients }) => {
    if (!clients?.length) return null;

    return (
        <div className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
            <ul className="flex items-center justify-center md:justify-start flex-shrink-0 gap-8 py-4 animate-infinite-scroll">
                {clients.map((client) => (
                    <li key={client.id} className="w-[200px] max-w-full flex items-center justify-center">
                        <img
                            src={client.imageUrl?.startsWith('http')
                                ? client.imageUrl
                                : `${API_BASE_URL}${client.imageUrl}`
                            }
                            alt={client.title}
                            className="h-24 w-auto object-contain"
                            onError={(e) => {
                                e.target.src = '/placeholder-logo.png';
                            }}
                        />
                    </li>
                ))}
            </ul>

            <ul 
                className="flex items-center justify-center md:justify-start flex-shrink-0 gap-8 py-4 animate-infinite-scroll"
                aria-hidden="true"
            >
                {clients.map((client) => (
                    <li key={`duplicate-${client.id}`} className="w-[200px] max-w-full flex items-center justify-center">
                        <img
                            src={client.imageUrl?.startsWith('http')
                                ? client.imageUrl
                                : `${API_BASE_URL}${client.imageUrl}`
                            }
                            alt={client.title}
                            className="h-24 w-auto object-contain"
                            onError={(e) => {
                                e.target.src = '/placeholder-logo.png';
                            }}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClientCarousel; 