import React from 'react';
import Heading from '../../components/common/Heading';
import OfficeList from '../../components/offices/OfficeList';
import ContactInfo from '../../components/contact/ContactInfo';
import ContactForm from '../../components/contact/ContactForm';
import ContactMap from '../../components/contact/ContactMap';
import { useSettings } from '../../hooks/useSettings';

const Contact = () => {
    const { settings, loading, error } = useSettings();

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-red-500 text-center py-8">
                {error}
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="relative shadow-custom rounded-[50px] overflow-hidden container mx-auto px-4 sm:px-8 lg:px-12 py-12 mb-20 xl:bg-custom-gradient-lg 2xl:bg-custom-gradient-xl border border-gray-300">
                <ContactInfo settings={settings} />

                <div className="relative z-10 flex flex-col lg:flex-row pb-24 gap-12">
                    <ContactForm />
                    <ContactMap mapUrl={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2585.7721579282973!2d6.119692812161191!3d49.581620548552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4795476b54f0fd35%3A0x22b7a92f1d9ef015!2s3%20Rue%20Luxite%2C%201899%20Kockelscheuer%2C%20Luxembourg!5e0!3m2!1sfr!2sfr!4v1675208392158!5m2!1sfr!2sfr`} />
                </div>
            </div>

            <OfficeList />
        </div>
    );
};

export default Contact;