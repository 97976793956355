import React from 'react';
import { useCrud } from '../hooks/useCrud';

const FormContainer = ({
  service,
  FormComponent,
  item = null,
  onSave,
  onCancel,
  additionalProps = {}
}) => {
  const { create, update } = useCrud(service);

  const handleSubmit = async (data) => {
    try {
      if (item?.id) {
        const updated = await update(item.id, data);
        onSave && onSave(updated);
      } else {
        const created = await create(data);
        onSave && onSave(created);
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <FormComponent
      item={item}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      {...additionalProps}
    />
  );
};

export default FormContainer; 