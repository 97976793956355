import React, { useEffect, useState } from 'react';
import HeroSection from '../../components/sections/HeroSection';
import ArticlesPage from './ArticlesPage';
import Heading from '../../components/common/Heading';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <div className="min-h-screen">
            <HeroSection />
            <div className="mx-auto max-w-6xl py-4 sm:py-8 lg:py-12 flex flex-col md:flex-row gap-y-16 lg:gap-y-0 lg:gap-x-32 items-center justify-center">
                <div className="text-center lg:text-left">
                    <Heading
                        subtitle={t('about.subtitle')}
                        title={t('about.title')}
                        classParent="center"
                        classChild="center"
                    />
                    <p className="mt-8 px-12 text-pretty text-base sm:text-lg font-medium text-gray-700 font-worksans text-center">
                        <Trans i18nKey="about.description" components={{ strong: <strong />, em: <em /> }} />
                    </p>
                    <div className="mt-4 flex items-center justify-center gap-x-6">
                        <Link
                            to="/a-propos"
                            className="rounded-md bg-[#0B86C8] px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-[#0B86C8]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {t('buttons.more')}
                        </Link>
                    </div>
                </div>
                <div className="text-center lg:text-left">
                    <Heading
                        subtitle={t('services.subtitle')}
                        title={t('services.title')}
                        classParent="center"
                        classChild="center"
                    />
                    <p className="mt-8 px-12 text-pretty text-base sm:text-lg font-medium text-gray-700 font-worksans text-center">
                        <Trans i18nKey="services.description" components={{ strong: <strong />, em: <em /> }} />
                    </p>
                    <div className="mt-4 flex items-center justify-center gap-x-6">
                        <Link
                            to="/services"
                            className="rounded-md bg-[#0B86C8] px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-[#0B86C8]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            {t('buttons.more')}
                        </Link>
                    </div>
                </div>
            </div>


            <div className="pb-16 bg-gray-50">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <ArticlesPage number={4} headerActive={false} HomePage={true} />
                </div>
            </div>
        </div >
    );
};

export default HomePage; 