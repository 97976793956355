import { useState, useEffect } from 'react';
import { fetchSettings } from '../services/specificServices/settings';
import { fetchCurrentPeriod } from '../services/specificServices/logoPeriods';
import { API_BASE_URL } from '../config/apiConfig';

export const useLogo = () => {
    const [logo, setLogo] = useState('');
    const [loading, setLoading] = useState(true);
    const [isPeriodLogo, setIsPeriodLogo] = useState(false);

    useEffect(() => {
        const getCurrentLogo = async () => {
            try {
                const [periods, settings] = await Promise.all([
                    fetchCurrentPeriod(),
                    fetchSettings()
                ]);

                const now = new Date();
                const currentDay = now.getDate();
                const currentMonth = now.getMonth() + 1;
                
                const currentPeriod = periods.find(period => {
                    const start = period.startMonth * 100 + period.startDay;
                    const end = period.endMonth * 100 + period.endDay;
                    const current = currentMonth * 100 + currentDay;

                    if (start <= end) {
                        return current >= start && current <= end;
                    } else {
                        return current >= start || current <= end;
                    }
                });

                if (currentPeriod?.logoUrl) {
                    setLogo(`${API_BASE_URL}${currentPeriod.logoUrl}`);
                    setIsPeriodLogo(true);
                } 
                else if (settings?.defaultLogoUrl) {
                    setLogo(`${API_BASE_URL}${settings.defaultLogoUrl}`);
                    setIsPeriodLogo(false);
                }
            } catch (error) {
                console.error('Erreur lors du chargement du logo:', error);
            } finally {
                setLoading(false);
            }
        };

        getCurrentLogo();
    }, []);

    return { logo, loading, isPeriodLogo };
}; 