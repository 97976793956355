import { http } from "../api";

export const fetchAll = () => http("/labels");

export const fetchTranslations = (labelId, language) => http(`/labels/${labelId}/translations/${language}`);

export const fetchOne = (id) => http(`/labels/${id}`);
export const create = (data) => http("/labels", { 
  method: "POST", 
  body: JSON.stringify(data) 
});
export const update = (id, data) => http(`/labels/${id}`, { 
  method: "PUT", 
  body: JSON.stringify(data) 
});
export const remove = (id) => http(`/labels/${id}`, { 
  method: "DELETE" 
});

export const uploadImage = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return http(`/labels/${id}/uploadImage`, {
    method: "POST",
    body: formData
  });
};

export const updateTranslation = (labelId, language, field, translation) => {
  return http(`/labels/${labelId}/translations`, {
    method: "PUT",
    body: JSON.stringify({
      language,
      field,
      translation
    })
  });
}; 