import React, { useState, useEffect } from 'react';
import { IoIosArrowUp } from 'react-icons/io';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <button
            onClick={scrollToTop}
            className={`
                fixed bottom-6 right-6 z-50
                w-12 h-12 rounded-full
                bg-[#0B86C8] hover:bg-[#289EDC]
                flex items-center justify-center
                shadow-lg transform transition-all duration-500 ease-in-out
                hover:scale-110 focus:outline-none
                ${isVisible 
                    ? 'opacity-100 translate-y-0 visible'
                    : 'opacity-0 translate-y-20 invisible pointer-events-none'
                }
            `}
            aria-label="Retour en haut de la page"
        >
            <IoIosArrowUp 
                className={`
                    w-6 h-6 text-white
                    transition-transform duration-300
                    group-hover:translate-y-[-2px]
                `}
            />
        </button>
    );
};

export default ScrollToTop; 