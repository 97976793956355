import React, { useState, useEffect } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import {
  create,
  update,
  uploadImage,
  fetchOne,
  fetchTranslations,
  updateTranslation,
  fetchById
} from '../../services/specificServices/articles';
import { useCategories } from '../../hooks/useCategories';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ArticleEditor from '../editors/ArticleEditor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const ArticleForm = ({ item = null, onSubmit, onCancel }) => {
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const [allTranslations, setAllTranslations] = useState({
    fr: { title: '', content: '<p><br></p>' },
    en: { title: '', content: '<p><br></p>' }
  });
  const { categoriesInit = [], loading } = useCategories();
  const [formData, setFormData] = useState({
    categoryId: item?.categoryId || ''
  });

  const availableLanguages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' }
  ];

  useEffect(() => {
    const initializeForm = async () => {
      if (item) {
        setPreviewImage(item?.imagePath ? `${API_BASE_URL}${item.imagePath}` : '');
        
        setAllTranslations(prev => ({
          ...prev,
          fr: {
            title: item.title || '',
            content: item.content || '<p><br></p>'
          }
        }));

        if (item.id) {
          for (const lang of availableLanguages) {
            try {
              const data = await fetchTranslations(item.id, lang.code);
              setAllTranslations(prev => ({
                ...prev,
                [lang.code]: {
                  title: data?.title || (lang.code === 'fr' ? item.title : ''),
                  content: data?.content || (lang.code === 'fr' ? item.content : '<p><br></p>')
                }
              }));
            } catch (error) {
              console.error(`Erreur lors du chargement des traductions pour ${lang.code}:`, error);
            }
          }
        }
      }
    };

    initializeForm();
  }, [item]);

  const loadTranslations = async (articleId, language) => {
    try {
      const data = await fetchTranslations(articleId, language);
      setAllTranslations(prev => ({
        ...prev,
        [language]: {
          title: data?.title || (language === 'fr' ? item?.title : ''),
          content: data?.content || (language === 'fr' ? item?.content : '<p><br></p>')
        }
      }));
    } catch (error) {
      console.error('Erreur lors du chargement des traductions:', error);
    }
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;

    setAllTranslations(prev => ({
      ...prev,
      [selectedLanguage]: {
        ...prev[selectedLanguage],
        title: prev[selectedLanguage].title === '' ?
          prev[selectedLanguage].title :
          prev[selectedLanguage].title,
        content: prev[selectedLanguage].content === '<p><br></p>' ?
          prev[selectedLanguage].content :
          prev[selectedLanguage].content
      }
    }));

    setSelectedLanguage(newLanguage);
  };

  const hasCategories = Array.isArray(categoriesInit) && categoriesInit.length > 0;

  const fields = [
    ...(hasCategories ? [{
      name: 'categoryId',
      label: 'Catégorie',
      type: 'select',
      placeholder: 'Sélectionner une catégorie',
      options: categoriesInit.map(cat => ({
        value: cat.id,
        label: cat.name
      })),
      validation: { required: 'La catégorie est requise' }
    }] : [])
  ];

  if (loading) {
    return <div>Chargement des catégories...</div>;
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setPreviewImage(URL.createObjectURL(selectedFile));
  };

  const handleTranslationChange = (field, value) => {
    if (value !== '<p><br></p>' || allTranslations[selectedLanguage][field] === '<p><br></p>') {
      setAllTranslations(prev => ({
        ...prev,
        [selectedLanguage]: {
          ...prev[selectedLanguage],
          [field]: value
        }
      }));
    }
  };

  const handleTranslationBlur = async (field, value) => {
    if (item?.id) {
      try {
        await updateTranslation(item.id, selectedLanguage, field, value);
      } catch (error) {
        console.error('Erreur lors de la mise à jour de la traduction:', error);
      }
    }
  };

  const handleCategoryChange = (e) => {
    const newCategoryId = e.target.value;
    setFormData(prev => ({
      ...prev,
      categoryId: newCategoryId
    }));
  };

  const handleSubmit = async (data) => {
    try {
      const submitData = {
        ...data,
        ...formData,
        categoryId: parseInt(formData.categoryId, 10),
        title: allTranslations.fr.title,
        content: allTranslations.fr.content
      };

      let savedArticle;

      if (item?.id) {
        savedArticle = await update(item.id, submitData);
        toast.success('Article modifié avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedArticle);
            }
          }
        });
      } else {
        savedArticle = await create(submitData);
        toast.success('Article créé avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedArticle);
            }
          }
        });
      }

      if (file) {
        try {
          await new Promise(resolve => setTimeout(resolve, 1000));

          const imageFormData = new FormData();
          imageFormData.append("file", file);
          await uploadImage(savedArticle.id, imageFormData);

          const updatedArticle = await fetchById(savedArticle.id);
          if (updatedArticle) {
            savedArticle = updatedArticle;
          }
        } catch (imageError) {
          console.error('Erreur lors de l\'upload de l\'image:', imageError);
        }
      }

      for (const lang of Object.keys(allTranslations)) {
        const translation = allTranslations[lang];
        if ((translation.title || translation.content) && !(lang === 'fr' && !item?.id)) {
          await updateTranslation(savedArticle.id, lang, 'title', translation.title);
          await updateTranslation(savedArticle.id, lang, 'content', translation.content);
        }
      }

    } catch (error) {
      console.error('Erreur lors de la sauvegarde :', error);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'color': [] }, { 'background': [] }],
      ['link'],
      ['clean']
    ],
  };

  return (
    <div className='relative'>
      <Form
        onSubmit={handleSubmit}
        onCancel={onCancel}
        fields={[]}
        initialValues={item}
        title={item ? "Modifier l'article" : "Nouvel article"}
        submitLabel={item ? "Mettre à jour" : "Publier"}
      >
        <div className="mt-8 space-y-6">
          <div>
            <label className="block font-semibold mb-1">Langue</label>
            <select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              {availableLanguages.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block font-semibold mb-1">Titre</label>
            <input
              type="text"
              value={allTranslations[selectedLanguage].title}
              onChange={(e) => handleTranslationChange('title', e.target.value)}
              onBlur={(e) => handleTranslationBlur('title', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder={`Titre en ${selectedLanguage}`}
            />
          </div>

          <div>
            <label className="block font-semibold mb-1">Catégorie</label>
            <select
              name="categoryId"
              value={formData.categoryId}
              onChange={handleCategoryChange}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Sélectionner une catégorie</option>
              {categoriesInit.map(cat => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block font-semibold mb-1">Contenu</label>
            <ArticleEditor
              value={allTranslations[selectedLanguage].content}
              onChange={(content) => handleTranslationChange('content', content)}
              onBlur={() => handleTranslationBlur('content', allTranslations[selectedLanguage].content)}
            />
          </div>

          <div>
            <label className="block font-semibold mb-1">Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
            {previewImage && (
              <div className="mt-2">
                <img
                  src={previewImage}
                  alt="Aperçu"
                  className="w-48 h-48 object-cover rounded"
                />
              </div>
            )}
          </div>
        </div>
      </Form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ArticleForm; 