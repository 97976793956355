import React, { useState, useEffect } from 'react';
import Form from '../common/Form';
import { API_BASE_URL } from '../../config/apiConfig';
import { create, update, uploadImage, fetchOne } from '../../services/specificServices/offices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
  success: {
    style: {
      background: '#0B86C8',
      color: 'white'
    }
  },
  error: {
    style: {
      background: '#f64915',
      color: 'white'
    }
  }
};

const OfficeForm = ({ item = null, onSubmit, onCancel }) => {
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [tempImageUrl, setTempImageUrl] = useState(null);

  useEffect(() => {
    if (item?.imageUrl) {
      const fullImageUrl = `${API_BASE_URL}${item.imageUrl}`;
      setOriginalImage(fullImageUrl);
      setPreviewImage(fullImageUrl);
    } else {
      setOriginalImage(null);
      setPreviewImage(null);
    }
  }, [item]);

  useEffect(() => {
    return () => {
      if (tempImageUrl) {
        URL.revokeObjectURL(tempImageUrl);
      }
    };
  }, [tempImageUrl]);

  const fields = [
    {
      name: 'country',
      label: 'Pays',
      type: 'text',
      placeholder: 'Pays',
      validation: { required: 'Le pays est requis' }
    },
    {
      name: 'city',
      label: 'Ville',
      type: 'text',
      placeholder: 'Ville',
      validation: { required: 'La ville est requise' }
    },
    {
      name: 'address',
      label: 'Adresse',
      type: 'text',
      placeholder: 'Adresse',
      validation: { required: 'L\'adresse est requise' }
    },
    {
      name: 'postalCode',
      label: 'Code postal',
      type: 'text',
      placeholder: 'Code postal',
      validation: { required: 'Le code postal est requis' }
    },
    {
      name: 'websiteUrl',
      label: 'Site web',
      type: 'text',
      placeholder: 'Site web',
      validation: { required: 'Le site web est requis' }
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      placeholder: 'Description',
      validation: { required: 'La description est requise' }
    },
  ];

  const handleSubmit = async (data) => {
    try {
      let savedOffice;

      const { id, imageUrl, ...officeData } = data;
      if (!file && item?.imageUrl) {
        officeData.imageUrl = item.imageUrl;
      }

      if (item?.id) {
        savedOffice = await update(item.id, officeData);
        toast.success('Bureau modifié avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedOffice);
            }
          }
        });
      } else {
        savedOffice = await create(officeData);
        toast.success('Bureau créé avec succès', {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: toastStyle.success.style,
          onClose: () => {
            if (typeof onSubmit === 'function') {
              onSubmit(savedOffice);
            }
          }
        });
      }

      if (file) {
        try {
          await uploadImage(savedOffice.id, file);
          savedOffice = await fetchOne(savedOffice.id);
        } catch (uploadError) {
          console.warn("Erreur lors de l'upload de l'image:", uploadError);
        }
      }

    } catch (error) {
      console.error("Une erreur est survenue lors de la sauvegarde :", error);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (tempImageUrl) {
        URL.revokeObjectURL(tempImageUrl);
      }

      setFile(selectedFile);
      const newTempUrl = URL.createObjectURL(selectedFile);
      setTempImageUrl(newTempUrl);
      setPreviewImage(newTempUrl);
    }
  };

  const handleCancel = () => {
    if (tempImageUrl) {
      URL.revokeObjectURL(tempImageUrl);
      setTempImageUrl(null);
    }

    setPreviewImage(originalImage);
    setFile(null);

    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  return (
    <div className='relative'>
      <Form
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        fields={fields}
        initialValues={item}
        title={item ? "Modifier le bureau" : "Nouveau bureau"}
        submitLabel={item ? "Mettre à jour" : "Créer"}
      >
        {previewImage && (
          <div>
            <label className="block font-semibold mb-1">Image actuelle</label>
            <img
              src={previewImage}
              alt="Aperçu"
              className="w-48 h-48 object-cover rounded"
            />
          </div>
        )}

        <div>
          <label className="block font-semibold mb-1">Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      </Form>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default OfficeForm;
