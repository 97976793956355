import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Table = ({
  columns,
  data = [],
  onDragEnd,
  draggable = false,
  actions,
  className = ''
}) => {
  const renderCell = (item, column) => {
    if (column.render) {
      return column.render(item);
    }
    return item[column.key];
  };

  const handleDragEnd = (result) => {
    if (!result.destination || !onDragEnd) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const orderedIds = items.map((item, index) => ({
      id: item.id,
      order: index + 1
    }));

    onDragEnd(orderedIds);
  };

  return (
    <div className="overflow-x-auto">
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className={`min-w-full bg-white rounded ${className}`}>
          <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              {columns.map((column) => (
                <th key={column.key} className={`py-3 px-6 text-left ${column.className || ''}`}>
                  {column.title}
                </th>
              ))}
              {actions && <th className="py-3 px-6 text-center">Actions</th>}
            </tr>
          </thead>
          <Droppable droppableId="table-body" isDropDisabled={!draggable}>
            {(provided) => (
              <tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="text-gray-700 text-sm"
              >
                {data.map((item, index) => (
                  <Draggable
                    key={item.id.toString()}
                    draggableId={item.id.toString()}
                    index={index}
                    isDragDisabled={!draggable}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...(draggable ? provided.dragHandleProps : {})}
                        className="border-b border-gray-200 hover:bg-gray-100"
                      >
                        {columns.map((column) => (
                          <td key={column.key} className={`py-3 px-6 ${column.cellClassName || ''}`}>
                            {renderCell(item, column)}
                          </td>
                        ))}
                        {actions && (
                          <td className="py-3 px-6 text-center">
                            {actions(item)}
                          </td>
                        )}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
};

export default Table; 