import React, { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useJobOffer } from '../../hooks/useJobOffer';
import { FaMapMarkerAlt, FaBriefcase, FaEnvelope, FaWhatsapp, FaTelegram, FaArrowLeft } from 'react-icons/fa';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { generateSlug } from '../../services/specificServices/jobOffers';

const JobDetails = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const { jobOffer, loading, error } = useJobOffer(slug);
    const { t } = useTranslation();

    useEffect(() => {
        if (jobOffer) {
            const currentSlug = generateSlug(jobOffer.title);
            if (currentSlug !== slug) {
                navigate(`/carrieres/${currentSlug}`, { replace: true });
            }
        }
    }, [jobOffer, slug, navigate]);

    const handleShare = (platform) => {
        const jobUrl = window.location.href;
        const title = jobOffer?.title;
        const text = title;

        switch (platform) {
            case 'email':
                window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(`${text}\n${jobUrl}`)}`;
                break;
            case 'sms':
                window.location.href = `sms:?body=${encodeURIComponent(`${text} ${jobUrl}`)}`;
                break;
            case 'whatsapp':
                window.open(`https://wa.me/?text=${encodeURIComponent(`${text} ${jobUrl}`)}`, '_blank');
                break;
            case 'telegram':
                window.open(`https://t.me/share/url?url=${encodeURIComponent(jobUrl)}&text=${encodeURIComponent(text)}`, '_blank');
                break;
            default:
                break;
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    if (error || !jobOffer) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-center">
                    <p className="text-xl text-gray-600 mb-4 font-dmsans">{t('messages.no_job_offers_found')}</p>
                    <Link to="/carrieres" className="text-[#0B86C8] hover:text-[#289EDC] font-dmsans">
                        {t('buttons.return_job_offers')}
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50">
            <div className="bg-[#0B86C8] text-white py-8 sm:py-12 lg:py-16">
                <div className="container mx-auto px-6 sm:px-8 lg:px-12">
                    <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-6 lg:gap-8">
                        <div className="w-full lg:w-2/3 space-y-4">
                            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold font-dmsans">{jobOffer.title}</h1>
                            <div className="flex flex-wrap items-center gap-4 text-white/90">
                                <div className="flex items-center font-dmsans">
                                    <FaMapMarkerAlt className="mr-2" />
                                    {jobOffer.location}
                                </div>
                                {jobOffer.contractType && (
                                    <div className="flex items-center font-dmsans">
                                        <FaBriefcase className="mr-2" />
                                        {jobOffer.contractType}
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-wrap gap-2 font-dmsans">
                                {jobOffer.tags?.map(tag => (
                                    <span
                                        key={tag.id}
                                        className="px-3 py-1 bg-[#898D93] rounded-full text-sm font-dmsans"
                                    >
                                        {tag.name}
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className="w-full lg:w-1/3 flex justify-start lg:justify-center">
                            <a
                                href={`mailto:recrutement@alcyone-consulting.lu?subject=Candidature pour le poste de ${jobOffer.title}`}
                                className="w-full sm:w-auto px-6 py-4 sm:py-6 lg:py-6 bg-[#17508A] text-white rounded-lg hover:bg-[#044A4D] transition-colors text-base sm:text-lg font-semibold text-center font-dmsans"
                            >
                                {t('buttons.postulate')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-6 sm:px-8 lg:px-12 py-8 sm:py-12">
                <Link
                    to="/carrieres"
                    className="inline-flex items-center text-[#0B86C8] hover:text-[#289EDC] mb-6 sm:mb-8 font-dmsans"
                >
                    <FaArrowLeft className="mr-2" /> {t('buttons.return_job_offers')}
                </Link>
                <div className="bg-white rounded-lg shadow-lg p-6 sm:p-8 lg:p-10">
                    <div className="flex flex-col lg:flex-row gap-8 lg:gap-12">
                        <div className="w-full lg:w-3/4">
                            <div className="text-gray-500 mb-6 font-dmsans">
                                {t('buttons.posted_date')} {new Date(jobOffer.postedDate).toLocaleDateString()}
                            </div>
                            <div className="prose max-w-none">
                                <div className="whitespace-pre-line text-gray-700 text-base sm:text-lg space-y-4 font-dmsans">
                                    {jobOffer.description}
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-1/4">
                            <div className="lg:sticky lg:top-8">
                                <h3 className="text-lg font-semibold mb-6 font-dmsans">{t('buttons.share')}</h3>
                                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-1 gap-4">
                                    <button
                                        onClick={() => handleShare('email')}
                                        className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 bg-[#0B86C8] hover:bg-[#289EDC] text-white rounded-lg transition-colors"
                                    >
                                        <FaEnvelope className="text-lg" /> 
                                        <span className="hidden sm:inline font-dmsans">Email</span>
                                    </button>
                                    <button
                                        onClick={() => handleShare('sms')}
                                        className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 bg-[#0B86C8] hover:bg-[#289EDC] text-white rounded-lg transition-colors"
                                    >
                                        <IoChatbubbleEllipsesOutline className="text-lg" /> 
                                        <span className="hidden sm:inline font-dmsans">SMS</span>
                                    </button>
                                    <button
                                        onClick={() => handleShare('whatsapp')}
                                        className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 bg-[#0B86C8] hover:bg-[#289EDC] text-white rounded-lg transition-colors"
                                    >
                                        <FaWhatsapp className="text-lg" /> 
                                        <span className="hidden sm:inline font-dmsans">WhatsApp</span>
                                    </button>
                                    <button
                                        onClick={() => handleShare('telegram')}
                                        className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 bg-[#0B86C8] hover:bg-[#289EDC] text-white rounded-lg transition-colors"
                                    >
                                        <FaTelegram className="text-lg" /> 
                                        <span className="hidden sm:inline font-dmsans">Telegram</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDetails; 