import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useNavigationConfig } from '../../config/navigationConfig';
import LanguageSelector from './LanguageSelector';
import { IoIosArrowDown } from "react-icons/io";
import { FaLinkedin } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
const MobileMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const navigationConfig = useNavigationConfig();
    const { t } = useTranslation();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    return (
        <div ref={menuRef} className="overflow-scroll">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none"
            >
                {isOpen ? (
                    <FaTimes className="h-5 w-5" />
                ) : (
                    <FaBars className="h-5 w-5" />
                )}
            </button>

            <div
                className={`fixed inset-0 z-[999] transition-all duration-500 ease-in-out ${isOpen ? 'visible opacity-100' : 'invisible opacity-0'
                    }`}
            >
                <div
                    className={`absolute inset-0 bg-black/50 h-screen backdrop-blur-sm transition-opacity duration-500 ${isOpen ? 'opacity-100' : 'opacity-0'
                        }`}
                    onClick={() => setIsOpen(false)}
                />

                <div
                    className={`absolute inset-y-0 right-0 w-[280px] bg-white h-screen shadow-2xl transition-transform duration-500 ease-out ${isOpen ? 'translate-x-0' : 'translate-x-full'
                        }`}
                >
                    <div className="flex flex-col h-screen overflow-y-auto pb-32">
                        <div className={`flex items-center justify-between p-4 border-b border-gray-100 transition-opacity duration-300 delay-200 ${isOpen ? 'opacity-100' : 'opacity-0'
                            }`}>
                            <h2 className="text-lg font-bold text-gray-800 font-dmsans">Menu</h2>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                            >
                                <FaTimes className="h-5 w-5 text-gray-500" />
                            </button>
                        </div>

                        <div className="">
                            <nav className={`p-4 space-y-2 transition-all duration-300 delay-300 ${isOpen ? 'translate-x-0 opacity-100' : 'translate-x-8 opacity-0'
                                }`}>
                                {navigationConfig.map((item, index) => (
                                    <Link
                                        key={item.path}
                                        to={item.path}
                                        onClick={() => setIsOpen(false)}
                                        className={`flex items-center justify-between py-3 px-2 text-gray-700 hover:text-[#0B86C8] 
                                            transition-all duration-300 rounded-lg hover:bg-gray-50
                                            ${isOpen ? 'translate-x-0 opacity-100' : 'translate-x-8 opacity-0'}
                                        `}
                                        style={{ transitionDelay: `${300 + index * 100}ms` }}
                                    >
                                        <span className="text-base font-dmsans font-medium">
                                            {item.label}
                                        </span>
                                        <IoIosArrowDown className="h-4 w-4" />
                                    </Link>
                                ))}
                            </nav>
                        </div>

                        <div className={`border-t border-gray-100 transition-all z-[99] duration-300 delay-500 ${isOpen ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
                            }`}>
                            <div className="p-4 bg-aco-Grey">
                                <p className="text-sm text-gray-500 mb-2 font-dmsans">{t('buttons.language')}</p>
                                <LanguageSelector />
                            </div>
                        </div>

                        <div className={`flex-col gap-2 mb-8 lg:hidden justify-center items-center p-4 space-y-2
                            transition-all duration-300 delay-700 ${isOpen ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
                            }`}>
                            <div className="flex-row gap-2 flex">
                                <a
                                    href="https://www.linkedin.com/company/lusis-luxembourg"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-[#0B86C8] hover:text-[#289EDC] border-[#0B86C8] rounded-full"
                                    aria-label="LinkedIn"
                                >
                                    <FaLinkedin className="w-8 h-8 border p-1.5 rounded-full" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu; 