import { useMentions } from "../../hooks/useMentions";

const Mentions = () => {
    const { items, loading, error } = useMentions();

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="mt-8 mx-auto">
                <div className="font-dmsans text-justify space-y-3">
                    {items?.map((item) => (
                        <div
                            dangerouslySetInnerHTML={{ __html: item.mentions }}
                            className="text-gray-800 leading-relaxed text-justify mentions"
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Mentions;