import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import AuthGuard from './components/SSO/AuthGuard';
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <AuthGuard>
      <App />
    </AuthGuard>
  </Router>
);