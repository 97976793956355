import React, { useState, useEffect } from 'react';
import { fetchSettings, updateSettings, uploadLogo } from '../../services/specificServices/settings';
import { API_BASE_URL } from '../../config/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    success: {
        style: {
            background: '#0B86C8',
            color: 'white'
        }
    },
    error: {
        style: {
            background: '#f64915',
            color: 'white'
        }
    }
};

const SettingsForm = () => {
    const [settings, setSettings] = useState({
        companyName: '',
        email: '',
        phone: '',
        address: '',
        defaultLogoUrl: '',
        isCountdownActive: false,
        launchDate: new Date().toISOString().slice(0, 16)
    });
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState({ type: '', content: '' });
    const [file, setFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    useEffect(() => {
        const loadSettings = async () => {
            try {
                const data = await fetchSettings();

                const token = localStorage.getItem('token');
                const countdownResponse = await fetch(`${API_BASE_URL}/compteur`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                const countdownData = await countdownResponse.json();

                const currentCounter = countdownData.length > 0 ? countdownData[0] : null;

                const mergedSettings = {
                    ...data,
                    launchDate: currentCounter?.launchDate ?
                        new Date(currentCounter.launchDate).toISOString().slice(0, 16) :
                        settings.launchDate,
                    isCountdownActive: !!currentCounter?.launchDate
                };

                setSettings(mergedSettings);

                if (data.defaultLogoUrl) {
                    setPreviewImage(`${API_BASE_URL}${data.defaultLogoUrl}`);
                }
            } catch (error) {
                console.error('Erreur:', error);
                setMessage({
                    type: 'error',
                    content: "Erreur lors du chargement des paramètres"
                });
            } finally {
                setLoading(false);
            }
        };

        loadSettings();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const formattedDate = new Date(settings.launchDate + 'Z');

            await updateSettings({
                ...settings,
                launchDate: formattedDate
            });

            const token = localStorage.getItem('token');
            const countdownResponse = await fetch(`${API_BASE_URL}/compteur`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const existingCounters = await countdownResponse.json();

            const countdownData = {
                id: existingCounters.length > 0 ? existingCounters[0].id : 1,
                launchDate: settings.isCountdownActive ? formattedDate.toISOString() : null
            };

            if (existingCounters.length > 0) {
                const updateResponse = await fetch(`${API_BASE_URL}/compteur/${existingCounters[0].id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(countdownData),
                });

                if (!updateResponse.ok) {
                    const errorData = await updateResponse.text();
                    console.error('Erreur mise à jour:', errorData);
                    throw new Error('Erreur lors de la mise à jour du compteur');
                }
            } else {
                const createResponse = await fetch(`${API_BASE_URL}/compteur`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(countdownData),
                });

                if (!createResponse.ok) {
                    const errorData = await createResponse.text();
                    console.error('Erreur création:', errorData);
                    throw new Error('Erreur lors de la création du compteur');
                }
            }

            if (file) {
                await uploadLogo(file);
            }

            setSettings(prev => ({
                ...prev,
                launchDate: formattedDate.toISOString().slice(0, 16)
            }));

            const updatedData = await fetchSettings();
            if (updatedData.defaultLogoUrl) {
                setPreviewImage(`${API_BASE_URL}${updatedData.defaultLogoUrl}`);
            }

            toast.success('Paramètres mis à jour avec succès', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                style: toastStyle.success.style
            });

            setFile(null);
        } catch (error) {
            console.error('Erreur:', error);
            toast.error('Erreur lors de la mise à jour des paramètres', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                style: toastStyle.error.style
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewImage(e.target.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setSettings((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-[400px]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    return (
        <div className="relative max-w-full p-6 mx-auto">
            <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md space-y-4">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">Paramètres généraux</h2>
                <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">Informations de l'entreprise</h3>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Nom de l'entreprise
                            </label>
                            <input
                                type="text"
                                name="companyName"
                                value={settings.companyName}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#0B86C8] focus:border-transparent"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={settings.email}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#0B86C8] focus:border-transparent"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Téléphone
                            </label>
                            <input
                                type="tel"
                                name="phone"
                                value={settings.phone}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#0B86C8] focus:border-transparent"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Adresse
                            </label>
                            <input
                                type="text"
                                name="address"
                                value={settings.address}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#0B86C8] focus:border-transparent"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Logo par défaut
                            </label>
                            {previewImage && (
                                <img
                                    src={previewImage}
                                    alt="Logo par défaut"
                                    className="w-32 h-32 object-contain mb-2 border rounded"
                                />
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="w-full p-2 border border-gray-300 rounded"
                            />
                        </div>

                        <div className="col-span-2">
                            <h4 className="text-md font-semibold text-gray-700 mb-3">Configuration du compte à rebours</h4>

                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    id="isCountdownActive"
                                    name="isCountdownActive"
                                    checked={settings.isCountdownActive}
                                    onChange={handleChange}
                                    className="h-4 w-4 text-[#0B86C8] border-gray-300 rounded focus:ring-[#0B86C8]"
                                />
                                <label htmlFor="isCountdownActive" className="ml-2 block text-sm text-gray-700">
                                    Activer le compte à rebours
                                </label>
                            </div>

                            <div className={settings.isCountdownActive ? 'opacity-100' : 'opacity-50 pointer-events-none'}>
                                <label className="block text-sm font-medium text-gray-700 mb-1">Date et heure de lancement</label>
                                <input
                                    type="datetime-local"
                                    name="launchDate"
                                    value={settings.launchDate}
                                    onChange={handleChange}
                                    className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-[#0B86C8] focus:border-transparent"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end pt-4">
                    <button
                        type="submit"
                        disabled={loading}
                        className="px-6 py-2 bg-[#0B86C8] text-white rounded hover:bg-[#289EDC] transition-colors disabled:opacity-50"
                    >
                        {loading ? 'Enregistrement...' : 'Enregistrer les modifications'}
                    </button>
                </div>
            </form>

            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                limit={3}
                toastStyle={toastStyle}
            />
        </div>
    );
};

export default SettingsForm; 