import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import AdminDashboard from './pages/AdminDashboard';
import HomePage from './pages/public/HomePage';
import ArticlesPage from './pages/public/ArticlesPage';
import ArticleDetails from './pages/public/ArticleDetails';
import PublicLayout from './layouts/PublicLayout';
import Contact from './pages/public/Contact';
import Team from './pages/public/Team';
import Careers from './pages/public/Careers';
import JobDetails from './pages/public/JobDetails';
import About from './pages/public/About';
import ServicesPage from './pages/public/ServicesPage';
import ServiceDetailPage from './pages/public/ServiceDetailPage';
import useAuth from './components/SSO/useAuth';
import { jwtDecode } from "jwt-decode";
import { getProfile } from './services/auth';
import Privacy from './pages/public/Privacy';
import Mentions from './pages/public/Mentions';
import Countdown from './components/common/Countdown';
import { API_BASE_URL } from './config/apiConfig';

const App = () => {
  const auth = useAuth();
  const location = useLocation();
  const [userProfile, setUserProfile] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isLaunchTime, setIsLaunchTime] = useState(false);
  const [launchDate, setLaunchDate] = useState(null);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        localStorage.removeItem('token');
        auth.displayLogin();
        setIsLoading(false);
      } else {
        getProfile()
          .then(data => {
            if (data) {
              setUserProfile(data);
              setUserRole(data.role);
            } else {
              console.warn("Profil utilisateur non trouvé");
              setUserProfile(null);
            }
          })
          .catch(error => {
            console.error("Erreur lors de la récupération du profil :", error);
            setUserProfile(null);
          });
      }
    } else {
      setIsLoading(false);
    }

    const fetchCountdownSettings = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/compteur`);
        const data = await response.json();

        if (data && data.length > 0) {
          const countdownData = data[0];
          const launchDateTime = new Date(countdownData.launchDate);
          const now = new Date();

          setLaunchDate(countdownData.launchDate);

          if (launchDateTime > now) {
            setIsCountdownActive(true);
            setIsLaunchTime(false);
          } else {
            setIsCountdownActive(false);
            setIsLaunchTime(true);
          }
        } else {
          setIsCountdownActive(false);
          setLaunchDate(null);
          setIsLaunchTime(true);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres du compteur:', error);
        setIsCountdownActive(false);
        setLaunchDate(null);
        setIsLaunchTime(true);
      }
    };

    fetchCountdownSettings();
  }, []);

  useEffect(() => {
    if (!launchDate) {
      setIsLaunchTime(true);
      setIsCountdownActive(false);
      return;
    }

    const now = new Date();
    const launch = new Date(launchDate);

    if (now >= launch) {
      setIsLaunchTime(true);
      setIsCountdownActive(false);
    }
  }, [launchDate]);


  useEffect(() => {
    if (!isLaunchTime && isCountdownActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isLaunchTime, isCountdownActive]);

  const handleCountdownEnd = () => {
    setIsLaunchTime(true);
    setIsCountdownActive(false);
    navigate('/');
  };

  if (!isLaunchTime && location.pathname !== '/admin' && isCountdownActive) {
    return (
      <>
        <div style={{
          filter: 'blur(6px)',
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 1,
          backgroundImage: `url(./old-website.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}>
        </div>
        <div style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 2
        }}>
          <Countdown
            targetDate={launchDate}
            onCountdownEnd={handleCountdownEnd}
          />
        </div>
      </>
    );
  }

  const PrivateRoute = ({ children, allowedRoles, userProfile }) => {
    const token = localStorage.getItem("token");
    const userRole = token ? jwtDecode(token).roles : null;

    const role = [...userRole, userProfile?.role].filter(Boolean);

    if (!userProfile) {
      return <div>Chargement...</div>;
    }

    if (!userRole) return <Navigate to="/" replace />;

    const hasAccess = role.some(r => allowedRoles.includes(r));

    if (!hasAccess) {
      return <Navigate to="/" replace />;
    }


    if (!hasAccess) return <Navigate to="/" replace />;

    return children;
  };

  return (
    <div>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/actualites" element={<ArticlesPage number={8} headerActive={true} />} />
          <Route path="/actualites/:slug" element={<ArticleDetails />} />
          <Route path="/equipe" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/carrieres" element={<Careers />} />
          <Route path="/carrieres/:slug" element={<JobDetails />} />
          <Route path="/a-propos" element={<About />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/services/:slug" element={<ServiceDetailPage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/politique-confidentialite" element={<Privacy />} />
          <Route path="/mentions-legales" element={<Mentions />} />
        </Route>
        <Route path="/admin" element={<AdminDashboard userProfile={userProfile} />} />
      </Routes>
    </div>
  );
};

export default App;

