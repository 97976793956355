import React, { useState } from 'react';
import { API_BASE_URL } from '../../config/apiConfig';
import { CiLinkedin } from 'react-icons/ci';

const TeamMemberCard = ({ firstname, name, role, image, linkedin, bio }) => {

    return (
        <div
            className="relative bg-white rounded-lg shadow-md w-full mb-8 sm:mb-0 transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
        >
            <div className="relative w-full h-96 overflow-hidden rounded-t-lg">
                <div className="absolute inset-0 transition-opacity duration-300 ease-in-out opacity-100">
                    <img
                        src={`${API_BASE_URL}${image}`}
                        alt={name}
                        className="w-full h-full object-cover transition-transform duration-300 scale-100 hover:scale-105"
                    />
                </div>
            </div>

            <div className="absolute -bottom-6 left-0 bg-white w-11/12 p-4 shadow-lg rounded-e-md 
                transform transition-all duration-300 hover:shadow-xl">
                <div className="flex justify-between items-center">
                    <div className="flex flex-col pr-2">
                        <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800 
                            text-left font-dmsans truncate transition-colors duration-300 
                            hover:text-[#0B86C8]">
                            {firstname} {name.toUpperCase()}
                        </h3>
                        <p className="text-sm sm:text-base text-gray-600 text-left font-dmsans 
                            truncate transition-colors duration-300">
                            {role}
                        </p>
                    </div>
                    <a
                        href={linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-shrink-0 transform transition-all duration-300 hover:scale-110"
                    >
                        <div className="p-1.5 sm:p-2 bg-[#0B86C8] rounded-md 
                            transition-colors duration-300 hover:bg-[#289EDC]">
                            <CiLinkedin className="text-white w-5 h-5 sm:w-6 sm:h-6 lg:w-8 lg:h-8" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TeamMemberCard; 