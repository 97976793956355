import { useState } from 'react';
import CryptoJS from 'crypto-js';

const useAuth = () => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('token'));

    const environment = {
        auth: {
            authorizationUrl: process.env.REACT_APP_AUTH_AUTHORIZATION_URL,
            clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
            clientPassword: process.env.REACT_APP_AUTH_CLIENT_PASSWORD,
            redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
            challengeMethod: process.env.REACT_APP_AUTH_CHALLENGE_METHOD,
            tokenUrl: process.env.REACT_APP_AUTH_TOKEN_URL,
            logoutUrl: process.env.REACT_APP_AUTH_LOGOUT_URL,
        },
    };

    const displayLogin = () => {
        let code = Math.random().toString(36);
        localStorage.setItem('codeVerifier', code);

        let codeChallenge = CryptoJS.enc.Base64url.stringify(CryptoJS.SHA256(code));

        window.location.href = `${process.env.REACT_APP_AUTH_AUTHORIZATION_URL}?` +
            `response_type=code&` +
            `scope=email&` +
            `client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&` +
            `redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}&` +
            `code_challenge_method=${process.env.REACT_APP_AUTH_CHALLENGE_METHOD}&` +
            `code_challenge=${codeChallenge}`;
    };

    const retrieveToken = async (code) => {
        const codeVerifier = localStorage.getItem('codeVerifier');
        let params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('client_id', process.env.REACT_APP_AUTH_CLIENT_ID);
        params.append('client_secret', process.env.REACT_APP_AUTH_CLIENT_PASSWORD);
        params.append('redirect_uri', process.env.REACT_APP_AUTH_REDIRECT_URI);
        params.append('code_verifier', codeVerifier);
        params.append('code', code);

        try {
            const response = await fetch(process.env.REACT_APP_AUTH_TOKEN_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
                body: params.toString()
            });

            if (!response.ok) {
                throw new Error('Invalid Credentials');
            }

            const data = await response.json();
            saveToken(data);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };


    const saveToken = (data) => {
        console.log("Saving token:", data);

        if (data?.access_token) {
            localStorage.setItem('token', data.access_token);
            setAccessToken(data.access_token);
        } else {
            console.error("Access token missing in the response.");
        }
    };


    const isAuthenticated = () => !!accessToken;

    return {
        displayLogin,
        retrieveToken,
        isAuthenticated,
    };
};

export default useAuth;
