import { http } from "../api";

export const fetchSettings = () => http("/settings");

export const updateSettings = async (data) => {
    return http("/settings", {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            companyName: data.companyName,
            email: data.email,
            phone: data.phone,
            address: data.address
        })
    });
};

export const uploadLogo = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return http('/settings/uploadLogo', {
        method: "POST",
        body: formData,
    });
}; 