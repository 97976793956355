import React from "react";
import { HiMiniChevronDoubleLeft, HiMiniChevronDoubleRight } from "react-icons/hi2";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    if (totalPages <= 1) return null;

    return (
        <div className="flex justify-center mt-10 font-opensans font-semibold text-sm">
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`mx-1 px-3 flex items-center rounded-full ${
                    currentPage === 1
                        ? "text-gray-500 cursor-not-allowed border-2 border-[#F1F1F1]"
                        : "text-gray-700 hover:bg-[#0B86C8] hover:text-white border-2 border-[#F1F1F1]"
                }`}
            >
                <HiMiniChevronDoubleLeft />
            </button>

            {Array.from({ length: totalPages }, (_, i) => (
                <button
                    key={i}
                    onClick={() => onPageChange(i + 1)}
                    className={`mx-1 px-4 py-2 rounded-full ${
                        currentPage === i + 1
                            ? "bg-[#0B86C8] text-white"
                            : "text-gray-700 hover:bg-[#0B86C8] hover:text-white border-2 border-[#F1F1F1]"
                    }`}
                >
                    {i + 1}
                </button>
            ))}

            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`mx-1 px-3 rounded-full ${
                    currentPage === totalPages
                        ? "hover:bg-[#0B86C8] hover:text-white cursor-not-allowed border-2 border-[#F1F1F1]"
                        : "text-gray-700 hover:bg-[#0B86C8] hover:text-white border-2 border-[#F1F1F1]"
                }`}
            >
                <HiMiniChevronDoubleRight />
            </button>
        </div>
    );
};

export default Pagination; 