import * as articleService from '../services/specificServices/articles';
import * as categoryService from '../services/specificServices/categories';
import * as officeService from '../services/specificServices/offices';
import * as teamMemberService from '../services/specificServices/teamMembers';
import * as clientService from '../services/specificServices/clients';
import * as labelService from '../services/specificServices/labels';
import * as jobOfferService from '../services/specificServices/jobOffers';
import * as logoPeriodService from '../services/specificServices/logoPeriods';
import * as serviceService from '../services/specificServices/services';
import * as projectService from '../services/specificServices/projects';
import * as playlistService from '../services/specificServices/playlist';

import ArticleTable from '../components/tables/ArticleTable';
import CategoryTable from '../components/tables/CategoryTable';
import OfficeTable from '../components/tables/OfficeTable';
import TeamMemberTable from '../components/tables/TeamMemberTable';
import ClientTable from '../components/tables/ClientTable';
import LabelTable from '../components/tables/LabelTable';
import JobOfferTable from '../components/tables/JobOfferTable';
import LogoPeriodTable from '../components/tables/LogoPeriodTable';
import ServiceTable from '../components/tables/ServiceTable';
import ProjectTable from '../components/tables/ProjectTable';
import PlaylistTable from '../components/tables/PlaylistsTable';

import ArticleForm from '../components/forms/ArticleForm';
import CategoryForm from '../components/forms/CategoryForm';
import OfficeForm from '../components/forms/OfficeForm';
import TeamMemberForm from '../components/forms/TeamMemberForm';
import ClientForm from '../components/forms/ClientForm';
import LabelForm from '../components/forms/LabelForm';
import JobOfferForm from '../components/forms/JobOfferForm';
import LogoPeriodForm from '../components/forms/LogoPeriodForm';
import ServiceForm from '../components/forms/ServiceForm';
import ProjectForm from '../components/forms/ProjectForm';
import PlaylistForm from '../components/forms/PlaylistForm';

export const resourcesConfig = {
  articles: {
    name: 'Articles',
    service: articleService,
    TableComponent: ArticleTable,
    FormComponent: ArticleForm,
    routes: {
      list: 'articles',
      create: 'createArticle',
      edit: 'editArticle'
    },
    options: {
      reloadOnChange: true,
      addButtonLabel: 'Nouvel article',
      editButtonLabel: 'Modifier',
      deleteButtonLabel: 'Supprimer',
      createFormTitle: 'Créer un article',
      editFormTitle: 'Modifier l\'article'
    }
  },
  categories: {
    name: 'Catégories',
    service: categoryService,
    TableComponent: CategoryTable,
    FormComponent: CategoryForm,
    routes: {
      list: 'categories',
      create: 'createCategory',
      edit: 'editCategory'
    },
    options: {
      addButtonLabel: 'Nouvelle catégorie',
      editButtonLabel: 'Modifier',
      deleteButtonLabel: 'Supprimer',
      createFormTitle: 'Créer une catégorie',
      editFormTitle: 'Modifier la catégorie'
    }
  },
  offices: {
    name: 'Bureaux',
    service: officeService,
    TableComponent: OfficeTable,
    FormComponent: OfficeForm,
    routes: {
      list: 'offices',
      create: 'createOffice',
      edit: 'editOffice'
    },
    options: {
      addButtonLabel: 'Nouveau bureau',
      editButtonLabel: 'Modifier',
      deleteButtonLabel: 'Supprimer',
      createFormTitle: 'Créer un bureau',
      editFormTitle: 'Modifier le bureau'
    }
  },
  teamMembers: {
    name: 'Équipe',
    service: teamMemberService,
    TableComponent: TeamMemberTable,
    FormComponent: TeamMemberForm,
    routes: {
      list: 'team',
      create: 'createTeamMember',
      edit: 'editTeamMember'
    },
    options: {
      addButtonLabel: 'Nouveau membre',
      editButtonLabel: 'Modifier',
      deleteButtonLabel: 'Supprimer',
      createFormTitle: 'Ajouter un membre',
      editFormTitle: 'Modifier un membre'
    }
  },
  clients: {
    name: 'Clients',
    service: clientService,
    TableComponent: ClientTable,
    FormComponent: ClientForm,
    routes: {
      list: 'clients',
      create: 'createClient',
      edit: 'editClient'
    },
    options: {
      addButtonLabel: 'Nouveau client',
      editButtonLabel: 'Modifier',
      deleteButtonLabel: 'Supprimer',
      createFormTitle: 'Ajouter un client',
      editFormTitle: 'Modifier le client'
    }
  },
  labels: {
    name: 'Labels',
    service: labelService,
    TableComponent: LabelTable,
    FormComponent: LabelForm,
    routes: {
      list: 'labels',
      create: 'createLabel',
      edit: 'editLabel'
    },
    options: {
      addButtonLabel: 'Nouveau label',
      editButtonLabel: 'Modifier',
      deleteButtonLabel: 'Supprimer',
      createFormTitle: 'Ajouter un label',
      editFormTitle: 'Modifier le label'
    }
  },
  jobs: {
    name: "Offres d'emploi",
    service: jobOfferService,
    TableComponent: JobOfferTable,
    FormComponent: JobOfferForm,
    routes: {
      list: 'jobs',
      create: 'createJob',
      edit: 'editJob'
    },
    options: {
      addButtonLabel: 'Nouvelle offre',
      editButtonLabel: 'Modifier',
      deleteButtonLabel: 'Supprimer',
      createFormTitle: "Créer une offre d'emploi",
      editFormTitle: "Modifier l'offre d'emploi"
    }
  },
  logoPeriods: {
    name: "Logos & Périodes",
    service: logoPeriodService,
    TableComponent: LogoPeriodTable,
    FormComponent: LogoPeriodForm,
    routes: {
        list: 'logoPeriods',
        create: 'createLogoPeriod',
        edit: 'editLogoPeriod'
    },
    options: {
        addButtonLabel: 'Nouvelle période',
        editButtonLabel: 'Modifier',
        deleteButtonLabel: 'Supprimer',
    }
  },
  services: {
    name: "Services",
    service: serviceService,
    TableComponent: ServiceTable,
    FormComponent: ServiceForm,
    routes: {
        list: 'services',
        create: 'createService',
        edit: 'editService'
    },
    options: {
        addButtonLabel: 'Nouveau service',
        editButtonLabel: 'Modifier',
        deleteButtonLabel: 'Supprimer',
        createFormTitle: 'Créer un service',
        editFormTitle: 'Modifier le service'
    }
  },
  projects: {
    name: "Références de projets",
    service: projectService,
    TableComponent: ProjectTable,
    FormComponent: ProjectForm,
    routes: {
        list: 'projects',
        create: 'createProject',
        edit: 'editProject'
    },
    options: {
        addButtonLabel: 'Nouveau projet',
        editButtonLabel: 'Modifier',
        deleteButtonLabel: 'Supprimer',
        createFormTitle: 'Créer un projet',
        editFormTitle: 'Modifier le projet'
    }
  },
  playlists: {
    name: 'Playlists',
    service: playlistService,
    TableComponent: PlaylistTable,
    FormComponent: PlaylistForm,
    routes: {
      list: 'playlists',
      create: 'createPlaylist',
      edit: 'editPlaylist'
    },
    options: {
      createFormTitle: 'Créer une playlist',
      editFormTitle: 'Modifier la playlist'
    }
  }
}; 