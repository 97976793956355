import React, { useEffect, useState } from 'react';
import Table from '../common/Table';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../config/apiConfig';

const SuperAdminTable = () => {
    const [superAdmins, setSuperAdmins] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        fetch(`${API_BASE_URL}/user/all`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => response.json())
            .then(data => setSuperAdmins(data))
            .catch(error =>
                toast.error('Erreur lors de la récupération des super admins:', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: {
                        background: '#0B86C8',
                        color: 'white'
                    }
                })
            );
    }, []);

    const handleRoleChange = (user, role) => {
        fetch(`${API_BASE_URL}/user/change-role/${user.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ role })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la modification du rôle');
                }
                return response.json();
            })
            .then(updatedUser => {
                setSuperAdmins(prevState =>
                    prevState.map(admin => admin.id === updatedUser.id ? updatedUser : admin)
                );
                toast.success('Rôle modifié avec succès', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: {
                        background: '#0B86C8',
                        color: 'white'
                    }
                });
            })
            .catch(error => {
                toast.error('Erreur lors de la modification du rôle', {
                    position: "bottom-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: {
                        background: '#f64915',
                        color: 'white'
                    }
                });
            });
    };

    const filteredSuperAdmins = superAdmins.filter(admin =>
        `${admin.prenom} ${admin.nom}`.toLowerCase().includes(search.toLowerCase())
    );

    const columns = [
        {
            key: 'prenom',
            title: 'Prénom',
        },
        {
            key: 'nom',
            title: 'Nom',
        },
        {
            key: 'email',
            title: 'Email',
        },
        {
            key: 'role',
            title: 'Rôle',
            render: (item) => (
                <select
                    className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
                    value={item.role}
                    onChange={(e) => handleRoleChange(item, e.target.value)}
                >
                    <option value="MANAGER">SUPER ADMIN</option>
                    <option value="CONSULTANT">CONSULTANT</option>
                </select>
            ),
        }
    ];

    return (
        <>
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Rechercher..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded"
                />
            </div>
            <Table columns={columns} data={filteredSuperAdmins} />
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                limit={3}
            />
        </>
    );
};

export default SuperAdminTable;
