import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { API_BASE_URL } from '../../config/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastStyle = {
    success: {
        style: {
            background: '#0B86C8',
            color: 'white'
        }
    },
    error: {
        style: {
            background: '#f64915',
            color: 'white'
        }
    }
};

const TeamMemberTable = ({ items, onEdit, onDelete, onDragEnd }) => {
    const sortedItems = [...items]
        .sort((a, b) => {
            if (a.displayOrder === null) return 1;
            if (b.displayOrder === null) return -1;
            return a.displayOrder - b.displayOrder;
        })
        .map((item) => ({
            ...item,
            stringId: `member-${item.id}`
        }));

    const handleDragEnd = (result) => {
        const { destination, source } = result;

        if (!destination ||
            (destination.droppableId === source.droppableId &&
                destination.index === source.index)) {
            return;
        }

        const reorderedItems = Array.from(sortedItems);
        const [movedItem] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, movedItem);

        const updatedOrder = reorderedItems.map((item, index) => ({
            id: item.id,
            displayOrder: index
        }));

        if (typeof onDragEnd === 'function') {
            onDragEnd(updatedOrder);
        }
    };

    const handleDelete = (member) => {
        const confirmMessage = `Êtes-vous sûr de vouloir supprimer ${member.firstName} ${member.lastName} de l'équipe ?`;

        if (window.confirm(confirmMessage)) {
            try {
                onDelete(member.id);
                toast.success('Membre supprimé avec succès', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.success.style
                });
            } catch (error) {
                toast.error('Erreur lors de la suppression du membre', {
                    position: "bottom-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    style: toastStyle.error.style
                });
            }
        }
    };

    return (
        <div className='relative'>
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className="overflow-x-auto">
                    <Droppable droppableId="droppable-members">
                        {(provided) => (
                            <table
                                className="min-w-full bg-white rounded"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <thead className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                    <tr>
                                        <th className="py-3 px-6 text-left">Photo</th>
                                        <th className="py-3 px-6 text-left">Nom</th>
                                        <th className="py-3 px-6 text-left">Prénom</th>
                                        <th className="py-3 px-6 text-left">Poste</th>
                                        <th className="py-3 px-6 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700 text-sm">
                                    {sortedItems.map((member, index) => (
                                        <Draggable
                                            key={member.stringId}
                                            draggableId={member.stringId}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <tr
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className={`border-b border-gray-200 ${snapshot.isDragging ? 'bg-gray-100' : 'hover:bg-gray-50'
                                                        }`}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <td className="py-3 px-6">
                                                        {member.imageUrl ? (
                                                            <img
                                                                src={member.imageUrl.startsWith('http')
                                                                    ? member.imageUrl
                                                                    : `${API_BASE_URL}${member.imageUrl}`
                                                                }
                                                                alt={`${member.firstName} ${member.lastName}`}
                                                                className="w-16 h-16 object-cover rounded-full"
                                                            />
                                                        ) : (
                                                            "Aucune photo"
                                                        )}
                                                    </td>
                                                    <td className="py-3 px-6 uppercase">{member.lastName}</td>
                                                    <td className="py-3 px-6">{member.firstName}</td>
                                                    <td className="py-3 px-6">{member.role}</td>
                                                    <td className="py-3 px-6 text-center">
                                                        <button
                                                            className="px-3 py-1 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 mr-2"
                                                            onClick={() => onEdit(member)}
                                                        >
                                                            Modifier
                                                        </button>
                                                        <button
                                                            className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                                            onClick={() => handleDelete(member)}
                                                        >
                                                            Supprimer
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </tbody>
                            </table>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
};

export default TeamMemberTable; 