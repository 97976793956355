import React from 'react';
import { API_BASE_URL } from '../../config/apiConfig';
import { useLabels } from '../../hooks/useLabels';
import Heading from '../common/Heading';
import { useTranslation } from 'react-i18next';
const LabelsSection = () => {
    const { items, loading, error } = useLabels();
    const { t } = useTranslation();

    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#0B86C8]"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <div className="text-red-500 text-center">
                    <p className="text-xl font-semibold">{error}</p>
                </div>
            </div>
        );
    }

    if (!items?.length) return null;

    return (
        <section className="py-8 sm:py-12 lg:py-16 bg-gray-50">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Heading
                    subtitle={t('labels.subtitle')}
                    title={t('labels.title')}
                    classParent="center"
                    classChild="center"
                />

                <div className="space-y-16 sm:space-y-20 lg:space-y-24 mt-16">
                    {items.map((label, index) => (
                        <div
                            key={label.id}
                            className={`flex flex-col ${
                                index % 2 === 0 
                                    ? "lg:flex-row" 
                                    : "lg:flex-row-reverse"
                            } items-center gap-8 lg:gap-16`}
                        >
                            <div className="w-full lg:w-1/2 flex justify-center lg:justify-normal">
                                <div className={`w-full flex ${
                                    index % 2 === 0 
                                        ? "justify-center lg:justify-start" 
                                        : "justify-center lg:justify-end"
                                }`}>
                                    <img
                                        src={label.imageUrl?.startsWith('http')
                                            ? label.imageUrl
                                            : `${API_BASE_URL}${label.imageUrl}`
                                        }
                                        alt={label.title}
                                        className="w-full h-auto rounded-lg shadow-lg object-contain 
                                            max-w-[320px] sm:max-w-[400px] lg:max-w-[480px] xl:max-w-[540px]
                                            transition-transform duration-300 hover:scale-105"
                                    />
                                </div>
                            </div>

                            <div className="w-full lg:w-1/2 flex flex-col">
                                <h2 className="text-xl sm:text-3xl lg:text-5xl font-bold mb-6 
                                    font-dmsans text-[#898D93] transition-colors duration-300 
                                    hover:text-[#0B86C8] text-center lg:text-left">
                                    {label.title}
                                </h2>
                                <div className="prose prose-lg max-w-none">
                                    <p className="text-base sm:text-lg text-gray-700 
                                        font-worksans leading-relaxed whitespace-pre-line text-justify">
                                        {label.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default LabelsSection; 