import { usePrivacy } from "../../hooks/usePrivacy";

const Privacy = () => {
    const { items, loading, error } = usePrivacy();

    return (
        <div className="container mx-auto px-4 py-8">

            <div className="">
                <div className="font-dmsans text-justify space-y-3">
                    {items?.map((item) => (
                        <div
                            dangerouslySetInnerHTML={{ __html: item.privacy }}
                            className="text-gray-800 leading-relaxed text-justify mentions"
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Privacy;